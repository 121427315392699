@import '../layout/toolbar.css';

.userPool-confirm-device {
  display: contents;

  > form {
    display: contents;

    > .layout-toolbar {
      justify-content: flex-end;
    }
  }
}
