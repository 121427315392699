[onsw-hotornot].ons-layout.group > .box {
  background-image: url(../img/hotornot/logo.png), url(../img/hotornot/bg.jpg);
  background-size: 340px, cover;
  background-repeat: no-repeat;
  background-position: 40px 5px, right;
}

.hotornot-pending {
  box-shadow: 0 -7px 7px #0000007a;
  background: linear-gradient(to bottom, rgb(125, 113, 113) 0%,rgb(21, 21, 21) 80%);
}

[onsw-item].hotornot {
  font-size: 28px;
  margin-top: 5px;
  white-space: nowrap;
}
.name[onsw-item].hotornot {
  color: #f90;
  font-family: var(--headline);
}
.hotornot-pending:not(.pos1) > .box > [onsw-item].hotornot {
  font-family: var(--headline-light);
}
.hotornot-positive > .box > [onsw-item] {
  color: white !important;
}
[onsw-item].hotornot > .box > .value {
  font-size: 20px;
  color: white;
  font-family: var(--headline-light);
}
.hotornot-pending.pos1 > .box > [onsw-text].hotornot.from {
  font-size: 15px;
  color: white;
  font-weight: 600;
}
[onsw-text].hotornot.descr {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.hotbar > .box {
  background: rgb(40,40,40);
  background: -moz-linear-gradient(top, rgba(40,40,40,1) 0%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(top, rgba(40,40,40,1) 0%,rgba(0,0,0,1) 100%);
  background: linear-gradient(to bottom, rgb(60, 53, 53) 0%,rgb(27, 27, 27) 100%);
}


.hotornot-positives {
  border-top: 1px solid #ffffff2e;
  background: rgba(26,26,26,.7);
}

.hotbar > .box > .ons-item > .box {
    box-shadow: 0 0 8px #170f0f;
}

.ons-layout.textblock > .box > .ons-heading > .box {
  color: #f90;
  font-size: 30px;
}

.hotbar > .box > [onsw-pin-button].active > .box > .icon * {
  stroke: black;
  stroke-width: 1px;
}
.hotornot.icons > .icon:first-child * {
  fill: #cc0000;
}
.hotornot.icons > .icon:last-child * {
  fill: #8dbd0d;
  stroke: #8dbd0d;
}
.hotbar > .box > [onsw-hotornot-button][type="hot"] > .box  {
  background-color: #8dbd0d;
  border: 2px solid #8dbd0d;
  padding: 6px;
}
[onsw-hotornot-button][type="hot"] > .box > .icon * {
  fill: #fff;
  stroke: #fff;
}
[onsw-hotornot-button][type="hot"] > .box:hover {
  border: 2px solid #8dbd0d;
  background-color: white !important;
}
[onsw-hotornot-button][type="hot"] > .box:hover > .icon * {
  stroke: #8dbd0d;
  fill: #8dbd0d;
}
[onsw-hotornot-button][type="not"] > .box > .icon * {
  fill: #cc0000;
}
.hotbar > .box > [onsw-hotornot-button][type="not"] > .box:hover {
  border: 2px solid #cc0000;
  padding: 6px;
}

[onsw-hotornot] .timer {
  background: linear-gradient(0deg, rgba(26,26,26,.7), transparent);
}

[onsw-hotornot] .timer > .box {
  background: linear-gradient(0deg, rgba(204,0,0,.5), transparent);
}
