[onsw-payment-methods] {
  overflow: hidden;
}

@media (min-width: 350px) {
    [onsw-payment-methods] .method {
        min-width: 300px;
        max-width: 600px;
    }
}
@media (min-width: 1024px) {
    [onsw-payment-methods] .method {
        min-height: 70px;
    }
}
[onsw-payment-methods] .method .box::after {
    display: block;
    content: '';
    clear: left;
}
[onsw-payment-methods] .method .box {
    display: block;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #d1d1d1;
    position: relative;
    min-height: 76px;
}
[onsw-payment-methods] .method img {
    float: left;
    margin-right: 10px;
}

[onsw-payment-methods] .method:hover .box {
    background-color: #f5f5f5;
}
[onsw-payment-methods] .method p {
    margin: 5px 0 5px 80px;
}
[onsw-payment-methods] .method .methodVerifiedFlag {
    display: block;
}
[onsw-payment-methods] .method .methodVerifiedFlag [ng-click]:hover {
    text-decoration: underline;
}
[onsw-payment-methods] .method2 > .box  > img,
[onsw-payment-methods] .method10 > .box  > img {
    width: 70px;
    padding-top: 15px;
}

/** special styles for sb/sepa and the percent bonus badges */
[onsw-payment-methods] .method > .box  > div.note {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border: solid #aa0000 1px;
    background-color: #aa0000;
    border-radius: 0 0 0 7px;
    padding: 0 5px 0 5px;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bolder;
}
