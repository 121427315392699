.dialog-buttons-hook {
  padding: var(--dialog-buttons-box-padding);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--dialog-buttons-box-gap);
  position: relative;
  box-sizing: border-box;

  @media (max-height: 450px) {
    grid-template-columns: repeat(6, 1fr);
  }

  > .ons-item {
    > .box {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 2px;
      background: var(--dialog-button-bg);
      height: var(--dialog-button-height);
      padding: var(--dialog-button-padding);
      color: var(--dialog-button-text-color);
      font-size: var(--dialog-button-text-size);
      border: var(--dialog-button-border);
      border-radius: var(--button-border-radius);

      &.disabled > .label {
        opacity: 0.5;
      }

      > .label {
        line-height: 1.2;
      }

      > .icon {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
      }

      @media (max-height: 450px) {
        justify-content: center;

        > .label {
          display: none;
        }

        > .icon {
          width: 36px;
          height: 36px;
        }
      }

      &:hover {
        background: var(--dialog-button-bg-hover);
        color: var(--dialog-button-text-color-hover);
        border: var(--dialog-button-border-hover);

        > .icon.attachment {
          fill: var(--dialog-button-hover-attachment-icon);
        }
      }

      > .icon.attachment {
        fill: var(--dialog-button-attachment-icon);
      }
    }
  }
}
