onsw-game { /* stylelint-disable-line plugin/no-unsupported-browser-features */
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;

  > .game-session-info {
    padding: var(--game-default-gap);
    grid-column-start: 2;
    background-color: var(--game-wrapper);
    border-radius: var(--default-border-radius);

    > h1 {
      font: var(--game-headline);
      text-transform: var(--games-session-text-transform);
      color: var(--games-session-text-color);
      text-align: center;
    }

    > .has-payback {
      text-align: center;
      font-weight: var(--text-strong);
      margin-top: 15px;
    }
  }

  > .amateur,
  > .user {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .name {
      display: none;
      margin-top: var(--game-default-gap);
      font: var(--game-headline);
      color: var(--game-img-name-color);
    }

    > img {
      width: 100%;
      object-fit: cover;
      /* generally forbidden, but we can use aspect-ratio here because playpal does */
      aspect-ratio: 1;
      border-radius: var(--game-img-border-radius);
      background: var(--game-wrapper);
    }
  }

  > onsw-messenger-message-box,
  > .game-over,
  > .no-session,
  > .game {
    grid-area: 2 / 1 / auto / span 3;
  }

  > .game-over {
    z-index: 1;
    position: relative;

    &::before {
      display: block;
      height: 100%;
      width: 100%;
      content: "";
      background: var(--game-wrapper);
      opacity: .7;
    }

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      .gameOverSplash {
        fill: var(--gameOverSplash);
      }

      .gameOverShadow {
        opacity: var(--gameOverShadow);
      }
    }
  }

  &.large {
    gap: 0 var(--game-default-gap);

    > .amateur,
    > .user {
      grid-row-end: span 2;

      > .name {
        display: block;
      }
    }

    > onsw-messenger-message-box,
    > .game-over,
    > .game {
      grid-area: 2 / 2;
    }
  }

  > .amateur.has-turn > img {
    box-shadow: 0 0 0 0 var(--game-icon-amateurTurn);
    animation: border-shadow_amateur 2s ease-out infinite;
    border-radius: var(--game-img-border-radius);
    box-sizing: border-box;
  }

  > .user.has-turn > img {
    box-shadow: 0 0 0 0 var(--game-icon-userTurn);
    animation: border-shadow_user 2s ease-out infinite;
    border-radius: var(--game-img-border-radius);
    box-sizing: border-box;
  }

  > onsw-messenger-message-box {
    position: relative;
    overflow: hidden;
    pointer-events: none;
    border-left: unset;

    > .box {
      position: absolute;
      width: 100%;
      bottom: 0;

      > .start {
        display: none;
      }

      > .msg {
        &:not(.fresh) {
          display: none;
        }

        .meta {
          display: none;
        }
      }
    }
  }
}

.intro_img{
 width: 100%;
}

@keyframes border-shadow_amateur {
  0% {
    box-shadow:  0 0 0 0 var(--game-icon-amateurTurn);
  }
  50% {
    box-shadow:  0 0 0 3px var(--game-icon-amateurTurn);
  }
  100% {
    box-shadow:  0 0 0 0 var(--game-icon-amateurTurn);
  }
}

@keyframes border-shadow_user {
  0% {
    box-shadow:  0 0 0 0 var(--game-icon-userTurn);
  }
  50% {
    box-shadow:  0 0 0 3px var(--game-icon-userTurn);
  }
  100% {
    box-shadow:  0 0 0 0 var(--game-icon-userTurn);
  }
}
