[onsw-signup] {
  margin-top: 10px;
}

[onsw-signup] .visibility {
  display: block;
  position: relative;
  right: 5px;
  top: 27px;
  height: 22px;
  width: 22px;
  cursor: pointer;
  vertical-align: bottom;
  float: right;
}

[onsw-signup] .visibility .icon {
  height: 100%;
  width: 100%;
}

[onsw-signup] > .box > label > input[ng-model="$parent.password"] {
  background-position: right 32px center !important;
  padding-right: 60px;
}

