@import 'video.js/dist/video-js.css';

onsw-profile-video {

  &:not(.active) .play {
    margin: 3px;
    width: 40px;
    height: 40px;
    border: 0.06666em solid #fff;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }

  &.active .play {
    height: 150px;
    width: 150px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    pointer-events: none;

    > path {
      opacity: 0.5;
      transition: opacity 0.5s ease;
      cursor: pointer;
      pointer-events: all;
    }

    > path:hover {
      opacity: 1;
    }
  }

  .video-js {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    margin:auto;

    .vjs-big-play-button {
      display: none;
    }
  }
}
