.livecam-tile-box,
.media-box,
.amateur-friend-box,
.tile-friend-box,
.tile-amateur-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  height: 100%; /* ? */
  gap: unset;
  background-size: 100% !important; /* size transition */
  transition: 1s ease background-size;
}

.amateur-friend-box {
  background-size: cover !important; /* disbale size transition friend fix*/
}

.tile-amateur-box {
  @media (hover: none) {
    onsw-pin-button.amateurToolbar > .box {
      pointer-events: none;
    }
  }

  > .layout-note {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;
    width: unset;
  }

  > .core-loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;

    > * {
      width: 100%;
    }
  }
}

.small-amateur-info-widget > .box {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tile-amateur-box:hover {
  transition-delay: 0.2s;
  background-size: 120% !important;
}

/* 16:9 */
.moderated-amateur-tile > .tile-amateur-box,
.media-box {
  background-size: 133.333% !important;
}
.moderated-amateur-tile > .tile-amateur-box:hover,
.media-box:hover {
  background-size: 160% !important;
}

.media-box .video-js.vjs-fluid {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.livecam-tile-box > :first-child,
.media-box > :first-child,
.amateur-friend-box > :first-child,
.tile-friend-box > :first-child,
.tile-amateur-box > :first-child {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.media-widget > .box > .detail.expanded,
.tile-amateur-widget > .box > .detail.expanded {
  transform: translateY(0);
}

.media-widget > .box > .detail.expanded,
.tile-amateur-widget > .box > .detail.expanded:not(.chat) {
  transition-delay: 0.5s;
}

.media-widget > .box > .detail,
.tile-amateur-widget > .box > .detail {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  background: rgba(0, 0, 0, 0.7);
  transition: all 0.5s ease;
  transition-property: transform;
  transition-delay: 0s;
  transform: translateY(100%);
  font-size: 12px;
  line-height: 15px;
}

.media-widget > .box > .detail > .desc,
.tile-amateur-widget > .box > .detail > .desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: -5px;
}

.media-widget > [onsw-rating] > .box > .label {
  display: none;
}
