.layout-container.userpool-profile {

  &.small {
    > .cams {
      display: none;
    }
  }

  &.medium {
    grid-template-columns: 1fr 1fr;
  }

  &.large {
    grid-template-columns: 2fr 2fr 1fr;
  }

  &.xlarge {
    grid-template-columns: 1fr 1fr 1fr;
  }

  > .layout-container > .layout-panel > p > img {
    float: left;
    height: 80px;
    margin-right: 20px;
  }
}
