.livecamRegardOverlay .ons-heading {
  font-size: 18px;
  font-weight: bold;
}
.livecamRegardOverlay .ons-heading > span {
  color: #f90;
  font-size: 40px;
  font-family: var(--headline);
  font-weight: normal;
}
