[onsw-amateur-label].messengerDialog {
  display: block;
  height: 0;
  overflow: visible;
}

[onsw-amateur-label].messengerDialog > .box {
  cursor: pointer;
  overflow: unset;
  height: 64px;
  padding: 8px 0;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

[onsw-amateur-label].messengerDialog > .box > * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

[onsw-amateur-label].messengerDialog > .box > .from {
  display: block;
}

[onsw-open-overlay].messengerDialog:hover ~ [onsw-amateur-label] > .box,
[onsw-amateur-label].messengerDialog > .box:hover {
  background: var(--contact-background-hover);
}

[onsw-amateur-label].messengerDialog > .box > .name {
  font-size: 16px;
  font-weight: bold;
}

@media (max-height: 450px) {
  [onsw-amateur-label].messengerDialog > .box {
    height: auto;
    padding-left: 10px;
  }

  [onsw-amateur-label].messengerDialog > .box > .from {
    display: inline;
  }

  [onsw-amateur-label].messengerDialog {
    height: auto;
  }
}
