@import 'note.css';

.layout-note.error {
  color: var(--layout-note-error-color);
  background: var(--layout-note-error-background);

  > .close > .icon * {
    fill: var(--layout-note-error-color);
  }
}

