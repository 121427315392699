@import '../layout/header.css';

.layout-header.guest-header {
  > .layout-container.login-form {
    align-items: stretch;
    grid-template-columns: repeat(4, auto);
    gap: var(--header-login-gap, var(--container-gap));

    > .layout-input {
      width: 150px;
      font: var(--header-input-font, var(--input-font));
      border: var(--header-input-border, var(--input-border));
      background: var(--header-input-background, var(--input-bg));

      &:focus {
        border: var(--header-input-focus-border, var(--input-border-focus));
      }
    }

    > .layout-link.preset-button {
      padding: var(--header-button-padding, var(--link-padding));
    }

    > .layout-textlink.confirm-mail {
      grid-column-end: span 2;
    }

    @media (max-width: 1279px) {
      > .layout-textlink {
        display: none;
      }
    }
  }

  &.small {
    padding-right: 10px;

    .layout-container.login-form {
      display: none;
    }
  }

  &.large {
    > .layout-container.login-form {
      padding-right: 15px;
    }
  }

  &:not(.small) {
    .button.login {
      display: none;
    }
  }

  &:not(.large) {
    .layout-link.preset-button.signup {
      display: none;
    }
  }
}
