@import '../layout/toolbar.css';

.layout-toolbar.messenger-voice {
  align-items: center;
  font: 11px var(--default-font);
  color: var(--voicemessage-text-color);

  > .btn-icon > .icon {
    border: 1px solid var(--voicemessage-play-color);
    padding: 4px;
    border-radius: 50%;
    flex-basis: 20px;
    fill: var(--voicemessage-play-color);

    &:hover {
      border-color: var(--voicemessage-play-color-hover);
      transform: none; /* layout-toolbar default is shit */
      fill: var(--voicemessage-play-color-hover);
    }
  }

  > .btn-icon.pause > .icon {
    border-color: var(--voicemessage-pause-bg);
    background: var(--voicemessage-pause-bg);
    fill: var(--voicemessage-pause-color);
  }

  > .expired,
  > .wave {
    z-index: 1; /* wavesurfer breaks out without this */
    flex-grow: 1;
  }

  &.layout-inactive > *, /* Consider display "Sprachnachricht" or so */
  &.layout-active > .expired,
  &.layout-playing > .play,
  &:not(.layout-playing) > .pause {
    display: none;
  }

  &.layout-disabled {
    > .wave,
    > .duration,
    > .pause {
      display: none;
    }

    > .play > .icon {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .msg.in.voice > .box > & {
    --voicemessage-wave-color: var(--voicemessage-msg-in-wave-color);
    --voicemessage-wave-progress-color: var(--voicemessage-msg-in-wave-progress-color);
    --voicemessage-play-color: var(--voicemessage-msg-in-btn-color);
    --voicemessage-play-color-hover: var(--voicemessage-msg-in-btn-color-hover);
    --voicemessage-pause-color: var(--voicemessage-msg-in-btn-color-active);
    --voicemessage-pause-bg: var(--voicemessage-msg-in-btn-bg-active);
    --voicemessage-text-color: var(--voicemessage-msg-in-duration-color);
  }

  .msg.out.voice > .box > & {
    --voicemessage-wave-color: var(--voicemessage-msg-out-wave-color);
    --voicemessage-wave-progress-color: var(--voicemessage-msg-out-wave-progress-color);
    --voicemessage-play-color: var(--voicemessage-msg-out-btn-color);
    --voicemessage-play-color-hover: var(--voicemessage-msg-out-btn-color-hover);
    --voicemessage-pause-color: var(--voicemessage-msg-out-btn-color-active);
    --voicemessage-pause-bg: var(--voicemessage-msg-out-btn-bg-active);
    --voicemessage-text-color: var(--voicemessage-msg-out-duration-color);
  }
}
