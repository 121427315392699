/* TODO target each list individually ?? !! */
.layout-panel.panel-list {
  padding: unset;
  gap: 0;
  background: unset;

  > .warp-grid:not(.layout-empty) ~ * {
    display: none;
  }

  > .layout-toolbar,
  > .headline,
  > .heading,
  > .subheading,
  > .empty-list,
  > h1 {
    margin: unset;
    padding: var(--panel-padding);
    background: var(--panel-background);
  }

  > .empty-list {
    margin-bottom: var(--empty-list-margin, var(--panel-padding));
  }
}
