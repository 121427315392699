body > canvas {
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  z-index: 3;
}

#puzzle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 1000;
  background: rgba(0,0,0,.4);
}

#puzzle > canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
