.ons-layout.dropdown {
  display: inline-block;
  position: relative;
  vertical-align: bottom !important;
}

.ons-layout.dropdown > .box {
  position: absolute;
  top: -10px;
  right: 0;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
}

.ons-layout.dropdown > .box > * > .box {
  display: block;
  white-space: nowrap;
  padding: 5px 10px;
  font-size: 16px;
}

.ons-layout.dropdown > .box > .spacer {
  border-top: 3px solid;
  margin-bottom: 0;
}

.ons-layout.dropdown[hookname="userPopup"] {
  float: right;
  position: relative;
  top: 50px;
}

