@media (min-width: 1024px) {
  .fmotd-teaser-hook.above-movies {
    display: none;
  }
}
.fmotd-box {
  display: grid;
  gap: 10px;
}

@media (min-width: 1600px) {
  .fmotd-box {
    grid-template-columns: 7fr 3fr;
  }
  .fmotd-box > .previous,
  .fmotd-box > .info {
    grid-column: 1 / 3;
  }
}

.fmotd-teaser-box {
  position: relative; /* für den button */
  display: grid;
  grid-template-columns: minmax(0, 7fr) minmax(0, 3fr);
  grid-template-rows: 180px 180px;
  gap: 10px;
}
@media (min-width: 1600px) {
  .fmotd-widget > .fmotd-teaser-box {
    grid-template-rows: 240px 240px;
  }
}
[onsw-free-movie].current {
  grid-row: 1 / 3;
  grid-column: 1;
}

@media (max-width: 767px) {
  .fmotd-teaser-box {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: 370px 180px;
  }
  [onsw-free-movie].current {
    grid-row: 1;
    grid-column: 1 / 3;
  }
}
@media (max-width: 480px) {
  .fmotd-teaser-box {
    grid-template-rows: 260px 140px;
  }
}

[onsw-free-movie] > .box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-top: unset!important;
  overflow: hidden;
  background-size: auto calc(100% - 40px) !important;
  background-position: top !important;
  transition: background-size ease 1s;
}

[onsw-free-movie] > .box > :first-child {
  position: relative;
  order: 2;
  height: 40px;
}
[onsw-free-movie]:not(.current) > .box:hover {
  transition-delay: .2s;
  background-size: auto 100% !important;
}

[onsw-free-movie] .movie-hd {
  display: none;
}
[onsw-free-movie] [onsw-item][label="::nickname"] {
  display: none;
}
[onsw-free-movie] .layout-toolbar.toolbar-list > .box {
  filter: none;
}
[onsw-free-movie] .layout-toolbar.toolbar-list .add > .box > .label::after {
  content: ': ';
}
[onsw-free-movie] .detail {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 15px;
  padding: 0 5px;
}
[onsw-free-movie].current > .box > :first-child {
  height: 70px;
}

[onsw-free-movie].current .detail {
  -webkit-line-clamp: 4;
  padding-right: 142px;
}

@media (min-width: 1600px) {
  .frame.premium > .box > .title::after {
    background-size: contain;
    height: 170px;
  }
}

/**
 * Button
 */
.fmotd-teaser-hook .button {
  position: absolute;
  right: calc(30% + 20px);
  bottom: 20px;
}
@media (max-width: 767px) {
  .fmotd-teaser-hook .button {
    bottom: unset;
    right: 10px;
    top: 315px;
  }
}
@media (max-width: 480px) {
  .fmotd-teaser-hook .button {
    top: 203px;
  }
}

[onsw-free-movie].current > .box > .layout-toolbar.toolbar-list > .box > .add {
  display: none;
}

.fmotd .ons-text p {
  margin-bottom: 10px;
}
.frame.premium > .box {
  height: auto;
  padding: 15px;
  text-align: center;
}
.frame.premium .button {
  margin-top: 15px;
}
@media (min-width: 1600px) {
  .frame.premium > .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .frame.premium > .box > .title::after {
    display: block;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px 0;
  }
}

/**
 * Listenkachel
 */
.free-movie-tile .layout-toolbar.toolbar-list > .box > [onsw-item].add {
  order: 1;
}

.frame.premium > .box > .title::after,
[onsw-stock-indicator].fmotd > .box {
  background-image: var(--freemovie-logo);
}

