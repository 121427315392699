[onsw-stock-indicator].picture.invalid > .box > .label {
  height: 30px;
  line-height: 22px;
  text-align: center;
  padding: 4px;
  width: auto;
  margin: auto;
  background-color: #b71818;
  border-bottom: 1px solid #cb0505;
  box-shadow: 0 2px 0 #7a0000;
  text-shadow: none;
  opacity: 0.65 !important;
  box-sizing: border-box;
}


[onsw-stock-indicator].valid > .box > .icon * {
  fill: #88b40d;
}