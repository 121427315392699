onsw-smileys {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;

    > * {
        width: 32px;
        height: 32px;

        &:hover {
            transform: scale(1.6);
        }
    }
}

span.smiley {
    height:18px;
    width:18px;
    display:inline-block;
    vertical-align: middle;
    background-size: cover;
    background-repeat: no-repeat;
}

.only-smileys > span.smiley {
    height:32px !important;
    width:32px !important;
}

span.smiley.livecam_amor {
    animation: 1s sprite_h6 infinite step-end;
}
span.smiley.livecam_blowjob {
    animation: 1s sprite_h10 infinite step-end;
}
span.smiley.livecam_bye {
    animation: 1s sprite_h5 infinite step-end;
}
span.smiley.livecam_cunnilingus {
    animation: 1s sprite_h10 infinite step-end;
}
span.smiley.livecam_erection {
    animation: 1s sprite_h5 infinite step-end;
}
span.smiley.livecam_exhibitonist {
    animation: 1s sprite_h4 infinite step-end;
}
span.smiley.livecam_hdl {
    animation: 1s sprite_h5 infinite step-end;
}
span.smiley.livecam_hearts {
    animation: 1s sprite_h5 infinite step-end;
}
span.smiley.livecam_hello {
    animation: 1s sprite_h5 infinite step-end;
}
span.smiley.livecam_inlove {
    animation: 1s sprite_h7 infinite step-end;
}
span.smiley.livecam_laugh {
    animation: 1s sprite_h5 infinite step-end;
}
span.smiley.livecam_lol {
    animation: 1s sprite_h5 infinite step-end;
}
span.smiley.livecam_thumbup {
    animation: 1s sprite_h10 infinite step-end;
}
span.smiley.livecam_wank {
    animation: 1s sprite_h9 infinite step-end;
}
span.smiley.livecam_wave {
    animation: 1s sprite_h5 infinite step-end;
}

@keyframes sprite_h4{
    33.33% {background-position-x: 33.33%}
    66.66% {background-position-x: 66.66%}
    100% {background-position-x: 100%}
}

@keyframes sprite_h5{
    25% {background-position-x: 25%}
    50% {background-position-x: 50%}
    75% {background-position-x: 75%}
    100% {background-position-x: 100%}
}

@keyframes sprite_h6{
    20% {background-position-x: 20%}
    40% {background-position-x: 40%}
    60% {background-position-x: 60%}
    80% {background-position-x: 80%}
    100% {background-position-x: 100%}
}

@keyframes sprite_h7{
    16.66% {background-position-x: 16.66%}
    33.33% {background-position-x: 33.33%}
    50% {background-position-x: 50%}
    66.66% {background-position-x: 66.66%}
    83.33% {background-position-x: 83.33%}
    100% {background-position-x: 100%}
}

@keyframes sprite_h9{
    12.5% {background-position-x: 12.5%}
    25% {background-position-x: 25%}
    37.5% {background-position-x: 37.5%}
    50% {background-position-x: 50%}
    62.5% {background-position-x: 62.5%}
    75% {background-position-x: 75%}
    87.5% {background-position-x: 87.5%}
    100% {background-position-x: 100%}
}

@keyframes sprite_h10{
    11.1% {background-position-x: 11.1%}
    22.2% {background-position-x: 22.2%}
    33.3% {background-position-x: 33.3%}
    44.4% {background-position-x: 44.4%}
    55.5% {background-position-x: 55.5%}
    66.6% {background-position-x: 66.6%}
    77.7% {background-position-x: 77.7%}
    88.8% {background-position-x: 88.8%}
    100% {background-position-x: 100%}
}

