@import '../layout/container.css';

.layout-container.messenger {
  position: fixed;
  top: 65px;
  bottom: 0;
  right: 0;
  width: 200%;
  transition: transform 0.5s ease-out;
  transform: translateX(50%);

  grid-template-rows: min-content min-content minmax(0, 1fr);
  grid-template-columns: 50% 50%;
  align-items: unset; /* layout-container override - consider default */
  color: var(--messenger-color);
  /*overflow: hidden;*/

  /* Hide the messenger for guests and for smartphone size when not visible. */
  body:not(.show-dialog):not(.show-contacts) & {
    display: none;
  }

  body:not(.show-dialog) & {
    > .tabs > .box > .ons-item > .box > .label {
      display: none;
    }
  }

  body.show-dialog & {
    transform: translateX(0);

    @media (max-height: 450px) {
      /* Assume on-screen keyboard => squeeze the dialog */
      top: 0;
      z-index: 2;

      > .tabs {
        display: none;
      }
    }
  }

  @media (min-width: 768px) {
    top: 60px;
    width: 600px;
    grid-template-columns: 300px 300px;
    transform: translateX(300px);
  }

  @media (max-width: 1023px) {
    > .tabs > .box > .ons-item > .box > .label {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    width: 620px;
    grid-template-columns: 300px 320px;
    transform: translateX(320px);
  }

  @media (min-width: 1280px) {
    top: 0;
  }

  /* TODO this should become .layout-toolbar */
  > .tabs {
    grid-column: 1 / 3;
    grid-row: 1;
    background-color: var(--messenger-tab-bg);

    > .box {
      > .ons-item.link.active > .box {
        background: var(--messenger-tab-bg-active);
      }

      > :last-child {
        margin-left: auto;

        > .box {
          width: 36px; /* spc */
          padding: 0;
        }
      }
    }
  }

  > .ons-form {
    grid-column: 1;
    grid-row: 2;
  }

  > .items {
    grid-column: 1;
    grid-row: 3;

    > .box > :not(.ons-list) {
      text-align: center;
    }

    > .box > onsw-tiles {
      --warp-height: 71px;
      --warp-gap: var(--messenger-contacts-gap, 0px);
      > * {
        transition: top 0.5s ease;
      }
    }

    > .scroll-overlay {
      > .page-down,
      > .scroll-top {
        position: absolute;
        width: 100%;
        /*border: none;*/ /* not sure about this */
        height: 23px;
        text-align: center;
        line-height: 27px;
        background: var(--messenger-scroll-bg);

        > .icon {
          fill: var(--messenger-scroll-icon-color);
        }

        &:hover {
          background: var(--messenger-scroll-bg-hover, var(--messenger-scroll-bg));

          > .icon {
            fill: var(--messenger-scroll-icon-color-hover, var(--messenger-scroll-icon-color));
          }
        }
      }

      > .page-down {
        bottom: 0;
        border-top: var(--messenger-scroll-border);
      }

      > .scroll-top {
        top: 0;
        border-bottom: var(--messenger-scroll-border);
      }
    }
  }

  > .messenger-dialog {
    grid-column: 2;
    grid-row: 2 / 4;
    border-left: 1px solid var(--messenger-border-color);
    display: flex;
    flex-direction: column;
    position: relative;

    > onsw-messenger-message-box {
      min-height: 0; /* grid */
      flex-grow: 1;

      > .box {
        height: 100%;
        overflow: hidden auto;
      }

      > .scroll-overlay > .bar-x {
        display: none;
      }
    }

    > .layout-container.layout-overlay {
      padding: 20px 0;
      place-content: end;

      > .layout-panel,
      > .layout-note,
      > [onsw-hook] {
        width: 100%;
        max-width: 300px;
        max-height: 80vh;
        overflow: auto;
      }
    }
  }
}
