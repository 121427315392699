@keyframes latest-message {
  from {
    background-color: var(--contact-background-flash-from);
  }
  to {
    background-color: var(--contact-background-flash-to);
  }
}
.contact-hook {
  height: 100%;
}

.contact-box {
  height: 100%;
  padding: 5px;
  line-height: 20px;
  cursor: pointer;
  border-style: solid;
  border-width: var(--contact-border, 0 0 1px 0);
  border-color: var(--messenger-border-color);
  border-radius: var(--contact-border-radius);
  background-color: var(--contact-bg);
  margin: 0 var(--contact-margin, 0);

  :hover > & {
    background-color: var(--contact-background-hover);

    > .nickname {
      color: var(--contact-nickname-color-hover, var(--contact-nickname-color));
    }
  }

  .active > & {
    background-color: var(--contact-background-active);
    border-width: var(--contact-border-active, 0 0 1px 0);
    border-color: var(--contact-border-active-color, var(--messenger-border-color));

    > .nickname {
      color: var(--contact-nickname-color-active);
    }
  }

  .unread > & {
    animation: latest-message .5s 2 alternate ease;

    .preview {
      font-weight: var(--text-strong);
    }

    .info .new {
      display: block;
    }
  }

  img {
    margin: 5px 8px 5px 5px;
    height: 50px;
    width: 50px;
    object-fit: cover;
    float: left;
    border-radius: var(--contact-img-border-radius);
  }

  .nickname {
    display: flex;
    gap: 4px;
    align-items: center;
    font-family: var(--headline);
    font-size: var(--contact-nickname-font-size);
    font-weight: var(--contact-nickname-font-weight);
    color: var(--contact-nickname-color);
  }

  .icon.online {
    width: 12px;
    height: 12px;
    order: 2;
  }

  .icon.game {
    order: 3;
    height: 12px;
    width: 12px;
    transform: scale(2);
    margin-left: 6px;
  }

  .icon.match2 {
    order: 4;
    height: 12px;
    width: 12px;
    transform: scale(2);
    margin-left: 4px;
  }

  .icon.close {
    order: 5;
    height: 18px;
    width: 18px;
    margin-left: auto;
    display: block;

    * {
      fill: var(--contact-close-color);
    }

    &:hover * {
      fill: var(--contact-close-color-hover);
    }
  }

  .preview {
    display: flex;
    align-items: center;
    gap: 3px;

    .icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;

      * {
        fill: var(--contact-icon-color);
      }
    }

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .info {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .timestamp {
      font-size: 11px;
      color: var(--contact-timestamp-color);
    }

    .new {
      display: none;
      font-family: var(--contact-new-font-family);
      font-weight: var(--contact-new-font-weight);
      color: var(--contact-new-color);
      background-color: var(--contact-new-bg);
      border-radius: var(--contact-new-bg-border-radius);
      font-size: var(--contact-new-font-size);
      padding: var(--contact-new-padding);
      text-transform: var(--contact-new-text-transform);
      animation: latest-message-new .3s 4 alternate;
    }
  }
}

body.desktop .contact-box {
  .icon.close {
    display: none;
  }

  &:hover .icon.close {
    display: block;
  }
}

@keyframes latest-message-new {
  from {
    background-color: var(--contact-new-flash-bg-from);
    color: var(--contact-new-flash-color-from);
  }

  to {
    background-color: var(--contact-new-flash-bg-to);
    color: var(--contact-new-flash-color-to);
  }
}

