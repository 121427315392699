[onsw-teaser].easter2020.small > .box > .toggle::before {
  display: none;
}

[onsw-teaser].easter2020 [onsw-countdown] > .box {
  background: #d00e72;
  color: white;
}

@media (max-width: 1023px) {
  [onsw-teaser].easter2020 [onsw-countdown] > .box > .value {
    line-height: 45px;
    font-size: 40px;
  }
}

[onsw-teaser].easter2020 .close {
  color: white!important;
}

:lang(de) [onsw-teaser].easter2020.large > .box {
  padding-top: 56.25%;
  background-image: url("/specials/easter2020/banner_m_de.jpg");
}
:lang(en) [onsw-teaser].easter2020.large > .box {
  padding-top: 56.25%;
  background-image: url("/specials/easter2020/banner_m_en.jpg");
}
:lang(de) [onsw-teaser].easter2020.large.finished > .box {
  background-image: url("/specials/easter2020/banner_m_redeemed_de.jpg");
}
:lang(en) [onsw-teaser].easter2020.large.finished > .box {
  background-image: url("/specials/easter2020/banner_m_redeemed_en.jpg");
}

[onsw-teaser].easter2020.preview.small > .box,
[onsw-teaser].easter2020.active.small > .box {
  padding-top: 65px;
  background-position: center;
  background-image: url("/specials/easter2020/banner_mini_de.jpg");
}

:lang(en) [onsw-teaser].easter2020.preview.small > .box,
:lang(en) [onsw-teaser].easter2020.active.small > .box {
  background-image: url("/specials/easter2020/banner_mini_en.jpg");
}

[onsw-teaser].easter2020 > .box::after {
  display: none;
  content: ' ';
}

@media (min-width: 1024px) {
  :lang(de) [onsw-teaser].easter2020.large > .box {
    padding-top: 33.333%;
    background-image: url("/specials/easter2020/banner_l_de.jpg");
  }
  :lang(en) [onsw-teaser].easter2020.large > .box {
    padding-top: 33.333%;
    background-image: url("/specials/easter2020/banner_l_en.jpg");
  }

  body.show-dialog:lang(de) [onsw-teaser].easter2020.large > .box {
    padding-top: 56.25%;
    background-image: url("/specials/easter2020/banner_m_de.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].easter2020.large > .box {
    padding-top: 56.25%;
    background-image: url("/specials/easter2020/banner_m_en.jpg");
  }

  :lang(de) [onsw-teaser].easter2020.large.finished > .box {
    background-image: url("/specials/easter2020/banner_l_redeemed_de.jpg");
  }
  :lang(en) [onsw-teaser].easter2020.large.finished > .box {
    background-image: url("/specials/easter2020/banner_l_redeemed_en.jpg");
  }

  body.show-dialog:lang(de) [onsw-teaser].easter2020.large.finished > .box {
    background-image: url("/specials/easter2020/banner_m_redeemed_de.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].easter2020.large.finished > .box {
    background-image: url("/specials/easter2020/banner_m_redeemed_en.jpg");
  }
}

@media (min-width: 1280px) {
  :lang(de) [onsw-teaser].easter2020.large > .box {
    padding-top: 26.5%;
    background-image: url("/specials/easter2020/banner_xl_de.jpg");
  }
  :lang(en) [onsw-teaser].easter2020.large > .box {
    padding-top: 26.5%;
    background-image: url("/specials/easter2020/banner_xl_en.jpg");
  }

  body.show-dialog:lang(de) [onsw-teaser].easter2020.large > .box {
    padding-top: 33.333%;
    background-image: url("/specials/easter2020/banner_l_de.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].easter2020.large > .box {
    padding-top: 33.333%;
    background-image: url("/specials/easter2020/banner_l_en.jpg");
  }

  :lang(de) [onsw-teaser].easter2020.large.finished > .box {
    background-image: url("/specials/easter2020/banner_xl_redeemed_de.jpg");
  }
  :lang(en) [onsw-teaser].easter2020.large.finished > .box {
    background-image: url("/specials/easter2020/banner_xl_redeemed_en.jpg");
  }

  body.show-dialog:lang(de) [onsw-teaser].easter2020.large.finished > .box {
    background-image: url("/specials/easter2020/banner_l_redeemed_de.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].easter2020.large.finished > .box {
    background-image: url("/specials/easter2020/banner_l_redeemed_en.jpg");
  }
}

@media (min-width: 1600px) {
  body.show-dialog:lang(de) [onsw-teaser].easter2020.large > .box {
    padding-top: 26.5%;
    background-image: url("/specials/easter2020/banner_xl_de.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].easter2020.large > .box {
    padding-top: 26.5%;
    background-image: url("/specials/easter2020/banner_xl_en.jpg");
  }
  body.show-dialog:lang(de) [onsw-teaser].easter2020.large.finished > .box {
    padding-top: 26.5%;
    background-image: url("/specials/easter2020/banner_xl_redeemed_de.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].easter2020.large.finished > .box {
    padding-top: 26.5%;
    background-image: url("/specials/easter2020/banner_xl_redeemed_en.jpg");
  }
}
