.ons-layout.columns > .box > .blockFormContainer > .box {
  padding: 0 15px 15px;
}

@media (min-width: 1024px) {
  .ons-layout.columns > .box > .blockFormContainer > .box {
    padding: 50px 15px 0;
  }
}

.ons-layout.columns > .box {
  margin: 0 -5px;
}

.ons-layout.columns > .box::after {
  display: block;
  content: '';
  clear: both;
}

.ons-layout.columns > .box > * {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
  width: 100%;
  box-sizing: border-box;
}
.ons-layout.columns > .box > .livecamShow {
  display: block !important;
}


.ons-layout.columns.pad0 > .box {
  margin: 0;
}

.ons-layout.columns.pad0 > .box > * {
  padding: 0;
}

.ons-layout.columns.pad2 > .box {
  margin: -1px;
}

.ons-layout.columns.pad2 > .box > * {
  padding: 1px;
}

.ons-layout.columns.pad6 > .box {
  margin: -3px;
}

.ons-layout.columns.pad6 > .box > * {
  padding: 3px;
}

.ons-layout.columns.pad10 > .box {
  margin: -5px;
}

.ons-layout.columns.pad10 > .box > * {
  padding: 5px;
}

.ons-layout.columns.pad20 > .box {
  margin: -10px;
}

.ons-layout.columns.pad20 > .box > * {
  padding: 10px;
}

.ons-layout.columns.pad30 > .box {
  margin: -15px;
}

.ons-layout.columns.pad30 > .box > * {
  padding: 15px;
}

.ons-layout.columns > .box > .contentBox {
  background: #444;
  color: #ccc;
  background-clip: content-box;
}

.ons-layout.columns > .box > .contentBox > .box {
  padding: 15px;
}

.ons-layout.columns > .box > .block { display: block; }

.ons-layout.columns > .box > .left-xs {float: left;}
.ons-layout.columns > .box > .right-xs { float: right; }
.ons-layout.columns > .box > .clear.right-xs { clear: right; }
.ons-layout.columns > .box > .clear.left-xs { clear: left; }

.ons-layout.columns > .box > .col10-xs { width: 10%; }
.ons-layout.columns > .box > .col16-xs { width: 16.666%; }
.ons-layout.columns > .box > .col20-xs { width: 20%; }
.ons-layout.columns > .box > .col25-xs { width: 25%; }
.ons-layout.columns > .box > .col30-xs { width: 30%; }
.ons-layout.columns > .box > .col33-xs { width: 33.333%; }
.ons-layout.columns > .box > .col40-xs { width: 40%; }
.ons-layout.columns > .box > .col50-xs { width: 50%; }
.ons-layout.columns > .box > .col60-xs { width: 60%; }
.ons-layout.columns > .box > .col66-xs { width: 66.666%; }
.ons-layout.columns > .box > .col70-xs { width: 70%; }
.ons-layout.columns > .box > .col75-xs { width: 75%; }
.ons-layout.columns > .box > .col80-xs { width: 80%; }
.ons-layout.columns > .box > .col100-xs { width: 100%; }

@media (min-width: 768px) {
  .ons-layout.columns > .box > .col10-sm { width: 10%; }
  .ons-layout.columns > .box > .col16-sm { width: 16.666%; }
  .ons-layout.columns > .box > .col20-sm { width: 20%; }
  .ons-layout.columns > .box > .col25-sm { width: 25%; }
  .ons-layout.columns > .box > .col30-sm { width: 30%; }
  .ons-layout.columns > .box > .col33-sm { width: 33.333%; }
  .ons-layout.columns > .box > .col40-sm { width: 40%; }
  .ons-layout.columns > .box > .col50-sm { width: 50%; }
  .ons-layout.columns > .box > .col60-sm { width: 60%; }
  .ons-layout.columns > .box > .col66-sm { width: 66.666%; }
  .ons-layout.columns > .box > .col70-sm { width: 70%; }
  .ons-layout.columns > .box > .col75-sm { width: 75%; }
  .ons-layout.columns > .box > .col80-sm { width: 80%; }
  .ons-layout.columns > .box > .col100-sm { width: 100%; }

  .ons-layout.columns > .box > .right-sm { float: right }
  .ons-layout.columns > .box > .left-sm { float: left }
  .ons-layout.columns > .box > .no-float-sm { float: none }
  .ons-layout.columns > .box > .clear.right-sm { clear: right; }
  .ons-layout.columns > .box > .clear.left-sm { clear: left; }
}

@media (min-width: 1024px) {
  .ons-layout.columns > .box > .col10-md { width: 10%; }
  .ons-layout.columns > .box > .col16-md { width: 16.666%; }
  .ons-layout.columns > .box > .col20-md { width: 20%; }
  .ons-layout.columns > .box > .col25-md { width: 25%; }
  .ons-layout.columns > .box > .col30-md { width: 30%; }
  .ons-layout.columns > .box > .col33-md { width: 33.333%; }
  .ons-layout.columns > .box > .col40-md { width: 40%; }
  .ons-layout.columns > .box > .col50-md { width: 50%; }
  .ons-layout.columns > .box > .col60-md { width: 60%; }
  .ons-layout.columns > .box > .col66-md { width: 66.666%; }
  .ons-layout.columns > .box > .col70-md { width: 70%; }
  .ons-layout.columns > .box > .col75-md { width: 75%; }
  .ons-layout.columns > .box > .col80-md { width: 80%; }
  .ons-layout.columns > .box > .col100-md { width: 100%; }

  .ons-layout.columns > .box > .right-md { float: right }
  .ons-layout.columns > .box > .left-md { float: left }
  .ons-layout.columns > .box > .no-float-md { float: none }
  .ons-layout.columns > .box > .clear.right-md { clear: right; }
  .ons-layout.columns > .box > .clear.left-md { clear: left; }
}

@media (min-width: 1280px) {
  .ons-layout.columns > .box > .col10-lg { width: 10%; }
  .ons-layout.columns > .box > .col16-lg { width: 16.666%; }
  .ons-layout.columns > .box > .col20-lg { width: 20%; }
  .ons-layout.columns > .box > .col25-lg { width: 25%; }
  .ons-layout.columns > .box > .col30-lg { width: 30%; }
  .ons-layout.columns > .box > .col33-lg { width: 33.333%; }
  .ons-layout.columns > .box > .col40-lg { width: 40%; }
  .ons-layout.columns > .box > .col50-lg { width: 50%; }
  .ons-layout.columns > .box > .col60-lg { width: 60%; }
  .ons-layout.columns > .box > .col66-lg { width: 66.666%; }
  .ons-layout.columns > .box > .col70-lg { width: 70%; }
  .ons-layout.columns > .box > .col75-lg { width: 75%; }
  .ons-layout.columns > .box > .col80-lg { width: 80%; }
  .ons-layout.columns > .box > .col100-lg { width: 100%; }

  .ons-layout.columns > .box > .right-lg {float: right;}
  .ons-layout.columns > .box > .left-lg { float: left }
  .ons-layout.columns > .box > .no-float-lg { float: none }
  .ons-layout.columns > .box > .clear.right-lg {clear: right;}
  .ons-layout.columns > .box > .clear.left-lg { clear: left; }
}

@media (min-width: 1600px) {
  .ons-layout.columns > .box > .col10-xl { width: 10%; }
  .ons-layout.columns > .box > .col16-xl { width: 16.666%; }
  .ons-layout.columns > .box > .col20-xl { width: 20%; }
  .ons-layout.columns > .box > .col25-xl { width: 25%; }
  .ons-layout.columns > .box > .col30-xl { width: 30%; }
  .ons-layout.columns > .box > .col33-xl { width: 33.333%; }
  .ons-layout.columns > .box > .col40-xl { width: 40%; }
  .ons-layout.columns > .box > .col50-xl { width: 50%; }
  .ons-layout.columns > .box > .col60-xl { width: 60%; }
  .ons-layout.columns > .box > .col66-xl { width: 66.666%; }
  .ons-layout.columns > .box > .col70-xl { width: 70%; }
  .ons-layout.columns > .box > .col75-xl { width: 75%; }
  .ons-layout.columns > .box > .col80-xl { width: 80%; }
  .ons-layout.columns > .box > .col100-xl { width: 100%; }

  .ons-layout.columns > .box > .right-xl { float: right }
  .ons-layout.columns > .box > .left-xl { float: left }
  .ons-layout.columns > .box > .no-float-xl { float: none }
  .ons-layout.columns > .box > .clear.right-xl { clear: right; }
  .ons-layout.columns > .box > .clear.left-xl { clear: left; }
}

