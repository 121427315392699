[onsw-livecam-toy-button].inactive,
[onsw-livecam-button].inactive {
  display: none;
}

[onsw-livecam-button] {
  > .box {
    > .toy-active {
      display: none;
    }

    > .default-text {
      text-align: center;
    }
  }

  &.aotm-widget.button > .box {
    display: flex;
    flex-direction: column;
  }

  &.tile > .box { /* for sedcard */
    z-index: 1;
  }
}

/* CAM TEASER */
[onsw-livecam-button].cam-teaser-widget {
  position: absolute;
  left: 0;
  right: 0;
}

[onsw-livecam-button].cam-teaser-widget > .box {
  box-shadow: 0 6px 3px -3px #0000006b;
}

.myportal-widget [onsw-livecam-button].cam-teaser-widget, /* @todo */
.sidebar-widget [onsw-livecam-button].cam-teaser-widget {
  text-align: center;
  bottom: 25%;
}

.main-widget > .cam-teaser-box > [onsw-livecam-button] {
  text-align: right;
  bottom: 8%;
  right: 8%;
}
 
.cam-teaser-box svg * {
  fill: white;
}

.main-widget > .cam-teaser-box {
  position: relative;
}

