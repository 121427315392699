.ons-layout.fixed-main:not(.overlay) {
  background: #1a1a1a;
}

[onsw-stock-indicator].valid > .box > .icon * {
  fill: #88b40d;
}

[onsw-stock-indicator].expired > .box > .icon * {
  fill: #ff0000;
}
[onsw-stock-indicator] > .box > .value > .icon * {
  fill: #ffd700;
}

.video-js .vjs-control-bar {
  background-color: rgba(0,0,0,.7) !important;
}
.video-js .vjs-load-progress {
  background: rgba(255, 153, 0, .4) !important;
}
.video-js .vjs-load-progress div {
  background: #f90 !important;
}
