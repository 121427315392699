onsw-more-about-me h4 {
    font-family: var(--headline);
    color: #eee;
    font-size: 15px;
    margin: 0 0 10px;

    &:last-of-type {
        margin-top: 15px;
    }
}

onsw-more-about-me dl {
    margin: 10px 0 5px;
}

onsw-more-about-me dt {
    color: #f90;
    font-weight: 700;
    float: none;
}

onsw-more-about-me ul {
    margin: 0 0 0 20px;
    padding-left: 10px;
}

onsw-more-about-me li {
    margin: 5px;
    list-style-image: var(--checkmark-url);
}

onsw-more-about-me dd {
    display: block;
    float: none;
    overflow: visible;
}
