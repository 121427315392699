.userpool-2fa .image img{
  max-width: 100%;
  margin: 9% auto;
}
.userpool-2fa > .layout-container {

  &.large {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

          }
}