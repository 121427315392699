onsw-message-notification > .box {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #88b40c;
  z-index: 4;
  cursor: pointer;
  padding: 5px;
}

onsw-message-notification > .box > .text {
  font-size: 16px;
}

onsw-message-notification > .box .icon {
  opacity: 0.5;
  vertical-align: top;
  height: 34px;
  width: 34px;
  margin-right: 5px;
  float: left;
}

onsw-message-notification > .box > span > .icon {
  margin-right: 0;
  margin-left: 5px;
  float: right;
}

onsw-message-notification > .box .icon * {
  fill: #fff;
}

@media (min-width: 768px) {
  body onsw-message-notification { display: none; }
}

@media (max-width: 767px) {
  body.show-dialog onsw-message-notification,
  body.show-contacts onsw-message-notification,
  body.regard-shop-open onsw-message-notification { display: none; }
}
