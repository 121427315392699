/* LOGIN */
.userPool-login.header-login-widget.ons-form > .box > label > input {
  font-size: 14px;
  background: white;
  border: 1px solid transparent;

  &:focus {
    box-shadow: 0 0 5px rgba(0,0,0,0.6);
  }
}

.userPool-login.header-login-widget > form > .buttons > .box > .button.submit > .box {
  background: #f32886;
  padding: 6px 8px;

  > .label {
    color: white;
  }

  &:hover {
    background: var(--green);

    > .label {
      color: #ba1a5b;
    }
  }
}

.ons-item.button.header-login-widget > .box {
  padding: 6px 8px;
}
