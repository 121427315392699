.doi-hint-box {
  h1 {
    color: var(--doi-hint-text-color);
  }

  > .overlay-content.ons-layout > .box {
    display: grid;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .image {
      grid-area: 2 / 1 / auto / auto;
      margin: 15px 0;

      @media (min-width: 1024px) {
        grid-area: 1 / 2 / span 2 / auto;
        margin: 0;
      }

      > .box {
        aspect-ratio: 1;
      }
    }

    .doi-message {
      @media (min-width: 1024px) {
        padding-right: 10px;
      }

      > .descr {
        font-family: var(--headline);
        font-weight: var(--doi-hint-text-font-weight);
        font-size: 18px;
        line-height: 1.2;
        color: var(--doi-hint-text-color);
        margin: 20px 0 0;
      }

      > .value {
        line-height: 1.2;
        font-size: 36px;
        font-weight: var(--doi-hint-text-font-weight);
        font-family: var(--headline);
        color: var(--doi-hint-text-color);
        text-transform: uppercase;
      }

      p {
        margin: 0 0 20px;
      }

      ul {
        padding-left: 15px;
        margin: 15px 0;

        li {
          margin: 10px;
          list-style-image: var(--checkmark-url);
        }
      }
    }

    .support {
      align-self: end;

      .link {
        display: inline-block;

        &:not(:last-of-type)::after {
          content: '|';
          margin-left: 5px;
        }

        > .box,
        a {
          color: var(--doi-hint-link-color);
        }
      }
    }
  }
}

