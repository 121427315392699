.ons-scroll > .scroll-overlay > .bar > .slider {
    opacity: 0.2;
    transition: opacity .3s ease, transform-origin .1s ease-out;
    background-color: #888;
}

.ons-scroll:hover > .scroll-overlay > .bar > .slider {
    opacity: 0.5;
}

.ons-scroll > .scroll-overlay > .page-down > .icon,
.ons-scroll > .scroll-overlay > .scroll-top > .icon {
  height: 15px;
  width: 15px;
  vertical-align: text-top;
}

.ons-scroll > .scroll-overlay > .scroll-top > .icon {
  fill: #f90;
}

.ons-scroll > .scroll-overlay > .page-down > .icon {
  fill: #8dbd0d;
}
