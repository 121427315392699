.ons-layout.popup {
  display: block;
  position: absolute;
  max-width: 300px;
  z-index: 1;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
  background-clip: border-box;
  width: 100%;
  padding: 0;
}

.ons-layout.popup.center {
  transform: translate(-50%, 0);
  left: 50%;
}

main .ons-layout.popup:not(.up) {
  margin-bottom: 80px;
}

.ons-layout.popup.full-height {
  min-height: 100%;
}

.ons-layout.popup.up {
  transform: translate(0, calc(-100% + 50px));
}

.ons-layout.popup.up.center {
  transform: translate(-50%, calc(-100% + 50px));
}

.ons-layout.popup > .box {
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}
