.fmotd-teaser-hook .layout-toolbar.toolbar-list > .box > [onsw-item].add > .box > .label {
  color: #f90;
  font-family: var(--default-font);
  font-weight: bold;
  font-size: 12px;
}

.fmotd-teaser-hook .layout-toolbar.toolbar-list > .box > [onsw-item].title > .box > .label {
  font-size: 13px;
}

.fmotd-teaser-hook .current .layout-toolbar.toolbar-list > .box > [onsw-item] > .box > .label {
  font-size: 16px;
  color: #f90;
}

.fmotd-teaser-hook .top,
.fmotd-teaser-box {
  background: black;
}

.fmotd-teaser-hook .layout-toolbar.toolbar-list {
  background: linear-gradient(0deg, black, transparent);
}
