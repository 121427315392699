@import '../layout/panel.css';
@import '../layout/toolbar.css';
@import '../layout/input.css';
@import '../layout/price.css';

/* TODO regard send should use generic loading template */
.sending > .layout-panel.regard-send::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url('../../basic/img/loading.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.8);
}

.layout-panel.regard-send {
  box-shadow: var(--panel-regard-send-shadow);

  /**
     * Center the heading
     *
     * Trick 17: By creating an empty element with of the size of a close-icon.
     */
  > .heading.layout-toolbar {
    &::before {
      content: '';
      width: var(--toolbar-icon-size, 18px);
    }
  }

  > .image {
    height: var(--panel-regard-send-image-size, 85px);
    margin: auto;
  }

  > .layout-field {
    > .layout-input {
      height: 80px;
    }

    > .layout-price {
      position: absolute;
      bottom: 5px; /* margin? */
      right: 5px;
      color: var(--regard-send-price-color, var(--price-color));
    }
  }
}
