[onsw-payment-mobile-payment].payment .package > .box,
[onsw-payment-packages].payment .package > .box {
  border-color: #d1d1d1;
}
[onsw-payment-mobile-payment].payment .package:not(.active):hover > .box,
[onsw-payment-packages].payment .package:hover > .box {
  background-color: #f5f5f5;
}
[onsw-payment-packages].payment .package.packageSelected > .box {
  border-color: #b53e44;
}
[onsw-payment-progress].step .bar div {
  background: #b53e44;
}
[onsw-payment-progress].step li.active div,
[onsw-payment-progress].step li.finalized div {
  background: #b53e44;
}
[onsw-payment-progress].step li.active p {
  color:#b53e44;
}
