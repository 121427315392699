.ons-image[click-action] > .box,
.ons-image[image-click] > .box {
  cursor: pointer;
}

.ons-image.noPointer > .box {
  cursor: unset;
}

.ons-image > .box {
  padding-top: 75%;
}
.overlay-content .ons-image > .box {
  padding-top: 100%;
}

.ons-image.done > .box,
.ons-image.error > .box {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ons-image.busy > .box {
  background: url('../basic/img/loading2.svg') center no-repeat !important;
}

.ons-image.wide > .box {
  padding-top: 56%;
}

.ons-image.square > .box {
  padding-top: 100%;
}

/**
 * Kachel
 */
.ons-list.default .ons-image > .box,
.fmotd-teaser-box > .currentBox .ons-image > .box,
.fmotd-teaser-box > .nextBox .ons-image > .box {
  height: 100%;
  width: 100%;
  transition: transform ease 1s;
}

.ons-list.default :hover > div > div > .ons-image.done > .box,
.fmotd-teaser-box > .currentBox :hover > div > div > .ons-image.done > .box,
.fmotd-teaser-box > .nextBox :hover > div > div > .ons-image.done > .box {
  transition-delay: 0.2s;
  transform: scale(1.2);
}

.ons-list.default .ons-image {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.fmotd-teaser-box > .nextBox .ons-image {
  overflow: hidden;
}

/**
 * Livecam Loading Image
 */
.ons-image.livecamLoading {
  height: 100%;
}

.ons-image.livecamLoading > .box {
  height: 100%;
  width: 100%;
}

/**
 * MESSENGER DIALOG
 */
.ons-image.messengerDialog {
  float: left;
  padding: 10px;
  pointer-events: none;
}

.ons-image.messengerDialog > .box {
  display: inline-block;
  vertical-align: top;
  padding-top: 56px;
  width: 108px;
}

@media (max-height: 450px) {
  .ons-image.messengerDialog {
    display: none;
  }
}

/**
 * global feed
 */
.ons-image.small-feed-widget {
  float: left;
  margin-right: 10px;
}

.ons-image.small-feed-widget > .box {
  height: 70px;
  width: 70px;
}

/**
 * PICTURE SLIDESHOW
 */
.ons-image.pictureSlideshow > .box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ons-image.pictureSlideshow.done > .box {
  padding-top: unset;
  background-size: contain;
}

/**
 * PAYMENT BADGES
 */

[hookname='paymentBadges'] > .box {
  border-top: 1px solid;
  background: white;
  text-align: center;
  overflow: hidden;
}
.ons-image.paymentBadges > .box {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: unset;
  background-color: transparent !important;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ons-image.paymentBadges {
  display: inline-block;
  vertical-align: middle;
  padding: 0 6px;
}

.ons-image.paymentBadges:nth-child(1) {
  width: 46px;
  height: 50px;
}

.ons-image.paymentBadges:nth-child(2) {
  width: 67px;
  height: 50px;
}

.ons-image.paymentBadges:nth-child(3) {
  width: 46px;
  height: 50px;
}

@media (min-width: 1024px) {
  .ons-image.paymentBadges:nth-child(1) {
    width: 57px;
    height: 75px;
  }

  .ons-image.paymentBadges:nth-child(2) {
    width: 92px;
    height: 75px;
  }

  .ons-image.paymentBadges:nth-child(3) {
    width: 57px;
    height: 75px;
  }
}

/**
 * Sidebar-Banner
 */
.ons-image.otm > .box {
  background-size: cover;
  background-position: top center;
  height: 100%;
}

/**
 * Hotornot Home Banner
 */
.ons-image.otmHome > .box {
  padding-top: 40%;
}

@media (min-width: 1280px) {
  .ons-image.otmHome > .box {
    padding-top: 30%;
  }
}

.ons-image.otmHome.fmotd > .box {
  background-position: center bottom;
}
