.small-amateur-tile-box > .box > .icon * {
  fill: #8dbd0d;
}

.icon.offline * {
  fill: var(--icon-offline-color);
}

.icon.online * {
  fill: var(--icon-online-color);
}

.icon.match2 {
  fill: #ff4747;
}

