onsw-section.messenger-panel > .buttons > .ons-item {
  > .box {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    background: var(--sedcard-button-bg);
    height: var(--sedcard-button-height);
    padding: var(--sedcard-button-padding);
    color: var(--sedcard-button-text-color);
    font-size: var(--sedcard-button-text-size);
    border: var(--sedcard-button-border);
    border-radius: var(--button-border-radius);
    box-sizing: border-box;

    @media (hover: hover) {
      &:hover {
        background: var(--sedcard-button-bg-hover);
        color: var(--sedcard-button-text-color-hover);
        border: var(--sedcard-button-border-hover);

        > .icon.attachment {
          fill: var(--sedcard-button-hover-attachment-icon);
        }
      }
    }

    &.disabled > .label {
      opacity: 0.5;
    }

    > .icon {
      width: 30px;
      height: 30px;
      flex: 0 0 30px;

      &.attachment {
        fill: var(--sedcard-button-attachment-icon);
      }

      &.chatColor {
        .chatColor1 {
          fill: var(--sedcard-button-chatColor1);
        }

        .chatColor2 {
          fill: var(--sedcard-button-chatColor2);
        }
      }
    }

    > .label {
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

