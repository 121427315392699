.layout-summary {
  &::-webkit-details-marker {
    display: none;
  }

  .layout-closed > & {
    &::after {
      content: '\25be';
    }
  }

  .layout-open > & {
    &::after {
      content: '\25b4';
    }
  }
}
