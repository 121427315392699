file-upload {
  display: inline-block;
}
file-upload:before {
  padding: 5px 10px 6px;
  float: right;
  content: '…';
  color: #333;
  background: linear-gradient(to top, #bbb 0%, #ccc 100%);
  margin: -3px -6px;
}

file-upload > div {
  display:  inline-block;
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  max-width: calc(100% - 40px);
}
file-upload.ng-filled div {
  color: black;
}


file-upload input {
  display: none !important;
}
