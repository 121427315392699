[onsw-amateur-label].messengerDialog > .box > .goto {
  color: #f90;
}

@media (min-height: 451px) {
  [onsw-amateur-label].messengerDialog > .box > .goto {
    display: inline-block;
    padding-top: 1px;
  }
}

[onsw-amateur-label].messengerDialog > .box:hover > .goto {
  text-decoration: underline;
}
