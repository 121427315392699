@import '@acng/frontend-discovery/style.css';
@import '../layout/item.css';
@import '../layout/toolbar-list.css';

.friend-list.warp-grid {
  position: relative;
  --warp-gap: 2px;
  --warp-min-width: 170px;
  --warp-aspect-ratio: 1;
  --warp-aspect-ratio-fallback: 100%; /* see mdn aspect-ratio browser support */

  > .emptyList {
    grid-column: 1 / end;
    aspect-ratio: unset;
  }

  > .layout-item {
    overflow: hidden; /* for the border-radius */
    grid-template-rows: 1fr min-content;

    > .image {
      cursor: pointer;
      height: 100%;
      width: 100%;
      object-fit: cover;
      grid-area: 1 / 1 / 3 / 2;
    }

    > .preset-overlay {
      z-index: 1; /* the .toolbar-list creates a layer because of the `filter` */
      grid-area: 1 / 1 / 3 / 2;
      align-self: end;
    }

    > .layout-toolbar.toolbar-list {
      grid-area: 2 / 1 / 3 / 2;

      > .title,
      > .submenuButton {
        cursor: pointer;
      }

      > .submenuButton {
        width: 20px;
        height: 20px;
        fill: var(--item-color);
      }
    }
  }
}
