onsw-movie-player {
  display: block;
  padding-top: 56.25%;
  position: relative;
  border: var(--default-border);
  border-radius: var(--default-border-radius);
  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
    &.loading img {
      cursor: wait;
    }
    > .video-js > .vjs-big-play-button {
      display: none;
    }
    &::after {
      transition: opacity .5s ease;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      pointer-events: none;
      filter: drop-shadow(0 2px 2px rgba(0,0,0,.3));
    }
    &.preview::after {
      content: url("../../basic/img/play.svg");
      height: 150px;
      width: 150px;
    }
    &.loading::after {
      content: url("../../basic/img/loading2.svg");
      height: 50px;
      width: 50px;
    }
    @media (hover: hover) {
      &.preview::after {
        opacity: 0;
      }
      &.preview:hover::after {
        opacity: 1;
      }
    }
    /* Fix for Samsung Internet browser, which has a to good hover emulation */
    @media (pointer: coarse) {
      &.preview::after {
        opacity: 1;
      }
    }
  }
}
