@import '../layout/toolbar.css';
@import '../layout/link.css';

.layout-toolbar.layout-tabs {
  overflow: auto hidden;
  white-space: nowrap;

  &.sticky {
    z-index: 2;
    position: sticky;
    top: -10px;
    padding: 10px 0;
    margin: -10px 0;
    background: var(--tabs-sticky-background, var(--menu-bg));
    transition: background 1s ease;

    .scroll-min & {
      margin-bottom: 0;
      padding-bottom: 0;
      background: transparent;
    }
  }

  > .layout-link {
    height: var(--submenu-item-height);
    padding: var(--menu-item-padding, var(--link-padding));
    font: var(--menu-item-label-font, var(--link-font));
    color: var(--menu-item-label-color, var(--link-color));
    background: var(--menu-bg, var(--link-background));
    border-radius: var(--menu-item-border-radius, var(--link-border-radius));
    border: var(--menu-item-border-width) solid var(--menu-item-border-color);
    outline: none;

    @media (hover: hover) {
      &:hover {
        border: var(--menu-item-border-width) solid var(--menu-item-border-color-hover);
        color: var(--menu-item-label-color-hover, var(--link-color-hover));
        background: var(--menu-item-bg-hover, var(--link-background-hover));
      }
    }

    &.layout-active {
      border: var(--menu-item-border-width) solid var(--menu-item-border-color-active);
      color: var(--menu-item-label-color-active, var(--link-color-active, var(--link-color)));
      background: var(--menu-item-bg-active, var(--link-background-active, var(--link-background)));
      font-weight: var(--tab-active-font-weight);
    }

    > .value {
      &::before {
        content: "(";
      }

      &::after {
        content: ")";
      }
    }

    &.layout-empty > .value {
      display: none;
    }
  }
}
