@import '../layout/panel.css';
@import '../layout/container.css';
@import 'timer.css';
@import 'session-info.css';

.layout-container.games {
  grid-template-rows: minmax(0, min-content) auto;

  &.large {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

    > .sessions {
      grid-row: 1 / end;
    }
  }

  onsw-games.warp-column-flex {
    --warp-min-height: 110px;
    --warp-gap: 10px;

    > * > a {
      grid-template-columns: 100px auto 100px;
      text-decoration: none;

      > img {
        grid-row: 1 / span 3;
        height: 90px;
        width: 90px;
        object-fit: cover;

        &.partner {
          grid-column-start: 1;
        }

        &.game {
          grid-column-start: 3;
        }
      }
    }
  }

  onsw-game-suggestions.warp-column-flex {
    --warp-min-height: 90px;
    --warp-gap: 10px;

    > * > a {
      animation: ons-fade-in 1s ease;
      box-sizing: border-box;
      display: grid;
      align-items: center;
      grid-template-columns: 70px minmax(0, auto);
      gap: 10px;
      padding: 10px;
      background-color: var(--game-suggestions-bg);
      text-decoration: unset;
      color: var(--game-suggestions-text-color);
      border: var(--default-border);
      border-radius: var(--default-border-radius);

      &:hover {
        text-decoration: none;

        > .text {
          > .nickname {
            color: var(--game-suggestions-hover-color);
          }

          > .desc {
            color: var(--game-suggestions-text-color);
          }
        }

        > onsw-game-invite {
          color: var(--game-suggestions-text-color);
        }
      }

      > img {
        height: 70px;
        width: 70px;
        object-fit: cover;
      }

      > .text {
        > .nickname {
          font: var(--game-headline);
          color: var(--game-suggestions-name-color);
        }

        > .desc {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      > onsw-game-invite {
        grid-area: 2 / 1 / auto / span 2;
      }
    }

    &.large > * > a {
      grid-template-columns: 70px minmax(0, auto) min-content;
      gap: 0 10px;

      > img {
        grid-row-end: span 2;
      }

      > onsw-game-invite {
        grid-area: 1 / 3;
      }
    }
  }

  > .rules {
    .image {
      display: block;
      width: 100%;
    }

    h2 {
      font: var(--game-headline);
      color: var(--rules-color);
      margin: 15px 0 5px;
    }

    ul {
      margin: 0 0 10px 15px;
      padding-left: 10px;

      li {
        margin: 5px;
        list-style-image: var(--checkmark-url);
      }
    }
  }
}

onsw-section.game-tab,
onsw-section.games {
  .ons-image.banner > .box {
    padding-top: 35% !important;
  }
}
