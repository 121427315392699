.ons-overlay {
  color: #1a1a1a;
}

.ons-overlay > .content > .top > header {
  border-bottom: 1px solid #666;
  background-color: #333;
  color: #ccc;
}

.ons-overlay > .content > div:not(.payment) {
  background: #444;
  color: #ccc;
}

.ons-overlay .ons-close {
  position: absolute;
  bottom: 0;
  right: 0;
  border-left: 1px solid #666;
}

.ons-overlay .ons-close:hover {
  background: #c00;
  color: #fff !important;
}

.ons-overlay .ons-close > span {
  margin-top: -6px !important;
}