.userPool-login.ons-form.inline > form > label > input {
  font-family: var(--headline);
  font-size: 16px;
}

.userPool-login > form > label:nth-of-type(3) {
  height: 0;
}

.pageLogin  .ons-item.link.signupLong  > .box{
  color: #f90;
}
.pageLogin .link:nth-child(odd)::after {
  color: white;
}
