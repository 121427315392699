[onsw-profile-image] {
  position: relative;
}

[onsw-profile-image] img {
  display: block;
  width: 100%;
}

[onsw-profile-image] > form > label:nth-of-type(3) {
  clear: left;
}
