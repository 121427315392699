.overlay.idle-hook > .box {
  pointer-events: none;
}
.overlay.idle-hook > .box .ons-image > .box {
  background-color: unset;
}

.overlay.idle-hook > .box [onsw-image].idle-bubble > .box {
  background-size: contain;
  padding-top: 55%;
}

.overlay.idle-hook > .box p {
  padding: 10px 40px;
  font-weight: 600;
  font-size: 14px;
}

.overlay.idle-hook > .box p:last-of-type {
  color: var(--portal-small-box-title-color);
}

.overlay > .box [onsw-image].idle > .box {
  background-position: bottom right;
  padding-top: 130%;
}