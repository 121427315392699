[onsw-payment-packages].payment {
  overflow: hidden;
}

[onsw-payment-packages].payment .package:nth-child(1) > .freeCoins::after  {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center 10px;
    background-repeat: no-repeat;
    z-index: 10;
}
:lang(de)[onsw-payment-packages].payment .package:nth-child(1) > .freeCoins::after  {
     background-image: url(../../basic/img/freecoins/free_coins_de.png) !important;
 }
:lang(en)[onsw-payment-packages].payment .package:nth-child(1) > .freeCoins::after  {
    background-image: url(../../basic/img/freecoins/free_coins_en.png) !important;
}
[onsw-payment-packages].payment .package:nth-child(1) > .freeCoins  {
    background-image: linear-gradient(to bottom,  rgba(194,28,28,1) 0%,rgba(227,93,36,1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    z-index: 0;
    background-position: top;
    min-height: 210px;
    margin-top: 30px;
    box-shadow: 0px 3px 4px #a90301ba;
}

[onsw-payment-packages].payment .package > .freeCoins > .box {
    position: relative;
    top: 155px;
}

[onsw-payment-packages].payment .package:nth-child(1) > .box {
    background-image: url(../../basic/img/coins_1.png);
}
[onsw-payment-packages].payment .package:nth-child(2) > .box {
    background-image: url(../../basic/img/coins_2.png);
}
[onsw-payment-packages].payment .package:nth-child(3) > .box {
    background-image: url(../../basic/img/coins_3.png);
}
[onsw-payment-packages].payment .package:nth-child(4) > .box {
    background-image: url(../../basic/img/coins_4.png);
}
[onsw-payment-packages].payment .package:nth-child(5) > .box {
    background-image: url(../../basic/img/coins_5.png);
}

[onsw-payment-packages].payment .package > .box {
    background-repeat: no-repeat;
    padding: 5px;
    background-position: 108px bottom;
    margin-top: 15px;
    min-height: 85px;
    cursor: pointer;
    border: 1px solid;
    position: relative;
    line-height: 1;
}

[onsw-payment-mobile-payment].payment .package .price,
[onsw-payment-packages].payment .package .price {
    position: absolute;
    bottom: 7px;
    left: 10px;
    font-size: 18px;
    font-weight: bold;
    color: black;
}

[onsw-payment-packages].payment .package .name {
    position: relative;
    top: -15px;
    padding: 0 5px;
    background: white;
    text-align: left;
}

[onsw-payment-mobile-payment].payment .package .value.coins,
[onsw-payment-packages].payment .package .value.coins {
    position: absolute;
    bottom: 7px;
    right: 10px;
    text-align: right;
    font-size: 21px;
    font-weight: bold;
    color: black;
}
[onsw-payment-mobile-payment].payment .package .value.coins .default,
[onsw-payment-packages].payment .package .value.coins .default {
    position: relative;
}
[onsw-payment-mobile-payment].payment .package.withBonus .value.coins .default::before,
[onsw-payment-packages].payment .package.withBonus .value.coins .default::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 3px solid;
    transform:rotate(-5deg);
}
[onsw-payment-packages].payment .package .value.coins .bonus {
    margin-top: 15px;
    font-size: 26px;
    text-shadow: 2px  2px 2px white, 2px -2px 2px white, -2px  2px 2px white, 0 2px 4px rgba(0,0,0,.6);
    display: block;
}

[onsw-payment-packages].payment .package.packageSelected > .box {
    border: 2px solid;
}

/* Badges */
[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.note,
[onsw-payment-packages].payment .package > .box  > .ons-item.note {
    display: none;
}
[onsw-payment-packages].payment .package.packageSelected > .box  > .ons-item,
[onsw-payment-packages].payment .package > .box  > .ons-item {
    display: block;
    position: absolute;
    top: -8px;
    left: 15px;
    text-align: left;
    font-weight: bold;
    color: white;
    padding: 3px;
    transform: rotate(-7deg);
    width: 80px;
    height: 52px;
    border: 3px solid white;
    box-shadow: 0 2px 10px rgba(0,0,0,.4);
}

@media (max-width: 1023px) {
    [onsw-payment-packages].payment .package.withBonus > .box {
        margin-left: 25px;
    }

    [onsw-payment-packages].payment .package.withBonus > .box  > .ons-item {
        left: -15px;
    }

    [onsw-payment-packages].payment .package:not(.withBonus).packageSelected > .box  > .ons-item {
        top: -15px;
        right: 30px;
        left: auto;
        transform: none;
    }

    [onsw-payment-packages].payment .package > .freeCoins > .box {
        top: 160px;
        text-align: center;
    }

    [onsw-payment-packages].payment .package:nth-child(1) > .freeCoins {
        margin-top: 0;
    }

    :lang(de)[onsw-payment-packages].payment .package:nth-child(1) > .freeCoins::after {
        background-image: url(../../basic/img/freecoins/free_coins_mobile_de.png) !important;
        background-position: center 0;
    }

    :lang(en)[onsw-payment-packages].payment .package:nth-child(1) > .freeCoins::after {
        background-image: url(../../basic/img/freecoins/free_coins_mobile_en.png) !important;
        background-position: center 0;
    }
}

[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus:after,
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus:after {
    content: '+';
    position: absolute;
    top: -15px;
    left: -9px;
    font: bold 26px Arial, Helvetica, sans-serif;
}
[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus,
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus {
    text-align: center;
}
[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus .value,
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus .value {
    position: relative;
    bottom: auto;
    right: auto;
}
[onsw-payment-packages].payment .package.packageSelected > .box  > .ons-item.note .label {
    font-size: 13px;
    line-height: 1;
}
[onsw-payment-packages].payment .package.packageSelected > .box  > .ons-item.note .label span {
    font-size: 30px;
    text-transform: uppercase;
}

@media (min-width: 1024px) {
    [onsw-payment-packages].payment {
        min-height: 260px;
    }
    [onsw-payment-packages].payment .package > .box {
        margin-top: 35px;
        width: 100%;
        text-align: center;
        min-height: 200px;
        background-position: center 90px;
    }
    [onsw-payment-packages].payment .package.packageSelected > .box {
        margin-top: 30px;
        min-height: 220px;
    }
    [onsw-payment-packages].payment .package.packageSelected > .box  > .ons-item {
        top: -8px;
        right: auto;
        left: 15px;
        transform: rotate(-7deg);
    }
    [onsw-payment-packages].payment .package.withBonus > .box {
        background-position: center 50px;
    }
    [onsw-payment-packages].payment .package .price {
        bottom: auto;
        left: auto;
        right: 5px;
    }
    [onsw-payment-packages].payment .package .name {
        top: 40px;
        padding: 0;
        text-align: center;
        background: transparent;
    }
    [onsw-payment-packages].payment .package .name span {
        display: block;
    }
    [onsw-payment-packages].payment .package .value {
        text-align: center;
    }
    [onsw-payment-packages].payment .package .value.coins {
        width: calc(100% - 10px);
        bottom: 10px;
        right: auto;
        text-align: center;
    }
    [onsw-payment-packages].payment .package.packageSelected .value.coins {
        bottom: 25px;
    }
    [onsw-payment-packages].payment .package.packageSelected > .box  > .ons-item,
    [onsw-payment-packages].payment .package > .box  > .ons-item {
        top: -30px;
        left: 5px;
    }
}

[onsw-payment-packages].rebuy .name,
[onsw-payment-packages].rebuy .note,
[onsw-payment-packages].rebuy .description {
  display: none;
}
[onsw-open-overlay].ons-item.buy > .box {
      margin: auto;
      display: block;
      width: 200px;
      border: unset;
}

[onsw-payment-packages].rebuy .package > .box {
    display: block;
    font-weight: 400;
    padding: 4px;
    height: unset;
    text-align: center;
}
[onsw-payment-packages].rebuy .package > .box > .value {
    font-weight: 600;
}
[onsw-payment-packages].rebuy .package.withBonus > .box {
    white-space: nowrap;
    line-height: 1;
    text-align: center;
}
[onsw-payment-packages].rebuy .package.withBonus > .box .price {
    margin: 6px 0;
    font-size: larger;
    font-weight: 600;
}
[onsw-payment-packages].rebuy .package.withBonus > .box > .value > .default {
    display: none;
}
[onsw-payment-packages].rebuy .package.withBonus > .box > .value > .bonus {
    display: block;
    font-size: larger;
    white-space: nowrap;
}
