div, aside, header, section, figure, footer, p, article, nav {
  box-sizing: border-box;
}

[hidden],
template {
  display: none !important;
}

h1, h2, p, figure {
  margin: 0;
}

body {
  margin: 0;
  line-height: 1.4142;
}
:focus,
:required {
  outline: none;
}

[tabindex="-1"] {
  outline: none;
}

.ons-layout[hookname="main"] > .box > [onsw-hook] {
  position: relative;
}

.relative > .box {
  position: relative !important;
}

.spacer {
  margin-bottom: 10px;
}

.icon.online,
.icon.offline {
  height: 12px;
  width: 12px;
}
:not(.layout-toolbar) > * > .icon.close {
  * {
    fill: var(--default-close-color);
  }

  &:hover * {
     fill: var(--default-close-color-hover);
   }
}

/* Stargazer resource "not found" */
.drf-notfound {
  display: inline-block;
  background: #000;
  border: 2px dashed #ff0;
  outline: 2px solid #000;
  color: #ff0;
  padding: 5px 10px;
  border-radius: 5px;
}
