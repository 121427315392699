[onsw-payment-mobile-payment].payment .package:not(.active):hover > .box,
[onsw-payment-packages].payment .package:hover > .box,
[onsw-payment-methods] .method:not(.active):hover .box {
  background-color: #f5f5f5;
}
[hookname="paymentBadges"] > .box {
  border-color: #d1d1d1;
}

[onsw-payment-packages].payment .package .name {
  font-size: 18px;
  color: #f90;
  font-family: var(--headline);
}

[onsw-payment-mobile-payment].payment .package,
[onsw-payment-packages].payment .package {
  font-family: var(--headline);
}
[onsw-payment-mobile-payment].payment .package > .box  > .ons-item,
[onsw-payment-packages].payment .package.packageSelected > .box  > .ons-item,
[onsw-payment-packages].payment .package > .box  > .ons-item {
  background: #333;
}
[onsw-payment-packages].payment .package:not(.withBonus).packageSelected > .box > .ons-item {
  right: 20px;
}
[onsw-payment-packages].payment .package.packageSelected > .box {
  border-color: #333;
}
[onsw-payment-packages].payment .package .value.coins .bonus {
  color: #cc0000;
  font-size: 32px;
  margin-top: 10px;
}

[onsw-payment-mobile-payment].payment .package .value.coins .bonus {
  color: #cc0000;
}

[onsw-payment-packages].payment .package .value.coins .default {
  font-size: 24px;
}
[onsw-payment-mobile-payment].payment .package.withBonus .value.coins .default:before,
[onsw-payment-packages].payment .package.withBonus .value.coins .default:before {
  border-color: #cc0000;
}
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus .value {
  font-size: 24px;
  font-family: var(--default-font);
}
[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus .value {
  font-family: var(--default-font);
}
[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus:after,
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus:after {
  color: #cc0000;
}
[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus .label,
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus .label {
  font-size: 13px;
  letter-spacing: 1px;
  font-family: var(--headline);
}
[onsw-payment-packages].payment .package.packageSelected > .box > .ons-item.note .label span {
  font-family: var(--default-font);
}
[onsw-payment-methods] .method .methodVerifiedFlag {
  color: #89a509;
}
[onsw-payment-methods] .method .methodVerifiedFlag > span {
  color: #f90;
}
[onsw-payment-methods] .method.active .box {
  background-color: #9CB830;
  border: 1px solid #9CB830;
  color: #ffffff;
}

[onsw-payment-mobile-payment] .package.active > .box {
  background-color: #9CB830;
  border-color: #9CB830;
}

[onsw-payment-mobile-payment].payment .package.active .value.coins .bonus,
[onsw-payment-mobile-payment].payment .package.active .price {
  color: #ffffff;
}
