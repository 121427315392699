@import '../core/layout/sedcardButtons.css';

onsw-section.messenger-panel {
  position: relative; /* for the popups */

    > .layout-overlay > [onsw-hook] > .layout-panel,
    > .layout-overlay > .layout-panel {
      width: 300px;
    }

  > .layout-panel.messenger-composer {
    display: contents;
  }

  > .buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: var(--sedcard-button-height);
    gap: 6px;
  }

  > .games {
    position: relative;
    display: flex;
    gap: 6px;

    > .buttons {
      flex-grow: 1;
      display: flex;
      gap: 2px;

      > onsw-game-invite {
        flex-grow: 1;

        > .box {
        justify-content: left;
        display: grid;
        grid-template-columns: auto auto 1fr auto;
        grid-template-rows: auto auto;
        row-gap: 0;

        &::before {
          display: none;
        }

        &:hover {
          background: var(--game-inviteBox-bg);
          color: var(--game-inviteBox-sedcard-bg-hover-text-color);

          > .label {
            color: var(--game-inviteBox-sedcard-bg-hover-label-color);
          }
        }

        > img {
          grid-row-end: span 2;
          max-height: 42px;
        }

        > .text-game {
          grid-column: 2 / span 2;
          grid-row: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > .label {
          grid-column: 2;
          grid-row: 2;
          text-transform: var(--games-session-text-transform);
          color: var(--game-inviteBox-border-color);
          align-self: baseline;
        }

        > .value {
          grid-column: 3;
          grid-row: 2;
          align-self: baseline;
          position: relative;

          &::before {
            content: '(';
            position: relative;
            left: -1px;
          }

          &::after {
            content: ')';
            position: relative;
            right: -1px;
          }
        }

      }
    }
        > .info {
          aspect-ratio: 1;
          height: 100%;

          box-sizing: border-box;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            background: var(--game-inviteBox-bg);
            border-radius: var(--button-border-radius);
            cursor: pointer;
            padding: 5px 10px;

            > .icon {
              border-radius: 50%;
              width: 20px;
              height: 20px;
              background: var(--games-info-background);

              * {
                fill: var(--games-info-icon);
              }
            }

          &:hover > .icon {
            background: var(--games-info-hover-background);
          }
        }
    }

    > onsw-game-user-stats {
      display: contents;
    }

    .popup a {
      color: var(--link-color);
    }
  }

  &.tiny > .games > .buttons > .info {
    display: none;
  }

  &.small {
    > .buttons {
     grid-template-columns: repeat(2, 1fr);

      > [label="payment.buyCoins"] {
        display: none;
      }
    }

    > .games {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      > .buttons {
        grid-column-end: span 2;
      }
    }
  }
}
