@import '../layout/tabs.css';
@import '../layout/panel.css';

.amateurPool-sedcard-header {
  > .layout-tabs > .layout-link {
    animation: sedcard-header-tabs-fade-in .5s ease-out;

    &.layout-empty {
      display: none;
    }

    @media (max-width: 767px) {
      > .value {
        display: none;
      }
    }
  }

  > .layout-panel {
    margin-bottom: 10px;
    background: var(--sedcard-heading-bg, var(--panel-background));

    > .heading {
      font: var(--sedcard-heading-font, var(--panel-heading-font));
      text-transform: none;
      text-decoration: none;

      > .from {
        color: var(--sedcard-heading-from-color);
        font: var(--sedcard-heading-from-font);

        @media (max-width: 480px) {
          display: none;
        }
      }
    }
  }
}

@keyframes sedcard-header-tabs-fade-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
