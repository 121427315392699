.ons-text.empty {
  display: none !important;
}

.ons-text > .box > .text {
  white-space: pre-line;
}

.ons-text[text-click] > .box {
  cursor: pointer;
}

