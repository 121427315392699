/**
 * ### Documentation
 */
.layout-doc {
  p:not(:first-child) {
    margin-top: 10px;
  }
  p:not(:last-child) {
    margin-bottom: 10px;
  }
}
