.small-media-tile {
  /*box-sizing: border-box;*/
  height: var(--warp-height);
  padding: 5px;
  display: grid;
  grid-template-columns: 70px auto;
  grid-template-rows: min-content auto;
  gap: 0 5px;
  cursor: pointer;
  background: var(--portal-small-box-bg);

  > img {
    grid-row-end: span 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > .title {
    font-family: var(--headline);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 24px;
    color: var(--portal-small-box-title-color);
    font-size: var(--portal-small-box-title-font-size);
    font-weight: var(--portal-small-box-title-font-weight);
  }

  > .desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: calc(46px / 3);
  }
}

/* SKT AMX DST */
.amx-dst .small-media-tile {
  grid-template-columns: 66px auto;
  gap: 0 6px;
  padding: 6px;
  background: white;
  img {
    border-radius: 50%;
  }
  > .title {
    line-height: 20px;
  }
}
