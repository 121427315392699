[onsw-chosen] > .box > .info {
  font-family: var(--default-font);
  line-height: 28px;
}

[onsw-chosen] > .box input,
[onsw-chosen] > .box .available,
[onsw-chosen] > .box > .info {
  color: #333;
  background: #ddd;
  border: 1px solid #222;
}
