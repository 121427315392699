.box:not(.withBonus) > .ons-layout.overlay-toolbar.transactionResultOverlay > .box,
.box:not(.withBonus) > .ons-layout.overlay-toolbar.paybackResultOverlay > .box,
.box:not(.withBonus) > .ons-layout.overlay-toolbar.payment > .box {
  background: white;

  > .ons-item > .box {
    color: #de2872 !important;
    background: white !important;
  }
}

[onsw-payment-mobile-payment].payment .package:not(.active):hover > .box,
[onsw-payment-packages].payment .package:hover > .box,
[onsw-payment-methods] .method:not(.active):hover .box {
  background-color: #f8ccf0;
}

[onsw-payment-mobile-payment] .package.active > .box,
[onsw-payment-methods] .method.active .box {
  background-color: #f8ccf0;
  border: 2px solid #de287c;
  color: #1d2129;
}

[onsw-payment-new-window] > .box > .buttons > .box > .ons-item.button.no > .box {
  background: #de287c;
  border: 0;

  > .label {
    color: white;
  }
}

[onsw-payment-mobile-payment].payment .package > .box > .ons-item,
[onsw-payment-packages].payment .package.packageSelected > .box > .ons-item {
  background: var(--green);
  color: white;
}

[onsw-payment-packages].payment .package.packageSelected > .box {
  border-color: #de2872;
}

[onsw-payment-packages].payment .package .name {
  font-size: 15px;
  color: #a60a6e;
  font-weight: 600;
}

.ons-item.backLink > .box > .label::before {
  border-right-color: #de287c;
}

.payment.ons-item.link > .box,
.payment.ons-item.link > .box:hover,
.payment .ons-item.link > .box,
.payment .ons-item.link > .box:hover,
.payment a,
.payment a:hover {
  color: #de287c;
}

[onsw-payment-methods] .method .methodVerifiedFlag {
  color: #42b72a;
}

[onsw-payment-methods] .method .methodVerifiedFlag > span {
  color: #de2872;
}

[onsw-payment-country] > form.box > label > span {
  color: #1d2129;
  font-family: var(--headline);
  font-size: 14px;
}

.ons-heading.payment > .box {
  color: #1d2129;
  font-family: var(--headline);
  font-weight: 600;
  font-size: 15px;
}

[onsw-payment-packages].payment .package > .box > .ons-item {
  background: var(--green);
  color: white;
}

[onsw-bonus].payment > .box > .ons-image > .box {
  padding-top: 200px;
}

[onsw-payment-mobile-payment].payment .package > .box > .ons-item.bonus .label,
[onsw-payment-packages].payment .package > .box > .ons-item.bonus .label {
  letter-spacing: unset;
}

[onsw-payment-packages].payment .package .value.coins .default {
  font-size: 21px;
}

[onsw-payment-packages].payment .package > .box > .ons-item.bonus .value {
  font-size: 26px;
}

[onsw-payment-mobile-payment].payment .package .value.coins .bonus,
[onsw-payment-packages].payment .package .value.coins .bonus {
  color: #de287c;
  margin-top: 15px;
  font-size: 26px;
  text-shadow: 2px 2px 2px white, 2px -2px 2px white, -2px 2px 2px white, 0 2px 4px rgb(0 0 0 / 60%);
}

[onsw-payment-mobile-payment].payment .package.withBonus .value.coins .default:before,
[onsw-payment-packages].payment .package.withBonus .value.coins .default:before {
  border-color: #bc1b5c;
}

[onsw-payment-mobile-payment].payment .package > .box > .ons-item.bonus:after,
[onsw-payment-packages].payment .package > .box > .ons-item.bonus:after {
  color: #de287c;
}

.ons-layout.overlay-toolbar.bonusOverlay > .box > .ons-item > .box {
  > .icon * {
    fill: white;
    opacity: .5;
  }

  &:hover > .icon * {
    opacity: 1;
  }
}


/* Payback */
.payment.payback h2 {
  color: #de287c;
  font-size: 14px;
  margin: 0 0 10px;
}

.payment.payback p.intro {
  margin: 0 0 10px;
}

.payment.payback p span {
  color: #de287c;
  font-weight: 600;
}

.payment.payback p.help-block {
  font-size: 11px;
  font-weight: normal;
  margin: 0;
}


[onsw-payment-progress].step li.active div,
[onsw-payment-progress].step li.finalized div {
  background: linear-gradient(to bottom, #de287c 0%, #a60a6e 100%);
}

[onsw-payment-progress].step li.active p {
  color: #de287c;
}

[onsw-payment-progress].step .bar div {
  background: linear-gradient(to bottom, #de287c 0%, #a60a6e 100%);
}
