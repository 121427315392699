@import '../tables.css';

.achievement-history {
  th {
    font: var(--achievement-heading-font);
    color: var(--achievement-font-color);
  }

  td {
    font-family: var(--default-font);
  }

  .icon {
    height: 12px;
    transform: scale(2) translate(2px, 0);
    width: 26px;
  }

  .date {
    width: 90px;
  }

  .value {
    width: 90px;
    text-align: center;
  }
}
