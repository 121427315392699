.ons-item.accordeon,
.accordeon-item > .accordeon-btn {
  > .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: color .3s ease;
    color: var(--accordeon-btn-color);
    padding: var(--accordeon-btn-padding);
    background: var(--accordeon-btn-bg);

    > .icon * {
      fill: var(--accordeon-btn-color);
      transition: fill .3s ease;
    }

    > .label {
      font: var(--accordeon-btn-font);
    }
  }

  &:hover {
    > .box {
      color: var(--accordeon-btn-hover-color);

      > .icon * {
        fill: var(--accordeon-btn-hover-color);
      }
    }
  }

  &.active > .box {
    > .label {
      color: var(--accordeon-btn-active-color);
    }

    > .icon {
      transform: rotate(180deg);
    }
  }
}

[onsw-facet-filter] .ons-item.accordeon {
  > .box {
    padding: unset;
  }

  &.active > .box {
    padding-bottom: 5px;
  }
}

.sexicon-list .accordeon-item {
  background: var(--accordeon-item-sexicons-bg);

  > .accordeon-btn > .box {
    background: var(--accordeon-btn-sexicons-bg);
  }
}
