
.payment.mobile-payment-headline > .box > .label > .note {
    color:red;
}

[onsw-payment-mobile-payment].payment .package > .box {
    background-repeat: no-repeat;
    padding: 5px;
    background-position: 108px bottom;
    margin-top: 15px;
    min-height: 60px;
    cursor: pointer;
    border: 1px solid;
    position: relative;
    line-height: 1;
}

[onsw-payment-mobile-payment].payment .package > .box > .box > img {
    height: 45px;
    width: 100px;
    position: absolute;
    left: 30%;
    top: 8px;
}

[onsw-payment-mobile-payment].payment .package > .box  > .ons-item {
    display: block;
    position: absolute;
    top: -14px;
    left: 15px;
    text-align: left;
    font-weight: bold;
    color: white;
    padding: 3px;
    transform: rotate(-7deg);
    width: 71px;
    height: 42px;
    border: 3px solid white;
    box-shadow: 0 2px 10px rgba(0,0,0,.4);
}

[onsw-payment-mobile-payment].payment .package .value.coins .default {
    font-size: 16px;
}

[onsw-payment-mobile-payment].payment .package .value.coins .bonus {
    margin-top: 8px;
    font-size: 18px;
    text-shadow: 2px  2px 2px white, 2px -2px 2px white, -2px  2px 2px white, 0 2px 4px rgba(0,0,0,.6);
    display: block;
}

[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus .value {
    font-size: 15px;
}

[onsw-payment-mobile-payment].payment .package.withBonus > .box {
    margin-left: 25px;
}

[onsw-payment-mobile-payment].payment .package.withBonus > .box  > .ons-item {
    left: -15px;
}

[onsw-payment-mobile-payment].payment .package.withBonus > .box  > .ons-item > .box > .label {
    font-size: 12px;
}

[onsw-payment-mobile-payment].payment .package.active  .value.coins .bonus {
    text-shadow: none;
}