@import '@acng/frontend-discovery/style.css';

.warp-grid {
  &.default { /* was onsw-tiles too */
    --warp-gap: var(--warp-gap-default, 2px);
    --warp-min-width: 170px;
    --warp-aspect-ratio: 1;
    --warp-aspect-ratio-fallback: 100%; /* see mdn aspect-ratio browser support */

    @media (min-width: 1280px) {
      --warp-min-width: 200px;
    }

    @media (min-width: 1900px) {
      --warp-min-width: 220px;
    }
  }

  &.media {
    --warp-gap: var(--warp-gap-default, 2px);
    --warp-min-width: 280px;
    --warp-aspect-ratio: 4 / 3;
    --warp-aspect-ratio-fallback: 75%;
  }

  .home-guest-hook & {
    --warp-gap: var(--warp-gap-default, 2px);
    --warp-min-width: 280px;
    --warp-aspect-ratio: 4 / 3;
    --warp-aspect-ratio-fallback: 75%;
  }

  .userpool-profile & { /* .warp-grid.warp-column-limit */
    --warp-gap: var(--warp-gap-default, 2px);
    --warp-min-width: 140px;
    --warp-limit: 10;
    --warp-column-limit: 2;
  }

  .home-guest-hook & > *,
  &.media > *,
  &.default > * {
    /* should be somewhere else */
    position: relative;

    > * {
      animation: ons-fade-in 1s ease;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.warp-column-flex.sidebar-feed {
  --warp-limit: 5;
  --warp-gap: 10px;
  --warp-min-height: 100px;
}
