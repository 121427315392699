[onsw-amateur-label].messengerDialog > .box > .goto {
  padding-left: 20px;
  font-weight: bold;
}

[onsw-amateur-label].messengerDialog.empty > .box > .goto {
  display: none;
}

@media (max-height: 450px) {
  [onsw-amateur-label].messengerDialog > .box > .goto {
    display: none;
  }
}

