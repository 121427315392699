.closeable > .box::before,
.ons-item.close > .box::after,
:not(.notify) > :not(.ons-item).close::after {
  font-family: var(--default-font);
  color: #d1d1d1;
}

.close.dark::after {
  color: #666;
}
