[onsw-toggle-button] > .box {
  cursor: pointer;
}

[onsw-toggle-button].caret > .box {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

[onsw-toggle-button].caret > .box::after {
  content: "";
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

[onsw-toggle-button].caret.inactive > .box::after {
  border-top: 4px dashed;
}

[onsw-toggle-button].caret.active > .box::after {
  border-bottom: 4px dashed;
}
