.layout-container.messenger > .box {
  background: var(--background-messenger);
}

.messenger-dialog-hook > .box {
  background-color: var(--background-messenger);
}

onsw-messenger-message-box.messengerDialog {
  background-image: url(../img/messenger.jpg);
}

@media (max-width: 767px) {
  .messenger-dialog-hook > .box > :nth-child(2) {
    background-image: url(../img/messenger.jpg)
  }
}

.ons-list > .box > .ons-layout.contact {
  background: var(--background-messenger);
  height: 75px;
}

.contact-box {
  > img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    margin: 4px 8px 4px 3px;
  }
}

.contact-tile {
  .timestamp {
    margin-top: 3px;
  }

  &:not(.active):hover {
    > .box {
      background: var(--dialog-hover);

      .preview {
        > * {
          fill: white;
          color: white;
        }
      }

      .timestamp,
      .nickname {
        color: white;
      }
    }
  }
}

@keyframes blubb {
  from { transform: scale(1); background-color: #b0eb2c;}
  to { transform: scale(1.4); background-color: #b0eb2c;}
}

onsw-messenger-text-box {
  &.messenger-dialog-widget {
    padding: 8px;
    border-top: none;

    > .box > label > textarea {
      background-color: white;
    }
  }

  &.compact > .box {
    > label {
      padding-right: 53px;
    }

    > .buttons > .box > .ons-item > .box {
      border: 1px solid var(--green);
      border-radius: 7px;

      &:hover {
        border: 1px solid #de287c;
      }

      &.messengerDialog {
        padding: 4px 8px 0;
      }
    }
  }
}

.ons-image {
  &.messengerDialog {
    padding: 10px;

    > .box {
      height: 60px;
      width: 60px;
      border-radius: 50%;
    }
  }
}

[onsw-amateur-label] {
  &.messengerDialog > .box {
    background: white;
    border-top: 1px solid #dddfe2;
    border-bottom: 1px solid #dddfe2;
    padding: 10px;

    &:hover {
      background: #d7f4ff;
    }

    > .age {
      font-size: 12px;
    }

    > .goto {
      color: #42b72a;
      line-height: 50px;
      padding-left: unset;
      font-weight: 600;
    }

    > .name {
      font-size: 15px;
      color: #de287c;
    }

    &:hover > .goto {
      color: #de287c;
    }

    @media (min-width: 768px) {
      > * {
        max-width: 170px;
      }
    }
  }
}

@media (max-width: 767px) {
  .messenger-dialog-box {
    background: var(--background-messenger);

    > .ons-layout.messengerDialog > .box {
      &:nth-child(1):not(.dialog-buttons-hook) {
        background: white;
      }

      > [onsw-amateur-label] > .box {
        height: 60px;
        background: unset;
        border: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        > .ons-image > .box {
          height: 36px;
          width: 36px;
          padding-top: unset;
          border-radius: 50%;
        }
      }
    }
  }
}

onsw-messenger-message-box {
  > .box {
    > .start {
      max-width: 50%;
      margin: 8px auto 12px;
      text-align: center;
      padding: 3px 6px;
      font-size: smaller;
      background: white;
      display: block;
      border-radius: 7px;
      font-style: normal;
      color: var(--text-messenger);
    }

    > .msg {
      > .box {
        min-width: 60%;
        max-width: 85%;

        > img {
          width: auto;
          height: 50px;
          margin-right: 8px;
        }
      }

      &.in {
        > .box {
          color: var(--text-messenger);
          background: var(--msg-in);
          border-radius: 0 7px 7px 7px;
          filter: drop-shadow(3px 2px 2px rgba(0, 0, 0, .05));

          &::after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: var(--msg-in) transparent transparent transparent;
            top: 0;
            left: -10px;
          }
        }

        &.ng-enter-active > .box::after {
          border-color: #de287c transparent transparent transparent;
        }

        > .meta {
          margin-right: 20px;
        }
      }

      &.out {
        > .box {
          background: var(--msg-out);
          color: var(--text-messenger);
          border: 0;
          border-radius: 7px 0 7px 7px;
          filter: drop-shadow(3px 2px 2px rgba(0, 0, 0, .05));

          &::after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: var(--msg-out) transparent transparent transparent;
            top: 0;
            right: -10px;
          }
        }

        &.ng-enter-active > .box::after {
          border-color: #de287c transparent transparent transparent;
        }

        > .meta {
          margin-left: 20px;
        }
      }

      &.regard > .box {
        font-weight: 400;
      }

      > .meta {
        margin-top: 2px;
      }

      &.ng-enter-active > .box {
        background: #de287c;
        color: white;
      }
    }
  }

  .ons-item.link > .box {
    color: #de287c;
    text-decoration: none;
    clear: both;

    > .icon {
      height: 20px;
      width: 20px;
      vertical-align: middle;

      * {
        fill: #de287c;
      }
    }

    &:hover {
      text-decoration: underline;
      color: #a60a6e;

      > .icon * {
        fill: #a60a6e;
      }
    }
  }
}

.flying-chat-box {
  border-radius: 7px;
  background: #de287c !important;
  padding: 6px;
}

[onsw-nickname-filter] > .box {
  background: var(--background-messenger);
  padding: 0 7px 6px;
  border-bottom: 0;
}

.contact-box > .nickname {
  color: #de287c;
  font-size: 14px;
  font-weight: 600;
}

.messengerDialog.online > .icon.online,
.messengerDialog.online > .icon.offline {
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.6));
  left: 56px;
  top: 56px;
  z-index: 1;
}

.layout-container.messenger > .box > .items > .scroll-overlay {
  > .scroll-top,
  > .page-down {
    background: var(--background-messenger);
  }

  > .page-down {
    border-bottom: 1px solid #dddfe2;
  }

  > .page-down {
    border-top: 1px solid #dddfe2;
  }

  > span > .icon * {
    fill: #de2872;
  }
}

.ons-scroll.messenger.btn-scroll-top > .scroll-overlay {
  > .page-down:hover,
  > .scroll-top:hover {
    background: #f084f4;

    > .icon * {
      fill: white;
    }
  }
}

.layout-container.messenger [onsc-message-count] > .box > .value {
  background: #b0eb2c;
  color: #ba1b5b;
  width: 14px;
  height: 14px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 50px;
  padding: 4px;
}

onsw-message-notification > .box {
  background: #12c1de;
  color: var(--text-default);
}
