onsw-section.game-tab,
onsw-section.games {
  .session-info {
    display: grid;
    align-items: center;
    column-gap: var(--game-default-gap);
    padding: var(--game-default-gap);
    background-color: var(--game-session-info-bg);
    border: var(--default-border);
    border-radius: var(--default-border-radius);

    &.isWon {
       border: 1px solid var(--games-session-win-color);

      > .info {
        color: var(--games-session-win-color);
      }
    }

    &.isLost {
       border: 1px solid var(--games-session-lose-color);

      > .info {
        color: var(--games-session-lose-color);
      }
    }

    &.isDraw {
      border: 1px solid var(--games-session-draw-color);

      > .info {
        color: var(--games-session-draw-color);
      }
    }

    > .info {
      font: var(--game-headline);
      text-transform: var(--games-session-text-transform);
    }

    > .has-payback {
      font-weight: var(--text-strong);
    }

    &:hover {
      text-decoration: none;
    }

    &.hasSession:hover {
      background-color: var(--game-session-hover-bg);
    }

    &.is-selected {
      .game-over {
        display: none;
      }

      &:hover {
        background-color: var(--game-session-info-bg);
        cursor: default;

        &.isWon {
          border-color: var(--games-session-win-color);
        }

        &.isLost {
          border-color: var(--games-session-lose-color);
        }

        &.isDraw {
          border-color: var(--games-session-draw-color);
        }
      }
    }
  }
}

onsw-section.games {
  a.session-info {
    background-color: var(--games-session-info-bg);
    color: var(--games-session-info-font-color);

    &:hover,
    &.hasSession:hover {
      background-color: var(--games-session-hover-bg);
    }
  }
}
