@import '../layout/container.css';
@import '../layout/toolbar.css';
@import '../layout/item.css';

.amateurPool-sedcard-image.layout-container {
  z-index: 1;
  grid-template: 'image list' auto / 5fr 1fr;
  gap: 3px;

  &.without-list {
    grid-template: 'image' auto !important;

    > .list {
      display: none;
    }
  }

  > .list {
    grid-area: list;
    align-self: stretch;
    position: relative;
    margin: -3px;

    > .box {
      padding: 3px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      display: flex;
      flex-direction: column;
      gap: 3px;

      > .layout-item {
        flex-shrink: 0;
        aspect-ratio: 1;
        grid-template: 'thumbnail' auto;
        overflow: hidden; /* required by border-radius */

        &.active {
          outline: var(--sedcard-image-outline, var(--item-outline-active));
        }

        @media (hover: hover) {
          &:hover {
            outline: var(--sedcard-image-outline, var(--item-outline-hover));
          }
        }

        > .image,
        > .icon.play {
          grid-area: thumbnail;
        }

        &.movie > .image {
          filter: blur(1px);
          transform: scale(2);
          opacity: .5;
        }

        > .image {
          animation-name: sedcard-image-enter;
          animation-duration: 1s;

          &.layout-waiting {
            animation: none;
            opacity: 0;
          }
        }

        > .icon.play {
          pointer-events: none;
          transform: scale(0.5);
          border-radius: 50%;
          padding: 10%;
          border: 5px solid var(--item-color);
          fill: var(--item-color);
        }

        &:hover {
          @media (hover: hover) {
            > .icon.play {
              border-color: var(--item-color-hover);
              fill: var(--item-color-hover);
            }
          }
        }
      }
    }
  }

  > .core-loader {
    grid-area: image;
    align-self: center;
    /*background: rgba(0, 0, 0, .5);*/
  }

  > .ons-image {
    box-sizing: border-box;
    margin: auto;
    width: 100%;
    grid-area: image;
    background: var(--panel-background);
    border-radius: var(--default-border-radius);
    border: var(--default-border);
    overflow: hidden; /* needed for the border-radius */
    aspect-ratio: 1;
    animation-name: sedcard-image-enter;
    animation-duration: 1s;
    z-index: -1; /* define stacking context for background filter */

    &.background {
      /*display: none;*/
      filter: brightness(40%);
      /*clip-path: padding-box;*/
    }

    &.video {
      aspect-ratio: 1;
      display: flex;
      align-items: start;

      ~ [onsw-livecam-button] {
        display: none;
      }
    }
  }

  &.small {
    grid-template:
      'image' 5fr
      'list' 1fr;

    > .list > .box {
      flex-direction: unset;

      > .layout-item {
        height: 100%;
      }
    }

    [onsw-livecam-button].tile {
      display: none;
    }
  }

  [onsw-livecam-button].tile {
    grid-area: image;
    place-self: center;

    > .box > .icon {
      height: 72px;
      width: 72px;
    }
  }

  > .layout-toolbar {
    grid-area: image;
    align-items: start;
    padding-left: 15px;

    > .online {
      display: flex;
      align-items: center;
      margin-top: var(--container-gap);
      gap: 6px;
      filter: var(--sedcard-online-filter);
      color: white;
      font: var(--sedcard-online-font);
      text-transform: var(--sedcard-online-text-transform);
      text-shadow: var(--sedcard-online-text-shadow);
    }

    > .match > svg {
      /* just like onsw-profile-video > .play */
      margin: 3px;
      height: 47px;
      width: 47px;
    }
  }

  > [onsw-amateur-topic-badge] {
    grid-area: image;
    align-self: end;
  }

  > .livecam {
    grid-area: image;
    align-self: end;
    margin: var(--container-gap);
    display: flex;
    flex-direction: column;
    gap: var(--container-gap);
    align-items: center;

    > .ons-item.button.livecam {
      display: contents;

      > .box {
        max-width: 400px;
        width: 100%;
      }
    }
  }
}

@keyframes sedcard-image-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
