:root {
  --default-font: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  --headline: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  --headline-light: Arial, 'Helvetica Neue', Helvetica, sans-serif;

  --primary-color: var(--pink);
  --text-strong: 600;

  --background: #de287c;

  --menu-bg: linear-gradient(to top, var(--background) 0%, #ba1a5b 100%);
  --menu-item-bg-hover: #f084f4;
  --menu-item-bg-active: #12c1de;
  --menu-boxShadow: 0 20px 20px #940C40;
  --menu-item-label-color-active: white;
  --menu-item-label-font: 600 15px / 1 var(--headline);
  --menu-item-text-transform: none;
  --menu-item-icon-color-active: white;
  --menu-item-signup-bg: #42b72a;
  --menu-item-signup-bg-hover: #f084f4;
  --menu-item-signup-icon-color: white;
  --menu-item-signup-icon-hover: white;
  --submenu-bg: #a22255;
  --submenu-item-font: 400 14px / 1 var(--headline);
  --submenu-item-bg-hover: #f084f4;
  --submenu-item-bg-active: #12c1de;
  --submenu-item-label-color-hover: white;
  --submenu-item-label-color-active: white;

  --accordeon-btn-bg: #7f0353;
  --accordeon-btn-color: white;
  --accordeon-btn-font: 600 14px var(--headline);
  --accordeon-btn-hover-color: #b0eb2c;
  --accordeon-btn-active-color: #b0eb2c;
  --accordeon-btn-sexicons-bg: #7f0353;

  --alternative-product-background: transparent;
  --alternative-product-border: 1px solid #c663a2;
  --alternative-product-title-font: 600 14px var(--headline);
  --alternative-product-description-font: 14px var(--default-font);
  --alternative-product-logo-background: #5f053e;

  --personal-info-badge-background-color: #a60a6e;
  --personal-info-badge-border: 1px solid #c663a2;
  --personal-info-badge-icon-color: #b0eb2c;
  --personal-info-badge-label-font: 12px var(--default-font);
  --personal-info-badge-value-font: 600 12px var(--default-font);
  --personal-info-list-label-font: 600 13px var(--default-font);
  --personal-info-list-value-font: 13px var(--default-font);
  --personal-info-list-border-color: #c663a2;

  --text-messenger: #1d2129;
  --text-default: white;

  --tabs-default: #c01974;
  --tabs-hover: #f084f4;
  --tabs-active: #a60a6e;

  --tabs-messenger-default: 1px solid white;
  --tabs-messenger-hover: white;
  --tabs-messenger-active: #de287c;

  --overlay-bg: #a60a6e;

  --input-bg: white;
  --input-color: #1d2129;
  --input-border-radius: 7px;
  --input-textarea-border-radius: var(--input-border-radius);
  --input-border: 1px solid #dddfe2;
  --input-border-focus: 1px solid var(--pink);
  --input-boxshadow-focus: 0 0 5px #7f0453;

  --messenger-form-gap: 6px;
  --messenger-input-bg: white;
  --messenger-input-border: var(--input-border);
  --messenger-input-border-focus: var(--input-border-focus);
  --messenger-input-boxshadow-focus: var(--input-boxshadow-focus);

  --default-btn-font: 600 14px/1 var(--default-font);
  --default-btn-text-transform: unset;
  --default-btn-bg: var(--green);
  --default-btn-border: 1px solid transparent;
  --default-btn-hover-bg: var(--pink);
  --send-btn-icon-color: white;
  --send-btn-border-radius: 7px;
  --record-btn-margin: 0;
  --record-btn-bg: #f084f4;
  --record-btn-border: 1px solid transparent;
  --record-btn-hover-bg: #7f0353;
  --record-btn-border-radius: 7px;

  --voicemessage-msg-out-wave-progress-color: var(--voicemessage-msg-out-btn-bg-active);
  --voicemessage-msg-out-btn-color: #a60a6e;
  --voicemessage-msg-out-btn-color-hover: #7f0353;
  --voicemessage-msg-out-btn-bg-active: #f084f4;
  --voicemessage-msg-out-btn-color-active: white;
  --voicemessage-msg-out-duration-color: #999;
  --voicemessage-recorder-wave-progress-color: #a60a6e;
  --voicemessage-recorder-btn-color: var(--voicemessage-msg-out-btn-color);
  --voicemessage-recorder-btn-bg-active: #a60a6e;

  --amateur-topic-badge-fg: white;
  --amateur-topic-badge-bg: #12c1de;

  --overlay-reason-bg: #a60a6e;
  --overlay-reason-fg: white;
  --overlay-reason-fg-hover: white;
  --overlay-reason-font-weight: 600;

  --icon-offline-color: #dadada;
  --icon-online-color: #b0eb2c;

  --default-close-color: #fac2d7;
  --default-close-color-hover: #de287c;

  --background-messenger: #ededed;
  --dialog-hover: #f084f4;
  --dialog-active: #d7f4ff;
  --msg-in: #d7f4ff;
  --msg-out: white;

  --sedcard-button-bg: #7f0353;
  --sedcard-button-bg-hover: #f084f4;
  --sedcard-button-chatColor1: #b0eb2c;
  --sedcard-button-chatColor2: #de287c;

  --dialog-button-height: 32px;
  --dialog-button-padding: 3px;
  --dialog-buttons-box-padding: 0 3px;
  --dialog-button-bg: #ededed;
  --dialog-button-bg-hover: #de287c;
  --dialog-button-border: 1px solid white;
  --dialog-button-border-hover: 1px solid #de287c;
  --dialog-button-text-color: #1d2129;
  --dialog-button-text-color-hover: white;
  --dialog-button-attachment-icon: initial;
  --dialog-button-hover-attachment-icon: white;

  --danger: #cc0000;
  --warning: #ff3b30;
  --success: #42b72a;
  --friend: #cc0000;
  --green: #42b72a;
  --yellow: #DEAF3E;
  --pink: #de2872;
  --vip: #e900e0;
  --coins: #42b72a;
  --footer-links: #fac2d7;

  --contact-bg: white;
  --contact-background-active: var(--dialog-active);
  --contact-background-flash-from: white;
  --contact-background-flash-to: white;
  --contact-nickname-color: #de287c;
  --contact-nickname-color-active: #de287c;
  --contact-close-color: #fac2d7;
  --contact-close-color-hover: #de287c;
  --contact-icon-color: unset;
  --contact-timestamp-color: #999;

  --contact-new-color: white;
  --contact-new-bg: var(--green);
  --contact-new-flash-bg-from: rgba(186,26,91,0);
  --contact-new-flash-bg-to: var(--green);
  --contact-new-flash-color-from: var(--green);
  --contact-new-flash-color-to: white;
  --contact-new-font-weight: 600;
  --contact-new-padding: 1px 4px 0;

  --floating-menu-bg: #e900e0;
  --floating-menu-icon: white;

  --countdown-default-box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
  --countdown-default-bg: #12c1de;
  --countdown-default-text-shadow: 0 0 2px #7f0353;
  --countdown-default-font-weight: 700;
  --countdown-sidebar-top-position: 50px;
  --countdown-wellcum-bg: #de287c;
  --countdown-sidebar-wellcum-bottom-position: 20px;
  --countdown-sidebar-wellcum-top-position: unset;

  --doi-hint-text-color: #f0d50f;
  --doi-hint-text-font-weight: 700;
}
