.layout-menu.ons-scroll {
  --menu-icon-size: 30px;

  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  user-select: none;
  margin: 0;
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.5s ease-in;
  background: var(--menu-bg);

  > .scroll-overlay > .bar {
    width: 3px;
  }

  .layout-summary::after {
    transform: scale(1.5, 0.9);
  }

  @media (min-width: 1280px) {
    top: 80px;
  }

  &.layout-open {
    max-width: 250px;
    box-shadow: var(--menu-boxShadow);

    > .main-menu-box .submenu.layout-open {
      background: var(--submenu-bg);
      border-color: var(--submenu-item-border-color);

      > .menu-summary {
        padding-bottom: var(--submenu-item-padding-bottom);
      }

      &:not(.locale) .menu-item {
        padding-left: calc(var(--menu-item-padding) + var(--menu-icon-size) + 18px);
      }

      .menu-item {
        height: var(--submenu-item-height);
        text-transform: var(--submenu-item-text-transform);
        font: var(--submenu-item-font);
        color: var(--submenu-item-label-color);

        &.showMore:not(:hover),
        &.layout-inactive > .icon {
          opacity: 0.5;
        }
      }

      .menu-item,
      > .menu-summary {
        @media (hover: hover) {
          &:hover {
            background-color: var(--submenu-item-bg-hover);
            color: var(--submenu-item-label-color-hover);
            border-color: var(--submenu-item-border-color);

            > .icon {
              fill: var(--menu-item-open-icon-color-hover);
            }
          }
        }

        &.layout-active {
          background-color: var(--submenu-item-bg-active);
          color: var(--submenu-item-label-color-active);
          border-color: var(--submenu-item-border-color);

          > .icon {
            fill: var(--menu-item-open-icon-color-active);
          }
        }
      }

      .layout-open > .showMore {
        display: none;
      }
    }
  }

  &.layout-closed {
    @media (min-width: 768px) {
      max-width: var(--menu-hook-width);
    }

    > .main-menu-box > .submenu > .menu-summary ~ * {
      display: none;
    }

    > .main-menu-box > .menu-item,
    > .main-menu-box > .submenu {
      @media (min-width: 768px) {
        width: 60px;
      }
    }
  }

  > .main-menu-box {
    box-sizing: border-box;
    width: 250px;
    height: 100%;
    padding: var(--menu-box-padding);
    display: flex;
    flex-direction: column;
    gap: var(--menu-item-margin-bottom);

    > .spacer {
      border: none;
      margin: var(--menu-spacer);

      /*
      &.spacer {
        margin-bottom: var(--menu-spacer);
      }

      &.spacerTop {
        margin-top: var(--menu-spacer);
      }
      */
    }

    > .menu-item,
    > .submenu {
      box-sizing: border-box;
      flex-shrink: 0;
      border-width: var(--menu-item-border-width);
      border-color: var(--menu-item-border-color);
      border-radius: var(--menu-item-border-radius);
      border-style: solid;
      transition: background 0.3s ease;
      overflow: hidden;
    }

    .menu-summary,
    .menu-item {
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: 18px;
      text-decoration: none;
      padding: var(--menu-item-padding);
      color: var(--menu-item-label-color);
      font: var(--menu-item-label-font);
      text-transform: var(--menu-item-text-transform);

      > .icon {
        flex: 0 0 var(--menu-icon-size);
        fill: var(--menu-item-icon-color);
      }

      > .value {
        display: contents;
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--menu-item-bg-hover);
          border-color: var(--menu-item-border-color-hover);
          color: var(--menu-item-label-color-hover);
          text-shadow: var(--menu-item-label-text-shadow);

          > .icon {
            fill: var(--menu-item-icon-color-hover);
          }
        }
      }

      &.layout-active {
        background-color: var(--menu-item-bg-active);
        border-color: var(--menu-item-border-color-active);
        color: var(--menu-item-label-color-active);

        > .icon {
          fill: var(--menu-item-icon-color-active);
        }
      }

      &.signup {
        background-color: var(--menu-item-signup-bg);
        color: var(--menu-item-signup-label-color);

        > .icon {
          fill: var(--menu-item-signup-icon-color);
        }

        @media (hover: hover) {
          &:hover {
            background-color: var(--menu-item-signup-bg-hover);
            color: var(--menu-item-label-color-hover);

            > .icon {
              fill: var(--menu-item-signup-icon-hover);
            }
          }
        }
      }
    }
  }
}
