ons-actionslider li .onsas-title {
  font-family: var(--headline);
}
ons-actionslider li .onsas-message {
  font-weight: 400;
  font-family: var(--default-font);
}

/***********/
/* actions */
/***********/

ons-actionslider li.onsas-online, ons-actionslider li.onsas-online .onsas-inner {
  background: linear-gradient(to bottom, #1FBA13 0%, #076000 100%);
}
ons-actionslider li.onsas-livecam, ons-actionslider li.onsas-livecam .onsas-inner {
  background: linear-gradient(to bottom, #FF7113 0%, #883600 100%);
}
ons-actionslider li.onsas-buddyconfirm, ons-actionslider li.onsas-buddyconfirm .onsas-inner,
ons-actionslider li.onsas-buddyrequest, ons-actionslider li.onsas-buddyrequest .onsas-inner {
  background: linear-gradient(to bottom, #e52d27 0%, #b31217 100%);
}
ons-actionslider li.onsas-pictures, ons-actionslider li.onsas-pictures .onsas-inner ,
ons-actionslider li.onsas-movies, ons-actionslider li.onsas-movies .onsas-inner {
  background: linear-gradient(to bottom, #1291FE 0%, #004A8A 100%);
}
ons-actionslider li.onsas-old {
  opacity: 0.5;
}
ons-actionslider li.onsas-old:hover {
  opacity: 1;
}
