[onsw-payment-country] > form > label > span,
.ons-heading.payment > .box {
  color: #1a1a1a;
  font-family: var(--headline);
  font-size: 16px;
  margin: 10px 0 5px;
}

[onsw-payment-country] > form > label > select {
  background: white;
}

[onsw-payment-new-window] > .box > .buttons > .box > .ons-item.no > .box {
  background: #c00 radial-gradient(#c00, #900);
  border: 1px solid #b00;
}
