@import 'note.css';

.layout-note.info {
  color: var(--layout-note-info-color);
  background: var(--layout-note-info-background);

  > .close > .icon * {
    fill: var(--layout-note-info-color);
  }
}
