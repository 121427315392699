@import '../css/layout/container.css';
@import '../css/layout/panel.css';
@import '../css/layout/link.css';
@import '../css/layout/item.css';
@import '../css/layout/input.css';
@import '../css/layout/note.css';
@import '../css/layout/toolbar.css';
@import '../css/layout/toolbar-list.css';
@import '../css/layout/price.css';
@import '../css/achievement/package.css';

/** ## Global: Sexpartner Community (spc) */
:root {
  /** ### amateurPool */
  .amateurPool-otm-shuffle {
    --otm-name-font: 15px var(--headline);
    --otm-age-font: 13px var(--headline);
  }

  .amateurPool-feed-item {
    --feed-item-icon-color: #7ca410;
  }

  .amateurPool-sedcard-image {
    --sedcard-image-outline: 3px solid #8dbd0d;
  }

  .amateurPool-selector-by-zip {
    --zip-selector-font: 32px var(--headline);
    --zip-selector-fill-hover: #8dbd0d;
  }

  .amateurPool-sedcard-header {
    --sedcard-heading-font: 21px var(--headline);
    --sedcard-heading-from-font: 16px var(--headline);
    --sedcard-heading-from-color: #eee;
  }

  /** ### layout */
  .layout-container {
    --container-gap: 10px;
  }

  .layout-input {
    --input-font: 13px var(--default-font);
    --input-bg: #ddd;
    --input-color: #333;
    --input-border: 1px solid transparent;
    --input-border-focus: 1px solid #3d3d3d;
    --input-boxshadow-focus: 0 0 8px rgba(0, 0, 0, 0.6);
  }

  .layout-field {
    --input-label-font: 13px var(--headline);
    --input-label-color: #ccc;
  }

  .layout-item {
    --item-font: 11px var(--default-font);
    --item-heading-font: 15px var(--headline);
    --item-background: #444;
    --item-background-hover: #222;
    --item-background-active: #222;
    --item-outline-active: 1px solid #666;
    --item-highlight-color: #f90;
    --item-highlight-color-hover: white;
    --item-highlight-color-active: #f90;
    --item-color: #eee;
    --item-color-hover: #eee;
  }

  .layout-link {
    --link-color-hover: var(--primary-color);
  }

  .layout-note {
    --layout-note-info-background: #d6e9c6;
    --layout-note-info-color: #3c763d;
    --layout-note-warn-background: #fcf8e3;
    --layout-note-warn-color: #8a6d3b;
    --layout-note-error-background: #a00;
    --layout-note-error-color: white;
  }

  .layout-panel {
    --panel-padding: 10px;
    --panel-gap: 10px;
    --panel-background: #333;
    --panel-heading-font: 16px var(--headline);
    --panel-heading-font-color: #f90;
    --panel-heading-text-transform: uppercase;
    --panel-subheading-font: 15px var(--headline);

    &.panel-list {
      --empty-list-text-color: #979797;
    }
  }

  .layout-price {
    --price-color: #eee;
    --price-font: 14px var(--headline);
  }

  .layout-toolbar {
    --toolbar-icon-color: #eee;

    &.layout-tabs {
      --tabs-sticky-background: #1a1a1a;
      --menu-item-label-color-hover: var(--menu-item-label-color-active);
      --menu-item-bg-hover: var(--menu-bg);
      --menu-item-bg-active: #333;
    }

    &.nova-dots {
      --dots-color: white;
      --dots-active-color: #f90;
    }
  }

  .layout-input-toggle {
    --toggle-bg-off: #686868;
    --toggle-bg-on: #8dbd0d;
    --toggle-color-on: white;
  }

  .layout-header {
    --header-bg-color: black;
    --header-border-bottom: unset;
    --header-menu-border-color: #2b2b2b;
    --header-menu-button-bg-color: #2b2b2b;
    --header-menu-button-icon-color: white;
    --header-menu-button-hover-icon-color: #f90;

    --logo-xs-url: url('img/logo-xs.png');
    --logo-url: url('img/logo.png');
    --logo-max-width: 220px;

    --header-label-color: #b4b4b4;
    --header-label-font-size: 13px;
    --header-label-font-family: var(--headline);
    --header-label-text-shadow: none;

    &.user-header {
      --games-button-value-bg: #b4df80;
      --games-button-value-color: black;
      --coins-button-value-bg: #f90;
      --coins-button-value-color: black;
      --messenger-button-value-bg: #8dbd0d;
      --messenger-button-value-color: black;
      --cams-button-value-bg: #2dc2f1;
      --cams-button-value-color: black;
      --vip-button-value-bg: #ffd700;
      --vip-button-value-color: black;
      --friends-button-value-bg: #cc0000;
      --friends-button-value-color: white;
    }

    &.guest-header {
      --header-login-gap: 5px;
      --header-input-font: 16px/18px var(--headline);
      --header-input-focus-border: 1px solid transparent;
      --header-link-font: 13px/20px var(--headline);
      --header-button-padding: 3px 8px;

      .layout-textlink {
        --link-text-decoration: none;
        font: 13px/22px var(--headline);
      }
    }
  }

  .layout-textlink {
    --link-hover-color: #f90;
  }

  /** - toolbar-list */
  --toolbar-list-font: 15px var(--headline);
  --toolbar-list-color-hover: #f90;
  --toolbar-list-background: rgba(0, 0, 0, 0.7);
  --toolbar-list-background-without-hover: rgba(0, 0, 0, 0.4);
  --toolbar-list-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));

  /** ### userpool */
  .userpool-mailing-config {
    --mailing-config-primary-color: #8dbd0d;
    --mailing-config-primary-font: 16px var(--headline);
    --mailing-config-secondary-color: white;
    --mailing-config-secondary-font: 14px var(--headline);
    --mailing-config-warning-font: 700 14px var(--default-font);
    --mailing-config-warning-color: white;
  }

  /** ### regard */
  .layout-item.regard {
    --regard-text-size: 12px;
    --item-color-hover: black;
  }
}
