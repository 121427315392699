.layout-toolbar {
  display: flex;
  flex-direction: var(--toolbar-direction, 'row');
  margin: var(--toolbar-margin);
  padding: var(--toolbar-padding);
  gap: var(--toolbar-gap, 6px);
  font: var(--toolbar-font);
  color: var(--toolbar-color);
  background: var(--toolbar-background);
  justify-content: space-between;

  > .space {
    margin: auto;
  }

  > .btn-icon,
  > .close {
    display: contents;

    > .icon {
      cursor: pointer;
      flex: 0 0 var(--toolbar-icon-size, 18px);
      fill: var(--toolbar-icon-color);
    }

    @media (hover: hover) {
      &:hover > .icon {
        transform: var(--toolbar-icon-transform-hover, scale(1.2));
        fill: var(--toolbar-icon-color-hover, var(--toolbar-icon-color));
      }

      &.layout-active {
        fill: var(--toolbar-icon-active-color, var(--toolbar-icon-color));
      }
    }
  }
}
