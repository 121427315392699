[onsw-teaser].christmas2022B.active.main:not(.small) > .box,
[onsw-teaser].christmas2022C.active.main:not(.small) > .box,
[onsw-teaser].christmas2022D.active.main:not(.small) > .box,
[onsw-teaser].christmas.small > .box {
  display: none;
}

[onsw-teaser].christmas.active.main.christmas2021C > [onsw-video-banner],
[onsw-teaser].christmas.active.main.christmas2021B > [onsw-video-banner] {
  display: none;
}

[onsw-teaser].christmas > .box {
  padding-top: 40%;
  background-image: url("/specials/christmas2021/xmas_overlay_de.jpg");
  background-size: cover;
  background-position: center;
}

[onsw-teaser].christmas.christmas2021B > .box {
  background-image: url("/specials/christmas2021B/xmas_overlay_de.jpg");
}

[onsw-teaser].christmas.christmas2021C > .box {
  background-image: url("/specials/christmas2021C/xmas_overlay_de.jpg");
}

:lang(en) [onsw-teaser].christmas > .box {
  background-image: url("/specials/christmas2021/xmas_overlay_en.jpg");
}

:lang(en) [onsw-teaser].christmas.christmas2021B > .box {
  background-image: url("/specials/christmas2021B/xmas_overlay_en.jpg");
}

:lang(en) [onsw-teaser].christmas.christmas2021C > .box {
  background-image: url("/specials/christmas2021C/xmas_overlay_en.jpg");
}

@media (min-width: 1280px) {
  [onsw-teaser].christmas > .box {
    padding-top: 30%;
    background-position: center 80%;
  }
  [onsw-teaser].christmas.christmas2021C > .box,
  [onsw-teaser].christmas.christmas2021B > .box {
    background-position: center;
  }
}

[onsw-teaser].christmas.small > .box {
  padding-top: 60px;
  background-position: top;
}

[onsw-teaser].christmas [onsw-countdown] > .box {
  background: #be2720;
  color: white;
}
[onsw-teaser].christmas [onsw-countdown] > .box > .value {
  color: white;
}

@media (max-width: 1023px) {
  [onsw-teaser].christmas [onsw-countdown] > .box {
    padding: 5px 10px;
  }

  [onsw-teaser].christmas [onsw-countdown] > .box > .label {
    display: none;
  }

  [onsw-teaser].christmas [onsw-countdown] > .box > .value {
    line-height: 45px;
    font-size: 40px;
  }
}

[onsw-teaser].christmas.otm > .box {
  background-image: url("/specials/christmas2021/xmas_Otm_de.jpg");
  padding-top: unset;
  height: 100%;
}
[onsw-teaser].christmas.christmas2021B.otm > .box {
  background-image: url("/specials/christmas2021B/xmas_Otm_de.jpg");
}
[onsw-teaser].christmas.christmas2021C.otm > .box {
  background-image: url("/specials/christmas2021C/xmas_Otm_de.jpg");
}
.myportal-widget [onsw-teaser].christmas.otm > .box {
  background-position: center 90%;
}
[onsw-teaser].christmas.otm [onsw-countdown] > .box {
  bottom: 122px;
}

:lang(en) [onsw-teaser].christmas.otm > .box {
  background-image: url("/specials/christmas2021/xmas_Otm_en.jpg");
}

:lang(en) [onsw-teaser].christmas.christmas2021B.otm > .box {
  background-image: url("/specials/christmas2021B/xmas_Otm_en.jpg");
}

:lang(en) [onsw-teaser].christmas.christmas2021C.otm > .box {
  background-image: url("/specials/christmas2021C/xmas_Otm_en.jpg");
}

[onsw-teaser].christmas.otm > .box > .close {
  display: none;
}

