onsw-messenger-message-box.offline > .box *,
onsw-messenger-message-box.busy > .box * {
  display: none;
}

onsw-messenger-message-box.offline > .box,
onsw-messenger-message-box.busy > .box {
  background-image: url('../../basic/img/loading2.svg');
  background-position: center;
  background-repeat: no-repeat;
}

onsw-messenger-message-box > .box > .start {
  text-align: center;
  font-style: italic;
  color: #c0bfbf;
  padding: 10px 0 0;
  font-size: smaller;
}

onsw-messenger-message-box > .box > .msg {
  padding: 10px 20px;
}

onsw-messenger-message-box > .box > .msg:last-child {
  padding-bottom: 20px;
}

onsw-messenger-message-box.ready > .box > .msg.ng-enter {
  animation: message-enter 0.5s 2 alternate ease;
}

@keyframes message-enter {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.4);
  }
}

onsw-messenger-message-box .msg.in {
  transform-origin: left;
  text-align: left;
}

onsw-messenger-message-box .msg.out {
  transform-origin: right;
  text-align: right;
}

onsw-messenger-message-box .msg.out > .box {
  text-align: left;
}

onsw-messenger-message-box > .box > .msg > .box {
  position: relative;
  display: inline-block;
  min-width: 30%;
  max-width: 75%;
  padding: 0.75em;
}

onsw-messenger-message-box > .box > .msg.game-invitation > .box,
onsw-messenger-message-box > .box > .msg.match > .box,
onsw-messenger-message-box > .box > .msg.sexicon > .box,
onsw-messenger-message-box > .box > .msg.kiss > .box,
onsw-messenger-message-box > .box > .msg.voice > .box {
  min-width: 75%;
}

onsw-messenger-message-box > .box > .msg > .box > .text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

onsw-messenger-message-box > .box > .msg > .box::after {
  border-style: solid;
  content: ' ';
  position: absolute;
  top: 0.5em;
  width: 0;
  height: 0;
}
onsw-messenger-message-box > .box > .msg.in > .box::after {
  left: -0.9em;
  border: 1em solid transparent;
  border-left: 0;
}
onsw-messenger-message-box > .box > .msg.out > .box::after {
  right: -0.9em;
  border: 1em solid transparent;
  border-right: 0;
}

onsw-messenger-message-box .msg > .box > .icon,
onsw-messenger-message-box .msg > .box > img {
  width: 50px;
  float: left;
  margin-right: 10px;
}

onsw-messenger-message-box .msg > .box > .icon {
  height: 50px;
}

onsw-messenger-message-box .ons-item.link > .box {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

onsw-messenger-message-box .ons-item.link > .box > .icon {
  height: 16px;
  width: 16px;
  vertical-align: text-bottom;
}

onsw-messenger-message-box {
  onsw-game-invite {
    --link-margin: 10px 0 0 0;
    width: 100%;
    box-sizing: border-box;

    .box {
      background: none;
      flex-direction: column;
      gap: 2px;
      width: 100%;

      img {
        display: none;
      }
    }

    .box:hover {
      background: none;
    }
  }
}
