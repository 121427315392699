.ons-crop {
  max-height: 39px;
  overflow: hidden;
}


.ons-crop.cropped {
  position: relative;
  border-bottom: 5px solid transparent;
}

.ons-crop.cropped > .box::before {
  content: '';
  display: block;
  position: absolute;
  background: linear-gradient(to right, rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 100%) 100%);
  left: 0;
  right: 0;
  bottom: 0;
  height: 16px;
}
