@import 'container.css';

/** ### Overlay */
.layout-container.layout-overlay {
  pointer-events: none;
  background: var(--layout-overlay-backdrop, none);
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  place-content: stretch;

  > * {
    pointer-events: all;
    box-shadow: var(--layout-overlay-box-shadow, 0 0 20px #0000004d);
    grid-area: 1 / 1; /* TODO consider something else */
    margin: auto; /* TODO consider something else */
  }
}
