.alternative-products {
  display: grid;
  gap: 10px;
  grid-template-columns: 50% 50%;
  overflow: hidden;

  @media (max-width: 1180px) {
    grid-template-columns: 100%;

    .bg-img {
      display: none;
    }
  }

  > .responsive-column {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .layout-panel {
      > h1 {
        white-space: break-spaces;
      }

      &.goodbye > h1 {
        font: var(--panel-heading-font-large);
      }

      p.title {
        font: var(--alternative-product-title-font);
      }
    }
  }

  .bg-img {
    background-image: url("../../basic/img/alternativeProducts/bg.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
  }
}


