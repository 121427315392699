[onsw-item].aotm-widget {
  text-shadow: 1px 1px 3px #000000;
  font-size: 30px;
}

.ons-item.aotm-widget > .box > .value {
  font-size: 21px;
}

.ons-text.aotm-widget {
  text-shadow: 1px 1px 3px #000000;
  font-size: 18px;
  line-height: 22px;
}

