[onsw-teaser].micaela2022.main.small:not(.active) > .box {
  display: none;
}

[onsw-teaser].micaela2022.main > [onsw-video-banner],
[onsw-teaser].micaela2022.main > [onsw-video-banner] {
  display: none;
}

[onsw-teaser].micaela2022.preview [onsw-livecam-button] {
  display: none !important;
}
@media (max-width: 1023px) {
  .main-widget [onsw-livecam-button].otm-widget > .box {
    position: absolute;
    padding: 5px 0px;
    width: 180px;
    bottom: 8%;
    left: 56%;
    box-shadow: 0px 6px 3px -3px #0000006b;
}

  [onsw-teaser].micaela2022 > .box {
  padding-top: 40%;
  background-image: url("/specials/micaela2022/banner_m_de.jpg");
  background-size: cover;
  background-position: center;
}

[onsw-teaser].micaela2022.active > .box {
  background-image: url("/specials/micaela2022/banner_m_de_active.jpg");
}

[onsw-teaser].micaela2022.small > .box {
  background-image: url("/specials/micaela2022/banner_mini_de.jpg");
}

:lang(en) [onsw-teaser].micaela2022.small > .box {
  background-image: url("/specials/micaela2022/banner_mini_en.jpg");
}

:lang(en) [onsw-teaser].micaela2022 > .box {
  background-image: url("/specials/micaela2022/banner_m_en.jpg");
}

:lang(en) [onsw-teaser].micaela2022.active > .box {
  background-image: url("/specials/micaela2022/banner_m_en_active.jpg");
}
}
@media (min-width: 1024px) {
  [onsw-teaser].micaela2022.main > .box {
    display: none;
  }
}
@media (min-width: 1280px) {
  [onsw-teaser].micaela2022 > .box {
    padding-top: 30%;
    background-position: center 80%;
  }
  [onsw-teaser].micaela2022 > .box,
  [onsw-teaser].micaela2022 > .box {
    background-position: center;
  }
}

[onsw-teaser].micaela2022.small > .box {
  padding-top: 60px;
  background-position: top;
}

[onsw-teaser].micaela2022 [onsw-countdown] {
  display: none!important;
}

[onsw-teaser].micaela2022.otm > .box {
  background-image: url("/specials/micaela2022/otm_de.jpg");
  padding-top: unset;
  height: 100%;
}
[onsw-teaser].micaela2022.otm.active > .box {
  background-image: url("/specials/micaela2022/otm_de_active.jpg");
}

:lang(en) [onsw-teaser].micaela2022.otm > .box {
  background-image: url("/specials/micaela2022/otm_en.jpg");
}

:lang(en) [onsw-teaser].micaela2022.otm.active > .box {
  background-image: url("/specials/micaela2022/otm_en_active.jpg");
}

[onsw-teaser].micaela2022.otm > .box > .close {
  display: none;
}

