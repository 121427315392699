/* # CSS Variables: "SPC" */
:root {
  /* ## Global Variables */
  --default-font: 'Open Sans', sans-serif;
  --headline: 'Open Sans Condensed', sans-serif;
  --headline-light: 'Open Sans Condensed Light', sans-serif;

  --primary-color: #f90;
  --text-strong: 700;

  --panel-heading-font-large: 21px var(--headline);

  --menu-bg: black;
  --menu-item-bg-hover: #f90;
  --menu-item-bg-active: #2b2b2b;
  --menu-hook-width: 60px;
  --menu-boxShadow: 5px 5px 20px black;
  --menu-box-padding: 0 0 80px;
  --menu-spacer: 15px;
  --menu-item-padding: 15px;
  --menu-item-border-width: 0;
  --menu-item-label-color: white;
  --menu-item-label-color-active: #f90;
  --menu-item-label-font: 16px / 1 var(--headline);
  --menu-item-text-transform: uppercase;
  --menu-item-icon-color: white;
  --menu-item-icon-color-hover: white;
  --menu-item-icon-color-active: #f90;
  --menu-item-open-icon-color-hover: var(--menu-item-icon-color-hover);
  --menu-item-open-icon-color-active: var(--menu-item-icon-color-active);
  --menu-item-signup-bg: #8dbd0d;
  --menu-item-signup-bg-hover: #f90;
  --menu-item-signup-icon-color: white;
  --menu-item-signup-icon-hover: white;
  --has-submenu-bg: var(--submenu-bg);
  --submenu-bg: #2b2b2b;
  --submenu-item-bg-hover: #f90;
  --submenu-item-bg-active: #2b2b2b;
  --submenu-item-padding-bottom: var(--menu-item-padding);
  --submenu-item-height: 36px;
  --submenu-item-font: 14px / 1 var(--headline);
  --submenu-item-text-transform: none;
  --submenu-item-label-color-hover: white;
  --submenu-item-label-color-active: #f90;

  --amateur-small-list-font-size: 13px;

  --accordeon-btn-bg: #444;
  --accordeon-btn-color: #b4b4b4;
  --accordeon-btn-padding: 5px 8px;
  --accordeon-btn-font: 15px var(--headline);
  --accordeon-btn-hover-color: #f90;
  --accordeon-btn-active-color: #f90;
  --accordeon-btn-sexicons-bg: #555;
  --sexicon-img-width: calc(256px / 5);

  --alternative-product-background: #444;
  --alternative-product-title-font: var(--panel-heading-font);
  --alternative-product-description-font: 16px var(--headline-light);
  --alternative-product-logo-background: #333;

  .layout-panel {
    --personal-info-badge-icon-color: #fff;
    --personal-info-badge-background-color: #444;
    --personal-info-badge-label-font: 14px var(--headline);
    --personal-info-badge-label-color: var(--panel-heading-font-color);
    --personal-info-badge-value-font: 12px var(--default-font);
    --personal-info-badge-border: var(--default-border);
    --personal-info-badge-border-radius: var(--default-border-radius);
    --personal-info-list-label-font: 14px var(--headline);
    --personal-info-list-label-color: var(--panel-heading-font-color);
    --personal-info-list-value-font: 12px var(--default-font);
    --personal-info-list-border-color: #444;
  }

  --input-font: 13px var(--default-font);
  --input-bg: #ddd;
  --input-color: #333;
  --input-border-radius: 0;
  --input-textarea-border-radius: var(--default-border-radius);
  --input-border: 1px solid transparent;
  --input-bg-focus: white;
  --input-color-focus: var(--input-color);
  --input-border-focus: 1px solid transparent;
  --input-boxshadow-focus: unset;
  --input-outline-offset: 0;
  --input-outline: none;

  --messenger-border-color: #d1d1d1;

  --messenger-form-gap: 0;
  --messenger-input-bg: white;
  --messenger-input-border: 1px solid #d1d1d1;
  --messenger-input-color-focus: var(--input-color);
  --messenger-input-border-focus: 1px solid #3d3d3d;
  --messenger-input-boxshadow-focus: 0 0 8px rgba(0, 0, 0, 0.6);

  --button-border-radius: 0;
  --default-btn-height: 30px;
  --default-btn-font: 16px/22px var(--headline);
  --default-btn-text-color: white;
  --default-btn-text-shadow: unset;
  --default-btn-text-transform: uppercase;
  --default-btn-bg: linear-gradient(to bottom,#738e15 0%,#8dbd0d 100%);
  --default-btn-border: 1px solid #8dbd0d;
  --default-btn-hover-bg: #738e15;

  --send-btn-bg: var(--default-btn-bg);
  --send-btn-border: var(--default-btn-border);
  --send-btn-hover-bg: var(--default-btn-hover-bg);
  --send-btn-icon-color: #e6e6e6;

  --amateur-topic-badge-fg: white;
  --amateur-topic-badge-bg: #8dbd0d;

  --overlay-reason-bg: #f90;
  --overlay-reason-fg: white;
  --overlay-reason-fg-hover: black;

  --sedcard-online-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));
  --sedcard-online-font: 700 13px var(--default-font);
  --sedcard-online-text-transform: lowercase;

  --sedcard-button-height: 52px;
  --sedcard-button-padding: 0 8px;
  --sedcard-button-bg: #202020;
  --sedcard-button-bg-hover: #f90;
  --sedcard-button-border: none;
  --sedcard-button-border-hover: none;
  --sedcard-button-text-size: initial;
  --sedcard-button-text-color: initial;
  --sedcard-button-text-color-hover: unset;
  --sedcard-button-attachment-icon: white;
  --sedcard-button-hover-attachment-icon: white;
  --sedcard-button-chatColor1: #c6de86;
  --sedcard-button-chatColor2: #ffe0b2;

  --dialog-buttons-box-gap: 2px;
  --dialog-buttons-box-padding: 0;
  --dialog-button-height: 40px;
  --dialog-button-bg: #e6e6e6;
  --dialog-button-bg-hover: #e6e6e6;
  --dialog-button-border: none;
  --dialog-button-border-hover: none;
  --dialog-button-padding: 0 3px;
  --dialog-button-text-size: 12px;
  --dialog-button-text-color: #666;
  --dialog-button-text-color-hover: #f90;
  --dialog-button-attachment-icon: initial;
  --dialog-button-hover-attachment-icon: unset;

  --icon-offline-color: #c3c3c3;
  --icon-online-color: #8dbd0d;

  --default-close-color: #d1d1d1;
  --default-close-color-hover: #f90;

  --contact-bg: transparent;
  --contact-background-hover: #ffe0b2;
  --contact-background-active: #e6e6e6;
  --contact-background-flash-from: #fff;
  --contact-background-flash-to: #8dbd0d;
  --contact-nickname-color: #f90;
  --contact-nickname-color-active: #f90;
  --contact-nickname-font-size: 15px;
  --contact-nickname-font-weight: unset;
  --contact-close-color: #d1d1d1;
  --contact-close-color-hover: black;
  --contact-icon-color: unset;
  --contact-timestamp-color: #999;

  --contact-new-color: #8dbd0d;
  --contact-new-bg: transparent;
  --contact-new-bg-border-radius: 0;
  --contact-new-flash-bg-from: rgba(141,189,13,0);
  --contact-new-flash-bg-to: rgba(141,189,13,1);
  --contact-new-flash-color-from: rgb(141,189,13);
  --contact-new-flash-color-to: rgb(255,255,255);
  --contact-new-font-size: 14px;
  --contact-new-padding: 0 6px;
  --contact-new-font-family: var(--headline);
  --contact-new-font-weight: unset;
  --contact-new-text-transform: uppercase;

  --livecam-icon-normal: #8dbd0d;
  --livecam-icon-voyeur: #2dc2f1;
  --livecam-icon-exclusive: #a80077;
  --livecam-icon-sedcard-image-normal: #f90;
  --livecam-icon-sedcard-image-voyeur: var(--livecam-icon-voyeur);
  --livecam-icon-sedcard-image-exclusive: var(--livecam-icon-exclusive);

  --livecam-button-normal-bg: linear-gradient(to bottom, #ffad3a 0%, #f56e0c 55%);
  --livecam-button-normal-color: white;
  --livecam-button-normal-label-text-shadow: unset;
  --livecam-button-normal-border: 1px solid transparent;
  --livecam-button-normal-box-shadow: unset;
  --livecam-button-normal-hover-bg: #f56e0c;
  --livecam-button-normal-hover-color: white;
  --livecam-button-normal-hover-label-text-shadow: unset;
  --livecam-button-normal-hover-border: 1px solid #f56e0c;

  --livecam-button-exclusive-bg: linear-gradient(to bottom, #cb60b3 0%, #a80077 55%);
  --livecam-button-exclusive-color: white;
  --livecam-button-exclusive-label-text-shadow: unset;
  --livecam-button-exclusive-border: 1px solid transparent;
  --livecam-button-exclusive-box-shadow: unset;
  --livecam-button-exclusive-hover-bg: #b21c89;
  --livecam-button-exclusive-hover-color: white;
  --livecam-button-exclusive-hover-label-text-shadow: unset;
  --livecam-button-exclusive-hover-border: 1px solid #b21c89;

  --livecam-button-voyeur-bg: linear-gradient(to bottom, #2dc2f1 0%, #0fa8d8 55%);
  --livecam-button-voyeur-color: white;
  --livecam-button-voyeur-label-text-shadow: unset;
  --livecam-button-voyeur-border: 1px solid transparent;
  --livecam-button-voyeur-box-shadow: unset;
  --livecam-button-voyeur-hover-bg: #0fa8d8;
  --livecam-button-voyeur-hover-color: white;
  --livecam-button-voyeur-hover-label-text-shadow: unset;
  --livecam-button-voyeur-hover-border: 1px solid #0fa8d8;

  --livecam-button-label-font-size: 16px;
  --livecam-button-additional-font-size: 12px;
  --livecam-button-box-shadow: unset;

  --livecam-show-close-bg: rgba(0,0,0,0.6);
  --livecam-show-close-bg-hover: black;
  --livecam-show-close-icon: white;
  --livecam-show-close-icon-hover: #f90;

  --livecam-show-button-bar-bg: rgba(0,0,0,.4);
  --livecam-show-input-border: none;

  --livecam-show-message-bg: rgba(0,0,0,.4);
  --livecam-show-message-border-radius: 0;

  --livecam-show-button-bg: #333;
  --livecam-show-button-color: white;
  --livecam-show-button-border: 1px solid #333;
  --livecam-show-button-border-radius: var(--button-border-radius);
  --livecam-show-button-hover-bg: #444;
  --livecam-show-button-hover-color: white;
  --livecam-show-button-hover-border: 1px solid #444;

  --livecam-show-send-button-bg: linear-gradient(to bottom, #738e15 0%, #8dbd0d 100%);
  --livecam-show-send-button-color: white;
  --livecam-show-send-button-border: 1px solid #8dbd0d;
  --livecam-show-send-button-box-shadow: unset;
  --livecam-show-send-button-hover-bg: #738e15;
  --livecam-show-send-button-hover-color: white;
  --livecam-show-send-button-hover-border: 1px solid #8dbd0d;

  --livecam-show-vibration-button-font-size: 20px;
  --livecam-show-queue-vibration-button-font-size: 16px;

  --livecam-show-smiley-select-bg: #333;
  --livecam-show-smiley-select-border-radius: 0;

  --livecam-show-smiley-button-hover-color: #ffd700;

  --livecam-show-rebuy-seconds: #fcb327;

  --livecam-motto: 18px/1 'Open Sans Condensed', sans-serif;

  --hotornot-teaser-logo-size: 50%;
  --hotornot-teaser-logo: url(../img/hotornot/logo.png);
  --hotornot-teaser-logo-en: url(../img/hotornot/logo.png);
  --hotornot-teaser-bg-url: url(../img/hotornot/bgTeaser.jpg);
  --hotornot-teaser-new-url: url(../img/hotornot/findout.png);
  --hotornot-teaser-new-url-en: url(../img/hotornot/findout_en.png);
  --hotornot-teaser-imgBox-border: 1px solid white;
  --hotornot-teaser-imgBox-boxshadow: 2px 2px 5px #000000e0;
  --hotornot-teaser-button-bg: #a80a0f;
  --hotornot-teaser-button-border: 1px solid #ff0000;
  --hotornot-teaser-button-boxshadow: var(--hotornot-teaser-imgBox-boxshadow);
  --hotornot-teaser-button-hover-bg: #ff0000;

  --portal-heading-box-bg: linear-gradient(to right, #900, #1a1a1a 45%);
  --portal-heading-box-padding: 7px;
  --portal-heading-box-color: white;
  --portal-scroll-bg: black;
  --portal-scroll-icon-color: #900;
  --portal-scroll-icon-hover-color: #8dbd0d;

  --portal-small-box-bg: linear-gradient(135deg, #000000 83%, #5b0101 100%);
  --portal-small-box-title-color: #f90;
  --portal-small-box-title-font-size: 16px;

  --floating-menu-bg: #8dbd0d;
  --floating-menu-icon: white;

  --countdown-default-box-shadow: unset;
  --countdown-default-border: unset;
  --countdown-default-bg: #333;
  --countdown-default-color: white;
  --countdown-default-font-weight: unset;
  --countdown-default-font-size: 20px;
  --countdown-default-1024-font-size: 32px;
  --countdown-default-text-shadow: unset;

  --countdown-sidebar-top-position: 310px;
  --countdown-sidebar-bottom-position: unset;

  --countdown-wellcum-box-shadow: unset;
  --countdown-wellcum-border: unset;
  --countdown-wellcum-bg: #333;
  --countdown-wellcum-color: white;
  --countdown-wellcum-text-shadow: unset;

  --countdown-sidebar-wellcum-top-position: 270px;
  --countdown-sidebar-wellcum-bottom-position: unset;
  --countdown-sidebar-font-size: 32px;

  --doi-hint-text-color: #f90;
  --checkmark-url: url("../../basic/img/checkmark.png");

  --gameOverSplash: #f00;
  --gameOverShadow: .5;
  --gameOverGradientColor1: #EF7D00;
  --gameOverGradientColor2: #F08500;
  --gameOverGradientColor3: #F5AA00;
  --gameOverGradientColor4: #F9C700;
  --gameOverGradientColor5: #FCDC00;
  --gameOverGradientColor6: #FEE800;
  --gameOverGradientColor7: #FFED00;

  --game-headline: 16px 'Open Sans Condensed', sans-serif;
  --game-default-gap: 10px;
  --games-timer-background: #1a1a1a;
  --games-timer-bar-background: linear-gradient(to right, #c00 0%, #89b50f 80px);

  --game-session-info-bg: #1a1a1a;
  --game-session-hover-bg: #444;

  --games-session-info-bg: #444;
  --games-session-hover-bg: #1a1a1a;
  --games-session-text-transform: uppercase;
  --games-session-win-color: #89b50f;
  --games-session-lose-color: #f00;
  --games-session-draw-color: #f90;

  --game-icon-default: #888;
  --game-icon-userTurn: #f00;
  --game-icon-amateurTurn: #f90;
  --game-icon-isPending: #b4df80;

  --gameColorBg: #b4df80;
  --gameColorShadow: rgba(0,0,0,.1);
  --gameColorPlus: #c00;
  --gameColorElements: #73b0f4;

  --game-wrapper: #333;

  --game-inviteBox-bg: var(--sedcard-button-bg);
  --game-inviteBox-border-color: #f90;
  --game-inviteBox-bg-hover-text-color: white;

  --game-suggestions-bg: #333;
  --game-suggestions-text-color: white;
  --game-suggestions-name-color: #f90;
  --game-suggestions-hover-color: #89b50f;

  --games-info-background: #444;
  --games-info-icon: white;
  --games-info-hover-background: #f90;
  --game-inviteBox-sedcard-bg-hover-label-color: #89b50f;

  --rules-color: #89b50f;

  --freemovie-logo: url('../img/fmotd/logo.png');

  /* ### achievement */
  --achievements-image-aspect-ratio: calc(36 / 11);
  --achievement-heading-font: 15px var(--headline);

  --unsubscribe-image-url: url('../../basic/img/login.jpg');
}

onsw-comment-list {
  --comment-gap: 5px;
  --comment-padding: 10px;
  --comment-background-even: #444;
}

onsw-game {
  connect-four-game {
    width: 100%;
    --game-wrapper: #333;
    --game-background: #202020;
    --game-border: 1px solid #404040;
    --value1-background:radial-gradient(ellipse at center,#f00000 52%, #bc0214 55%, #f00000 60%);
    --value2-background:radial-gradient(ellipse at center,#ff9000 52%, #c7770f 55%, #ff9000 60%);
    --value0-box-shadow: inset 0 0 6px 0 black, 0 1px 2px #404040;
    --value1-box-shadow: inset 0 0 2px #404040;
    --value2-box-shadow: inset 0 0 2px #404040;
    --column-hover-background: black;
  }
}
