@import '../layout/note-warn.css';
@import '../layout/toolbar.css';

.core-outdated.layout-note.warn {
  > .bar {
    margin: 20px 0;
    height: 5px;
    background-color: #887658;
    transition: 1s width linear;
  }

  > .layout-toolbar {
    margin: 10px;
  }
}
