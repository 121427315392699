@import 'container.css';
@import 'overlay.css';

.layout-container.layout-overlay.layout-global-popup {
  z-index: 10;
  transition: background-color 0.3s ease-in;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.popup-modal {
    background-color: rgba(0, 0, 0, 0.7);
    pointer-events: all;
  }

  > * {
    margin: 20px auto 0;
    animation: global-popup-enter 0.5s ease-in;
    transform-origin: top center;
  }

  > .core-loader {
    margin: unset;
    overflow: hidden;
  }

  > .layout-note,
  > .layout-panel {
    width: 300px;
  }

  > .layout-note {
    @media (min-width: 1024px) {
      margin-right: 20px;
      transform-origin: top right;
    }
  }

  > .panel-confirm {
    order: -1;
  }
}

@keyframes global-popup-enter {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}
