.layout-footer {
  display: block;
  margin: 20px 0;
  clear: both;

  p {
    margin: 10px 0;
  }

  nav > :not(:first-child)::before {
    content: ' | ';
  }
}

.card-wraper {
  display: flex;
  justify-content: center;
}

.card-detail {
  width: 70px;
  height: 35px;
  background: white;
  margin-right: 10px;
  border: 3px solid white;
}

.card-detail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
