@import 'toolbar.css';

.layout-toolbar.toolbar-list {
  --toolbar-gap: 3px;
  align-items: center;
  white-space: nowrap;
  padding: 0 5px;
  height: 36px;
  background: var(--toolbar-list-background);
  font: var(--toolbar-list-font);
  color: var(--toolbar-list-color); /* dst&portal&cams: black */

  > * {
    filter: var(--toolbar-list-filter); /* dst&portal&cams: none */
  }

  > .ons-item > .box > .icon {
    vertical-align: middle;
    width: 24px;
    height: 24px;
  }

  @media (min-width: 1024px) {
    height: 32px;
    background: var(--toolbar-list-background);

    .ons-item > .box > .icon {
      width: 28px;
      height: 28px;
    }

    @media (hover: hover) {
      transition: background 0.5s ease 0.5s;
      background: var(--toolbar-list-background-without-hover);

      :focus-within > &,
      :hover > & {
        color: var(--toolbar-list-color-hover);
        transition-delay: 0s;
        background: var(--toolbar-list-background);
      }

      > .ons-item > .box:hover > .icon {
        transform: scale(1.2);
      }
    }
  }

  > .ons-item:not(.title) > .box {
    > .additional,
    > .default-text,
    > .toy-active,
    > .label {
      display: none;
    }
  }

  > .title {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* DEPRECATED */
  > .ons-text > .box {
    display: inline;
  }

  > onsw-open-dialog-button.ons-item {
    &.inactive {
      opacity: 0.4;
    }
  }

  > [onsw-livecam-button].ons-item.inactive {
    display: none;
  }

  > .icon {
    flex-shrink: 0;
    margin-right: 3px;
  }

  .icon {
    &.chat * {
      fill: #fff;
    }

    &.cam * {
      fill: var(--livecam-icon-normal);
    }

    &.lovense * {
      fill: #ff379a;
    }
  }
}
