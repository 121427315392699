.spc-compliance > div {
    background: #333;
    color: #ccc;
    padding: 15px;
}


.spc-compliance > div > h1 {
    text-transform: unset;
    color: unset;
    background: unset;
    text-align: unset;
    padding: unset;
    font-size: 15.21px;
    font-weight: bold;
    margin: 15.21px 0;
    font-family: unset;
}
.spc-compliance a:active,
.spc-compliance a:visited,
.spc-compliance a {
    color: #f90;
}