onsw-section.game-tab,
onsw-section.games {
  onsw-timer {
    margin: 15px 0 0;
    display: block;
    border-radius: 9px;
    background: var(--games-timer-background);
    height: 18px;
    overflow: hidden;

    &.inactive {
      display: none;
    }

    > div {
      display: flex;
      align-items: center;
      width: calc(100% * var(--ratio));
      height: 18px;
      background: var(--games-timer-bar-background);
      border-radius: 9px 3px 3px 9px;
      padding: 0 6px;
      font-weight: 600;
      color: var(--games-timer-color);
    }
  }
}
