h1.small-feed-widget > .box > .text,
p.small-feed-widget:first-child > .box > .text {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2;
}

p.small-feed-widget:last-child {
  line-height: calc(70px / 4);
  height: 70px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

p.small-feed-widget:last-child > .box > .icon {
  display: none;
}
