.ons-item.button > .box {
  text-decoration: none;
  padding: 0 15px;
  background: var(--default-btn-bg);
  font: var(--default-btn-font);
  height: var(--default-btn-height);
  border: var(--default-btn-border);
  border-radius: var(--button-border-radius);

  @media (hover: hover) {
    &:hover {
      background: var(--default-btn-hover-bg);
    }
  }
}

.ons-item.button > .box .label {
  font: var(--default-btn-font);
  text-transform: var(--default-btn-text-transform);
  color: var(--default-btn-text-color);
}

.ons-item.button > .box > .icon:first-child {
  margin-left: -4px;
}

.ons-item.button > .box .icon * {
  fill: #fff;
}

.ons-item.button.cancel > .box {
  background: #979797;
  border: 1px solid #888;
}

.ons-item.button.cancel > .box:hover {
  background: #909090;
}

.inline > .box > .buttons > .box > .ons-item > .box {
  padding: 2px 8px;
  height: 30px;
}

