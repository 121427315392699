h5.terms {
  font-size: 14px;
}
.terms.content > .box > div > h1 {
  margin-top: 0;
}
.terms.content a {
  text-decoration: underline;
}
.terms.content ol ul {
  padding-left: 15px;
}
.terms.content li {
  margin: 5px 0 15px;
}

.privacyPolicy h3 {
  font-size: 14px;
  font-weight: 600;
  margin: 15px 0 5px;
}

.imprint.content .support {
  margin: 30px 0;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
}

.imprint.content p,
.terms.content p,
.privacyPolicy.content p {
  margin: 0 0 10px;
}

.imprint.content .support::after {
  display: block;
  content: '';
  clear: both;
}

.imprint.content .support .link > .box {
  font-weight: 600;
}

.imprint.content .support > [ons-icon] > svg {
  width: 76px;
  height: 76px;
  margin: 0 auto;
  display: block;
}

@media (min-width: 520px) {
  .imprint.content .support {
    text-align: left;
  }

  .imprint.content .support > [ons-icon] > svg {
    padding-right: 10px;
    float: left;
  }

  .imprint.content .support strong {
    display: inline-block;
    padding-top: 26px;
  }
}

