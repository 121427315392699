onsw-template {
  display: block; /* TODO really ? besser: contents oder inline */
}

onsw-template p {
  margin: 10px 0; /* no */
}

/* shit */
onsw-template p.help-block,
.ons-form > form > p.small {
  margin: 0;
  font-size: 11px;
  line-height: 1;
  color: #999;
}

.bold { /* ?? */
  font-weight: var(--text-strong);
}
