onsw-game-invite {
  &.disabled {
    display: none;
  }

  &.offline > .box {
    pointer-events: none;

    &::before {
      display: none;
    }
  }

  > .box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--game-default-gap);
    padding: 5px 10px;
    white-space: nowrap;
    background: var(--game-inviteBox-bg);
    border-radius: var(--button-border-radius);
    cursor: pointer;

    &::before {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     border: 2px solid var(--game-inviteBox-border-color);
     border-radius: var(--button-border-radius);
     transition: all .5s;
     animation: borderRotate 3s infinite linear;
   }

    &.wait {
      cursor: wait;
    }

    > img {
      max-height: 60px;
      height: 100%;
    }

    > .label {
      font: var(--game-headline);
    }

    &:hover {
      background: var(--sedcard-button-bg-hover);
      color: var(--game-inviteBox-bg-hover-text-color);

      &::before {
        border: none;
      }
    }
  }
}

@keyframes borderRotate {
  0%,
  100% {
    clip-path: inset(0 0 95% 0);
  }
  25% {
    clip-path: inset(0 0 0 95%);
  }
  50% {
    clip-path: inset(95% 0 0 0);
  }
  75% {
    clip-path: inset(0 95% 0 0);
  }
}
