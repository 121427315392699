@import '../layout/panel.css';
@import '../layout/input.css';
@import '../layout/toolbar.css';

.messenger-send.compact {

  > .layout-panel {
    padding: 0;
    background: none;
    border: none;
    grid-auto-flow: column;
    gap: var(--messenger-form-gap);

    > .layout-input {
      height: 50px;
      background: var(--messenger-input-bg, var(--input-bg));
      border: var(--messenger-input-border, var(--input-border));

      &:focus {
        border: var(--messenger-input-border-focus, var(--input-border-focus));
        box-shadow: var(--messenger-input-boxshadow-focus, var(--input-boxshadow-focus));
      }
    }

    > .layout-toolbar {
      display: contents;

      > .layout-link {
        height: 50px;
        width: 50px;
        justify-content: center;
        border-radius: var(--send-btn-border-radius);

        > .icon {
          flex-basis: 34px;
          fill: var(--send-btn-icon-color);
        }
        > .label {
          display: none;
        }
      }
    }
  }
}
