.ons-layout.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background: rgba(0, 0, 0, .8);
}

.ons-layout.overlay.fixed-main {
  z-index: 2;
}

@media (max-width: 767px) {
  body.regard-shop-open .ons-layout.overlay.fixed-main {
    z-index: unset;
  }
}

.ons-layout.overlay.animate.ng-enter {
  animation: ons-fade-in .5s ease;
}

.ons-layout.overlay.animate.ng-leave {
  animation: ons-fade-in .5s ease reverse;
}

.ons-layout.overlay > .box {
  position: absolute;
  top: 45px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  max-height: 755px;
  max-width: 885px;
}

@media (min-width: 480px) and (min-height: 480px) {
  .ons-layout.overlay > .box {
    width: 85%;
    height: 90%;
  }
}
@media (max-width: 1023px) {
  .ons-layout.overlay[hookname="payment"] > .box,
  .ons-layout.overlay > .box.default-overlay {
    max-width: 460px;
  }
  .ons-layout.overlay > .box.doi-hint-box {
    max-width: 600px;
  }
}

@media (max-width: 1279px) {
  .ons-layout.overlay.fixed-main > .box.default-overlay {
    max-width: 460px;
  }
}
@media (min-width: 1600px) {
  .ons-layout.overlay.fixed-main > .box > .ons-scroll,
  .ons-layout.overlay.fixed-main > .box > .ons-scroll > .box {
    height: unset;
  }
}

@media (max-width: 1599px) {
  body.regard-shop-open .ons-layout.overlay.fixed-main > .box.default-overlay,
  body.show-dialog .ons-layout.overlay.fixed-main > .box.default-overlay {
    max-width: 460px;
  }
}
@media (min-width: 1280px) {
  body:not(.regard-shop-open):not(.show-dialog) .ons-layout.overlay.fixed-main > .box > .ons-scroll,
  body:not(.regard-shop-open):not(.show-dialog) .ons-layout.overlay.fixed-main > .box > .ons-scroll > .box {
    height: unset;
  }
}

/**
 * TOOLBAR
 */
.overlay-toolbar > .box {
  display: flex;
}

.overlay-toolbar > .box > .ons-item:last-child {
  margin-left: auto !important;
}

.overlay-toolbar > .box > .ons-item > .box {
  display: block;
  padding: 15px;
  line-height: 1;
}

.overlay-toolbar > .box > .ons-item > .box > .icon {
  height: 21px;
  width: 21px;
  margin: -1px -5px;
  filter: drop-shadow(0 1px 2px rgba(0,0,0,.4));
}
.overlay-toolbar > .box > .ons-item > .box > .icon * {
  fill: white;
}

.ons-layout.overlay > .box > .ons-layout.overlay-toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
}

.ons-layout.overlay > .box > .ons-layout.overlay-toolbar > .box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 1024px) {
  .ons-layout.overlay > .box.default-overlay > .ons-layout.overlay-toolbar > .box {
    z-index: 1;
    top: 0;
    bottom: unset;
    background-color: unset;
  }
}

.overlay-toolbar.transactionResultOverlay > .box > .ons-item > .box > .icon *,
.overlay-toolbar.paybackResultOverlay > .box > .ons-item > .box > .icon *,
.ons-layout.overlay > :not(.box.withBonus) > .overlay-toolbar.payment > .box > .ons-item > .box > .icon * {
  fill: #666 !important;
}

.overlay-toolbar.transactionResultOverlay > .box > .ons-item:hover > .box,
.overlay-toolbar.paybackResultOverlay > .box > .ons-item:hover > .box,
.overlay-toolbar.payment > .box > .ons-item:hover > .box {
  background: transparent !important;
}

.overlay-toolbar.transactionResultOverlay > .box > .ons-item:hover > .box > .icon *,
.overlay-toolbar.paybackResultOverlay > .box > .ons-item:hover > .box > .icon *,
.ons-layout.overlay > :not(.box.withBonus) > .overlay-toolbar.payment > .box > .ons-item:hover > .box > .icon * {
  fill: black !important;
}

@media (max-width: 1023px) {
  .ons-layout.overlay > .box.withBonus > .overlay-toolbar.payment > .box > .ons-item > .box > .icon * {
    fill: #666 !important;
  }

  .ons-layout.overlay > .box.withBonus > .overlay-toolbar.payment > .box > .ons-item:hover > .box > .icon * {
    fill: black !important;
  }
}

/**
 * CONTENT
 */
.ons-layout.overlay-content {
  overflow: hidden;
  padding: 0 15px 15px;
}

.ons-layout.overlay > .box.default-overlay > .overlay-content {
  padding: 0;
}
.ons-layout.overlay-content.payment:not(.payback) {
  padding-bottom: 0;
}
.ons-layout.overlay > .box > .ons-scroll,
.ons-layout.overlay > .box > .ons-scroll > .box {
  height: 100%;
}

.rename-overlay-hook,
.password-set-form-hook,
.change-email-overlay-hook,
.password-reset-hook,
.password-set-overlay-hook {
  img {
    width: 100%;
  }
}

/**
 * FRIEND CONFIRM
 */
.ons-layout.overlay[hookname="friendConfirmEnd"] > .box {
  max-width: 350px;
}

/**
 * PAYMENT
 */
.ons-layout.overlay[hookname="payment"] > .box {
  max-height: 885px;
}

@media (min-width: 1024px) {
  .ons-layout.overlay > .payment-box.withBonus {
    top: 0;
  }

  .ons-layout.overlay > .payment-box:not(.iframeActive) > .overlay-content,
  .ons-layout.overlay > .payment-box:not(.iframeActive) > .overlay-content > .box {
    height: auto;
  }

  :lang(de).overlay > .payment-box > .overlay-content.showFreeCoinsBanner > .box {
    background-image: url(../../basic/img/freecoins/payment_overlay_header_de.jpg) !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
  }
  :lang(en).overlay > .payment-box > .overlay-content.showFreeCoinsBanner > .box {
    background-image: url(../../basic/img/freecoins/payment_overlay_header_en.jpg) !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
  }
  .overlay > .payment-box > .overlay-content.showFreeCoinsBanner [onsw-payment-country] {
    background-color: white;
    margin-top: 210px;
    margin-left: 170px;
  }
  .overlay > .payment-box > .overlay-content.showFreeCoinsBanner [onsw-item].ons-heading.payment {
    background-color: white;
    margin-left: 170px;
  }
}

/**
 * NOTIFCATIONREQUEST
 */
.ons-layout.overlay[hookname="notificationRequestOverlay"] > .box {
  max-width: 480px;
  margin: auto;
}

/**
 * BONUS
 */
.ons-layout.overlay-toolbar.bonusOverlay > .box {
  z-index: 1;
  top: 0;
  bottom: unset;
  background-color: unset;
}

@media (min-width: 1024px) {
  .box.withBonus > .ons-layout.overlay-toolbar.payment > .box {
    z-index: 1;
    top: 0;
    bottom: unset;
    background-color: unset !important;
  }

  .box.withBonus > .ons-layout.overlay-toolbar.payment > .box > .ons-heading {
    display: none;
  }

  .box.withBonus > .ons-layout.overlay-toolbar.payment > .box > .ons-item[click-action] > .box {
    padding: 20px 25px;
  }

  .ons-layout.overlay > .box.withBonus > .overlay-content.payment {
    padding-top: 15px;
  }
}

/**
 * DOI HINT
 */
@media (min-width: 1024px) {
  .ons-layout.overlay-content.doiHint {
    height: auto !important;
  }
}


/**
 * Chat Bug Refund
 */
.chat-bug-refund-box {
  max-width: 715px !important;
}

.overlay-toolbar.chatBugRefund > .box > .ons-item.ons-heading > .box {
  font-size: 20px;
}

.overlay-content.chatBugRefund > .box {
  font-size: larger;
}
