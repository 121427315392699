body:not(.show-contacts) .ons-layout.columns.shrink > .box > [class*="col"] { width: 100%; }

body:not(.show-contacts) .ons-layout.columns.shrink > .box > .left-xs { float: left; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .right-xs { float: right; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .clear.right-xs { clear: right; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .clear.left-xs { clear: left; }

body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col10-xs { width: 10%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col16-xs { width: 16.666%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col20-xs { width: 20%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col25-xs { width: 25%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col30-xs { width: 30%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col33-xs { width: 33.333%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col40-xs { width: 40%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col50-xs { width: 50%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col60-xs { width: 60%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col66-xs { width: 66.666%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col70-xs { width: 70%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col75-xs { width: 75%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col80-xs { width: 80%; }
body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col100-xs { width: 100%; }

@media (min-width: 768px) {
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col10-md { width: 10%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col16-md { width: 16.666%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col20-md { width: 20%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col25-md { width: 25%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col30-md { width: 30%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col33-md { width: 33.333%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col40-md { width: 40%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col50-md { width: 50%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col60-md { width: 60%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col66-md { width: 66.666%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col70-md { width: 70%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col75-md { width: 75%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col80-md { width: 80%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col100-md { width: 100%; }

  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .right-md { float: right }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .left-md { float: left }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .no-float-md { float: none }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .clear.right-md { clear: right; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .clear.left-md { clear: left; }
}

@media (min-width: 1024px) {
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col10-lg { width: 10%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col16-lg { width: 16.666%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col20-lg { width: 20%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col25-lg { width: 25%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col30-lg { width: 30%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col33-lg { width: 33.333%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col40-lg { width: 40%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col50-lg { width: 50%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col60-lg { width: 60%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col66-lg { width: 66.666%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col70-lg { width: 70%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col75-lg { width: 75%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col80-lg { width: 80%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col100-lg { width: 100%; }

  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .right-lg {float: right;}
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .left-lg { float: left }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .no-float-lg { float: none }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .clear.right-lg {clear: right;}
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .clear.left-lg { clear: left; }
}

@media (min-width: 1280px) {
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col10-xl { width: 10%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col16-xl { width: 16.666%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col20-xl { width: 20%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col25-xl { width: 25%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col30-xl { width: 30%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col33-xl { width: 33.333%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col40-xl { width: 40%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col50-xl { width: 50%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col60-xl { width: 60%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col66-xl { width: 66.666%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col70-xl { width: 70%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col75-xl { width: 75%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col80-xl { width: 80%; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .col100-xl { width: 100%; }

  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .right-xl { float: right }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .left-xl { float: left }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .no-float-xl { float: none }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .clear.right-xl { clear: right; }
  body:not(.show-contacts) .ons-layout.columns.shrink > .box > .clear.left-xl { clear: left; }
}

