/* afo */
.layout-container.layout-overlay {

  .layout-panel {
    --panel-background: #a60a6e;
  }

  .layout-toolbar {
    --toolbar-icon-color: #fd8faf;
  }
}
