.ons-item.tile {
  opacity: 1;
  transition: opacity .5s ease;
  height: 100%;
}

.livecam-tile-box .ons-item.tile.inactive {
  display: none;
}

.ons-item.tile.ng-enter {
  opacity: 0;
  transition-delay: .2s;
}

.ons-item.tile.ng-enter-active {
  opacity: 1;
}

.ons-item.tile.ng-leave-active {
  opacity: 0;
}

.ons-item.tile > .box:hover > .icon {
  transform: scale(1.3);
}

.livecam-tile-box .ons-item.tile > .box:hover > .icon {
  transform: unset;
}

.ons-item.tile > .box > .icon {
  margin-top: 5px;
  flex-grow: 1;
  width: auto;
  max-height: 72px;
  transition: transform .5s ease;
}

.ons-item.tile > .box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(2px 2px 2px black);
  cursor: pointer;
}

.ons-item.tile.hotornot-widget,
.ons-item.tile.sedcardImage {
  height: unset;
  @media (hover: none) {
    display: none;
  }
}

.ons-item.tile.hotornot-widget > .box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: unset;
}

.livecam-tile-box .ons-item.tile {
  opacity: 0;
  background: rgba(0,0,0,.4);
}

.livecam-tile-box .ons-item.tile:hover {
  opacity: 1;
}

.ons-item.tile.hotornot-widget > .box {  
  bottom: unset;
  height: 196px;
}

.ons-item.tile > .box .label,
.ons-item.tile > .box .value,
.ons-item.tile > .box .price,
.ons-item.tile > .box .additional {
  display: block;
  font-size: 12px;
  white-space: nowrap;
}

.ons-item.tile > .box .label {
  font-family: var(--headline);
  text-transform: uppercase;
  font-size: 16px;
}

onsw-open-dialog-button.ons-item.tile > .box > .icon * {
  fill: #fff;
}

.ons-layout.tileAmateur:first-child > .box > svg,
.livecam-tile-box > :first-child > .box > svg {
  position: absolute;
  top: 0;
  right: 2%;
  width: auto;
  pointer-events: none;
}

.ons-layout.tileAmateur:first-child > .box > svg {
  height: 25%;
  overflow: visible;
}

.livecam-tile-box > :first-child > .box > svg {
  height: 35%;
}

.layout-toolbar.toolbar-list.livecam-tile-widget {
  height: 20px !important;
}
