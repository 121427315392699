.ons-form > form > label {
  display: block;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.ons-form > form > label > textarea,
.ons-form > form > label > file-upload,
.ons-form > form > label > input[type="text"],
.ons-form > form > label > input[type="number"],
.ons-form > form > label > input[type="email"],
.ons-form > form > label > input[type="password"],
.ons-form > form > label > select {
  display: block;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

.ons-form > form > label > input[type="radio"],
.ons-form > form > label > input[type="checkbox"] {
  cursor: pointer;
  float: left;
  position: relative;
  margin: 2px 8px 0 0;
}

.ons-form > form > :first-child {
  margin-top: 0;
}

.ons-form > form > :last-child {
  margin-bottom: 0;
}

.ons-form.busy {
  cursor: wait;
  > * {
    pointer-events: none;
  }

  > form {
    opacity: .5;
  }
}

[onsw-confirm-mail].ons-form.busy form {
  opacity: 0;
}

:disabled {
  cursor: not-allowed;
  opacity: .5;
}

/**
 * Inline
 */
.ons-form.inline,
.ons-form.inline > form,
.ons-form.inline > form > label,
.ons-form.inline > form > p,
.ons-form.inline > .box > .buttons > .box,
.ons-form.inline > form > div {
  font-size: 0;
  display: inline;
}

.ons-form.inline > form > label > input[type="text"],
.ons-form.inline > form > label > input[type="number"],
.ons-form.inline > form > label > input[type="email"],
.ons-form.inline > form > label > input[type="password"] {
  display: inline-block;
  vertical-align: top;
  width: 150px;
  margin-right: 5px;
}

.ons-form.inline > form > label > span {
  display: none;
}

.ons-form.inline.table {
  display: inline-table;
}

.ons-form.inline.table > .box {
  display: table-row;
}

.ons-form.inline.table > .box > * {
  display: table-cell;
}

.ons-form.inline.table > .box > label > input {
  width: 100%;
}

.ons-form.inline.table > .box > * > * {
  float: left;
}

.userPool-login.ons-form.inline > form > label > input[type="checkbox"] {
  display: none;
}

.ons-form.hide-placeholder ::-webkit-input-placeholder {
  color: transparent;
}

.ons-form.hide-placeholder :-moz-placeholder {
  color: transparent;
}

.ons-form.hide-placeholder ::-moz-placeholder {
  color: transparent;
}

.ons-form.hide-placeholder :-ms-input-placeholder {
  color: transparent;
}

.ons-form > form > label > input.ng-dirty.ng-pending:not(.ng-empty) {
  background-image: url("../basic/img/validation/loop.svg");
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 16px;
}

.ons-form > form > label > input.ng-invalid.ng-invalid-api:not(.ng-empty) {
  background-image: url("../basic/img/validation/ask.svg");
  background-repeat: no-repeat;
  background-position: right 3px center;
  background-size: 22px;
}

.ons-form > form > label > input.ng-valid.ng-valid-api:not(.ng-empty) {
  background-image: url("../basic/img/validation/checkmark.svg");
  background-repeat: no-repeat;
  background-position: right 3px center;
  background-size: 22px;
}

.ons-form > form > label {
  position: relative;
}

.ons-form > form > label > .validation {
  /*display: none;*/
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 2px 5px;
  background: #cb0100;
  border: 2px solid #cb0100;
  text-shadow: none;
  border-top: inherit;
  color: white;
}

.ons-form > form > label > .core-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ons-form > form > label > .ng-invalid-api:not(.ng-empty) + .validation {
  display: block;
}

.ons-form > form > label > input.ng-invalid.ng-invalid-api:not(.ng-empty) {
  border: 2px solid #cb0100;
  background-color: white;
}

.ons-form > form > label > .validation * {
  display: block;
}

.ons-form > form > label > .validation > .fail::before {
  display: none;
}

.ons-form > form > p {
  margin: 10px 0;
}

.ons-form.profile > .box {
  position: relative;
}

.ons-layout.columns > .box > .blockFormContainer form {
  max-width: 430px;
  padding-bottom: 15px;
}

.ons-layout.columns > .box > .blockFormContainer .passwordSetOverlayGroup form {
  padding-bottom: 0;
}
.passwordSetForm form.box {
  padding: 15px 0;
}

