onsw-alternative-products {
  display: contents;

  onsw-section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &.large {
      .button {
        grid-row: 1 / 3;
      }
    }

    .product {
      padding: 10px;
      row-gap: 10px;
      column-gap: 40px;
      display: grid;
      background: var(--alternative-product-background);
      border: var(--alternative-product-border);

      .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        height: 70px;
        background: var(--alternative-product-logo-background);

        > .logo {
          max-width: 220px;
          max-height: 50px;
        }
      }

      .text {
        grid-row-start: 2;

        p.description {
          font: var(--alternative-product-description-font);
          line-height: 1.5;
          margin: 10px 0 0;
        }
      }

      .button {
        justify-self: right;
        align-self: end;
      }
    }
  }
}
