.ons-heading > .box {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ons-heading.default.popupToolbar > .box,
.ons-layout.popup > .box > .ons-heading.default > .box {
  white-space: unset;
  margin: 0 !important;
}

.ons-overlay > .content > :not(.top) [onsw-heading] {
  display: none;
}

