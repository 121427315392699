@import 'note.css';

.layout-note.warn {
  color: var(--layout-note-warn-color);
  background: var(--layout-note-warn-background);

  > .close > .icon * {
    fill: var(--layout-note-warn-color);
  }
}

