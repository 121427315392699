@import '../layout/link.css';

.layout-link.regard-category {
    padding: 5px 10px;
    gap: 10px;
    color: var(--regard-link-color, var(--link-color));

    > .icon {
        width: 40px;
        height: 40px;
    }

    @media (hover: hover) {
        &:hover {
            color: var(--regard-link-color-hover, var(--link-color-hover, var(--link-color)));
        }
    }

    &.active {
        color: var(--regard-link-color-active, var(--link-color-active, var(--link-color)));
    }
}
