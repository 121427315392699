/**
 * TODO textshadow...
 */
.layout-link {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  margin: var(--link-margin);
  padding: var(--link-padding);
  gap: var(--link-gap, 4px);

  outline: var(--link-outline);
  outline-offset: var(--link-outline-offset);

  border-radius: var(--link-border-radius);
  background: var(--link-background);
  border: var(--link-border);
  font: var(--link-font);
  color: var(--link-color);
  text-transform: var(--link-text-transform);
  filter: var(--link-filter);
  box-shadow: var(--link-box-shadow);
  text-decoration: none;

  > .icon {
    flex: 0 0 var(--link-icon-size, 30px);
    fill: var(--link-icon-color, var(--link-color));
  }

  @media (hover: hover) {
    &:hover {
      border: var(--link-border-hover, var(--link-border));
      outline: var(--link-outline-hover, var(--link-outline));
      background: var(--link-background-hover, var(--link-background));
      color: var(--link-color-hover, var(--link-color));
      text-decoration: none;

      > .icon {
        fill: var(--link-icon-color-hover, var(--link-color-hover, var(--link-color)));
      }
    }
  }

  &.layout-active,
  &.active {
    border: var(--link-border-active, var(--link-border));
    outline: var(--link-outline-active, var(--link-outline-hover, var(--link-outline)));
    background: var(--link-background-active, var(--link-background-hover, var(--link-background)));
    color: var(--link-color-active, var(--link-color-hover, var(--link-color)));

    > .icon {
      fill: var(--link-icon-color-active, var(--link-icon-color-hover, var(--link-color)));
    }
  }
}
