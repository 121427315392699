.messenger-dialog > onsw-messenger-message-box {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
}

onsw-messenger-message-box > .box > .msg.in > .box {
  background: #ffe0b2;
  color: black;
}

onsw-messenger-message-box > .box > .msg.out > .box {
  background: #e6e6e6;
  color: black;
}

onsw-messenger-message-box > .box > .msg > .meta {
  font-size: 11px;
  margin-top: 3px;
  color: #8a8a8a;
}

onsw-messenger-message-box > .box > .msg.match > .box,
onsw-messenger-message-box > .box > .msg.kiss > .box,
onsw-messenger-message-box > .box > .msg.sexicon > .box {
  font-weight: 700;
  line-height: 1.2;
}

onsw-messenger-message-box > .box > .msg.match > .box,
onsw-messenger-message-box > .box > .msg.kiss > .box {
  color: #cc0000;
}

onsw-messenger-message-box > .box > .msg.in > .box::after {
  border-right-color: #ffe0b2;
}

onsw-messenger-message-box > .box > .msg.out > .box::after {
  border-left-color: #e6e6e6;
}


onsw-messenger-message-box.ready > .box > .msg > .box,
onsw-messenger-message-box.ready > .box > .msg > .box::after {
  transition: all .3s ease-out;
}

onsw-messenger-message-box > .box > .msg.ng-enter-active > .box {
  background-color: #79bd07;
  color: white;
}
onsw-messenger-message-box > .box > .msg.out.ng-enter-active > .box::after {
  border-left-color: #79bd07;
}
onsw-messenger-message-box > .box > .msg.in.ng-enter-active > .box::after {
  border-right-color: #79bd07;
}
