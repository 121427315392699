.imageOverlay {
    z-index:1050;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.imageOverlay .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.8;
}
.imageOverlay img {
    transition: 0.1s all ease-out;
    position: absolute;
    max-width: 96%;
    max-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}