.ons-item.livecamLoading > .box::after {
  font-family: var(--default-font);
}

[onsw-item].aotm-widget {
  font-family: var(--headline);
}

[onsw-chosen] > .box > .available > .box > .option:hover {
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #738e15 0%, #8dbd0d 100%);
}
