#livecamPlayer {
  position: relative;
}

[hookname="livecamTipPopup"] {
  width: unset !important;
  max-width: unset !important;
  bottom: 50px;
  z-index: 1000 !important;
}

[hookname="livecamTipPopup"] > .box > [onsw-tip-button] > .box {
  width: 100%;
  box-sizing: border-box;
}
