@import '../layout/toolbar.css';
@import '../layout/link.css';

.messenger-voice-recorder {
  --wavesurfer-wave-color: var(--voicemessage-recorder-wave-color);
  --wavesurfer-wave-progress-color: var(--voicemessage-recorder-wave-progress-color);

  > .layout-toolbar.messenger-voice {
    background: var(--messenger-input-bg);
    border: var(--messenger-input-border);
    color: var(--voicemessage-recorder-text-color);
    font: 12px var(--default-font);

    padding: 0 10px;
    border-radius: var(--input-textarea-border-radius, var(--input-border-radius));

    > .record-indicator {
      display: contents;

      > .icon {
        flex: 0 0 28px;
        fill: #c80000;
      }
    }

    > .btn-icon.play,
    > .btn-icon.delete {
      > .icon {
        flex-basis: 24px;
        padding: 3px;
        border: 1px solid var(--voicemessage-recorder-btn-color);
        fill: var(--voicemessage-recorder-btn-color);
      }

      &:hover > .icon {
        border-color: var(--voicemessage-recorder-btn-color-hover);
        fill: var(--voicemessage-recorder-btn-color-hover);
      }
    }
  }

  > .layout-link {
    height: 50px;
    width: 50px;
    border-radius: var(--record-btn-border-radius);
    justify-content: center;

    > .icon {
      flex-basis: 34px;
      fill: var(--send-btn-icon-color);
    }

    &.btn-record,
    &.btn-stop {
      background: var(--record-btn-bg);
      border: var(--record-btn-border);

      &:hover {
        background: var(--record-btn-hover-bg);
        border: var(--record-btn-hover-border);
      }
    }
  }

  /* state */
  &.layout-hidden {
    display: none;
  }

  &.layout-inactive {
    > .btn-send,
    > .btn-stop,
    > .layout-toolbar.messenger-voice {
      display: none;
    }
  }

  &.layout-recording {
    > .btn-send,
    > .btn-record {
      display: none;
    }

    > .layout-toolbar.messenger-voice {
      > .duration.time,
      > .btn-icon.delete,
      > .btn-icon.play {
        display: none;
      }
    }
  }

  &.layout-active {
    > .btn-record {
      display: none;
    }

    > .layout-toolbar.messenger-voice {
      > .duration.countdown,
      > .record-indicator {
        display: none;
      }
    }

    &:not(.layout-playing) {
      > .btn-stop {
        display: none;
      }
    }

    &.layout-playing {
      > .btn-send {
        display: none;
      }

      > .layout-toolbar.messenger-voice > .btn-icon.play > .icon {
        background: var(--voicemessage-recorder-btn-bg-active);
        border-color: var(--voicemessage-recorder-btn-bg-active);
        fill: var(--voicemessage-recorder-btn-color-active);
      }
    }
  }
}
