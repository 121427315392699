.ons-layout.overlay.fixed-main {
  background: rgba(0, 0, 0, .5);
}
.livecamRegardOverlay {
  box-shadow: 0 0 90px black;
}
@media (min-width: 1280px) {
  .ons-layout.overlay > .livecam-regard-overlay-box {
    width: 885px;
  }
  .livecamRegardOverlay [onsw-image],
  .livecamRegardOverlay [onsw-image] {
    padding: 10px 0 10px 10px !important;
  }
}
@media (max-width: 1599px) {
  body.regard-shop-open .livecamRegardOverlay [onsw-image],
  body.show-dialog .livecamRegardOverlay [onsw-image] {
    padding: 10px 0 0 !important;
  }
}

.ons-layout.overlay > .livecam-regard-overlay-box {
  top: 0 !important;
}

.ons-layout.overlay > .box > .ons-layout.overlay-toolbar.livecamRegardOverlay > .box {
  top: 0;
  bottom: unset;
  z-index: 1;
  background: transparent;
  pointer-events: none;
}
.overlay-toolbar.livecamRegardOverlay > .box > .ons-item[click-action] > .box {
  pointer-events: all;
}
.overlay-toolbar.livecamRegardOverlay > .box > .ons-item > .box {
  padding: 10px;
}

.livecamRegardOverlay .ons-heading {
  padding-top: 10px;
  line-height: 1.2;
}

.livecamRegardOverlay .ons-heading > span {
  display: block;
}

.livecam-regard-overlay-hook onsw-regard-button {
  display: block;
  margin-bottom: 10px;
  > .box > .icon {
    display: none;
  }
}
