.sexicon-list {
  .price-box {
    text-align: right;

    > .icon {
      width: 20px;
      height: 20px;
      vertical-align: text-bottom;
      padding-left: 4px;
    }
  }

  [onsw-sexicons] {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: var(--sexicon-list-padding);

    > img {
      cursor: pointer;
      width: var(--sexicon-img-width);
    }
  }
}
