@import 'input.css';

.layout-field.layout-input-toggle {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 90px;

  --input-toggle-gap: 2px;
  --input-toggle-width: 54px;
  --input-toggle-height: 24px;

  /* internal */
  --toggle-padding: calc(var(--input-toggle-height) / 2 + var(--input-toggle-height) / 2 / 1.4142);

  > input[type='checkbox'] {
    grid-area: 1 / 2;
    height: 0;
    width: 0;
    visibility: hidden;

    &:not(:checked) + .toggle {
      background: var(--toggle-bg-off);
      color: white;
      padding-left: var(--toggle-padding);

      > .on {
        display: none;
      }
    }

    &:checked + .toggle {
      background: var(--toggle-bg-on);
      color: var(--toggle-color-on);
      padding-right: var(--toggle-padding);

      > .off {
        display: none;
      }

      &:after {
        left: calc(var(--input-toggle-width) - var(--input-toggle-height));
      }
    }
  }

  > .toggle {
    box-sizing: border-box;
    grid-area: 1 / 2;
    line-height: 1;
    width: var(--input-toggle-width);
    height: var(--input-toggle-height);
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: end;
    border-radius: calc(var(--input-toggle-height) / 2);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin: var(--input-toggle-gap);
      width: calc(var(--input-toggle-height) - 2 * var(--input-toggle-gap));
      height: calc(var(--input-toggle-height) - 2 * var(--input-toggle-gap));
      background: white;
      border-radius: 50%;
      transition: left 0.3s;
    }
  }
}
