/**
 * @see [Documentation](../../js/layout/style/slideshow.js)
 */

.nova-slideshow {
  aspect-ratio: 16 / 9;
  overflow: hidden;
  display: grid;
  border-radius: var(--slideshow-border-radius, var(--default-border-radius));
  grid-template: minmax(0, 1fr) min-content / minmax(0, 1fr);

  > .slide {
    grid-area: 1 / 1 / 3 / 2;
    position: relative;

    ~ .slide {
      pointer-events: none;
    }
  }

  > .nova-dots.layout-toolbar {
    grid-area: 2 / 1;
    z-index: 2;
    margin: var(--slideshow-toolbar-margin, 0 0 var(--toolbar-gap, 6px) 0);
  }

  &.remove-to {
    > .slide {
      will-change: transform;

      &.layout-leave {
        z-index: 1;
        animation-duration: var(--slideshow-transition-duration, 1s);
        animation-timing-function: ease-in;
      }
    }

    &.left > .slide.layout-leave {
      animation-name: layout-slideshow-remove-to-left;
    }

    &.right > .slide.layout-leave {
      animation-name: layout-slideshow-remove-to-right;
    }

    &.bottom > .slide.layout-leave {
      animation-name: layout-slideshow-remove-to-bottom;
    }

    &.top > .slide.layout-leave {
      animation-name: layout-slideshow-remove-to-top;
    }
  }

  &.cube3d {
    perspective: var(--slideshow-width, 800px);

    > .slide {
      will-change: transform;
      backface-visibility: hidden;
      transform-origin: 50% 50% calc(var(--slideshow-width) / -2);
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;
      animation-duration: var(--slideshow-transition-duration, 1s);

      &.layout-enter {
        animation-name: layout-slideshow-cube3d-left-enter;
      }

      &.layout-leave {
        animation-name: layout-slideshow-cube3d-left-leave;
      }
    }
  }

  &.fade {
    > .slide {
      will-change: opacity;

      &.layout-enter {
        animation: layout-slideshow-fade-in ease-out var(--slideshow-transition-duration, 2s);
      }

      &.layout-leave {
        animation: layout-slideshow-fade-out ease-in var(--slideshow-transition-duration, 2s);
      }
    }
  }
}

@keyframes layout-slideshow-fade-in {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
  }
}

@keyframes layout-slideshow-fade-out {
  to {
    opacity: 0;
    animation-timing-function: ease-out;
  }
}

@keyframes layout-slideshow-cube3d-left-leave {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(-90deg);
  }
}

@keyframes layout-slideshow-cube3d-left-enter {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0);
  }
}

@keyframes layout-slideshow-cube3d-right-leave {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(90deg);
  }
}

@keyframes layout-slideshow-cube3d-right-enter {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0);
  }
}

@keyframes layout-slideshow-cube3d-top-leave {
  from {
    transform: rotateX(0);
  }
  to {
    transform: rotateX(90deg);
  }
}

@keyframes layout-slideshow-cube3d-top-enter {
  from {
    transform: rotateX(-90deg);
  }
  to {
    transform: rotateX(0);
  }
}

@keyframes layout-slideshow-cube3d-bottom-leave {
  from {
    transform: rotateX(0);
  }
  to {
    transform: rotateX(-90deg);
  }
}

@keyframes layout-slideshow-cube3d-bottom-enter {
  from {
    transform: rotateX(90deg);
  }
  to {
    transform: rotateX(0);
  }
}

@keyframes layout-slideshow-remove-to-bottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes layout-slideshow-remove-to-top {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes layout-slideshow-remove-to-left {
  to {
    transform: translateX(-100%);
  }
}

@keyframes layout-slideshow-remove-to-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
