

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: local('Oswald'), url('../fonts/oswald.woff') format('woff');
}

body {
  font-weight: 400;
  color: var(--text-default);
  background: var(--background);
}

a:hover, a:active,
.ons-item.link.active > .box,
.ons-item.link > .box.active,
.ons-item.link > .box:hover {
  color: #12c1de;
}


/* Boxes */
.frame {
  background: #a60a6e;
  background-clip: content-box;

  > .box {
    padding: 10px 8px;

    @media (min-width: 469px) {
      padding: 10px 15px;
    }
  }

  .frame {
    background-color: #7f0453;
  }
}

.ons-layout.fixed-main:not(.overlay) > .box {
  background: var(--background);
}

.ons-heading.default > .box {
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}

.livecam-tile-box,
.media-box,
.amateur-friend-box,
.tile-friend-box,
.tile-amateur-box {
  background-color: #a60a6e !important;
}


/* Dropdown */
.ons-layout.dropdown > .box {
  > .spacer {
    border-color: #c663a2;
  }

  > * {
    > .box {
      background: #a60a6e;
      color: white;

      &.active,
      &:hover {
        background: #a60a6e;
        color: #12c1de;
      }
    }
  }
}


/* Forms */
onsw-template p.help-block,
.ons-form > form > p.small {
  color: #d996af;
  font-size: 12px;
  line-height: 1.3;
}

.ons-form > .box > label > input:not([type="checkbox"]),
.ons-form > .box > label > file-upload,
.ons-form > .box > label > select,
.ons-form > form > label > textarea {
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  color: var(--input-color);
  background: var(--input-bg);
  padding: 5px;
  -moz-appearance: none;
}

.ons-form > .box > label > input:not([type="checkbox"]),
.ons-form > .box > label > file-upload,
.ons-form > form > label > textarea {
  padding: 5px 10px;
}

.ons-form > .box > label > input:not([type="checkbox"]),
.ons-form > .box > label > file-upload,
.ons-form > .box > label > select {
  height: 30px;
}

file-upload:before {
  margin: -5px -10px;
  padding: 5px 10px;
  color: #e900e0;
  background: #fae9f0;
  border-top-right-radius: var(--input-border-radius);
  border-bottom-right-radius: var(--input-border-radius);
}

.ons-form > .box > label > input:not([type="checkbox"]):focus,
.ons-form > .box > label > file-upload:focus,
.ons-form > .box > label > textarea:focus,
.ons-form > .box > label > select:focus {
  border: var(--input-border-focus);
  box-shadow: none;
}

.ons-form > form > label > span,
[onsw-facet-filter] .ons-form > .box > label > span {
  color: var(--text-default);
}

[onsw-facet-filter] {
  > .box {
    margin-top: -5px !important;
  }
}

[onsw-chosen] > .box {
  input {
    border-left: none;
    border-right: none;
  }

  input,
  > .info {
    padding: 0 10px;
    height: 30px;
    background: white;
    color: var(--input-color);
    border-radius: var(--input-border-radius);
  }

  > .available {
    color: var(--input-color);
    background: white;
    border: none;

    > .box {
      border-radius: var(--input-border-radius);

      > input {
        border: 1px solid #e902e0;
      }

      > .option:hover {
        background: #a60a6e;
        color: white;
      }
    }
  }

  > .selected {
    > .option,
    > .add {
      background: #12c1de;
      color: #a60a6e;
    }

    > .add > span {
      color: #a60a6e !important;
    }

    > .option:after  {
      color: #a60a6e !important;
    }
  }
}

[onsw-amateur-common-filter] > .box > div.suggestions {
  border: none;
  background-color: white;

  .suggestion.selected {
    background: #fae9f0;
    color: var(--input-color);
  }
}


/* Images */
:not(.payment-hook) .ons-image.busy > .box,
:not(.payment-hook) .ons-image.done > .box,
:not(.payment-hook) .ons-image.error > .box {
  background-color: #a70a6e;
}


/* Sedcard */
.ons-image.sedcardImage > .box {
  background-color: #a70a6e;
}

onsw-more-about-me {
  dt {
    color: #12c1de;
  }

  h4 {
    color: #b0eb2c;
    font-size: 14px;
  }
}


/* Tagebuch */
.frame .frame.feeditem {
  background: transparent;

  > .box {
    border: 1px solid #c663a2;
  }
}

p.small-feed-widget:first-child > .box,
header > .box > p.ons-text {
  color: #d996af;
}

.small-feed-box:hover {
  background: #951958;
}

/* Kacheln */
.ons-item.tile > .box {
  filter: drop-shadow(1px 1px 2px #940C3F);

  > .label {
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
  }
}

.media-widget > .box > .detail,
.tile-amateur-widget > .box > .detail,
.ons-item.small-tile > .box {
  background: rgba(186, 30, 96, .7);
}


/* online */
.contact-box .icon.online {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
}

/* Suche */
[onsw-facet-filter] > .box {
  background: unset;
  padding: 0;
}

.ons-text.emptyList > .box {
  color: var(--text-default);
  padding: 20px 15px;
  text-align: center;
  background: #a70a6e;

  > .text::before {
    background-image: url(../img/noResult.png);
    background-size: contain;
  }
}


/* Scrollbalken */
:not(onsw-messenger-message-box).ons-scroll {
  > .scroll-overlay > .bar > .slider {
    background-color: white;
    opacity: .35;
  }
}

.ons-scroll > .scroll-overlay > .bar {
  width: 5px !important;
  right: 2px !important;
}

main.ons-scroll > .scroll-overlay > .scroll-top {
  background: #12c1de;
  color: white;
  border-radius: 50%;

  > .icon * {
    fill: white;
  }

  &:hover {
    background: #f084f4;
  }
}


/* Attachments */
[onsw-attachments] {
  .attachment {
    background: #7f0453;

    &:hover {
      background: #f084f4;
    }

    .delete {
      > .icon {
        fill: #9c648f;
      }

      &:hover > .icon * {
        fill: white;
      }
    }
  }
}


/* Buttons */
.ons-item.button {
  > .box {
    background: var(--green);
    border: 0;

    > .label {
      color: white;
      text-transform: var(--default-btn-text-transform);
      font: var(--default-btn-font);
    }

    &:hover {
      background: var(--default-btn-hover-bg);

      > .label {
        color: white;
      }
    }

    .icon * {
      fill: white;
    }
  }

  &.cancel > .box {
    background: #de287c;
    border: 1px solid #de287c;

    > .label {
      color: white;
    }

    &:hover {
      background: #951958;
      border: 1px solid #951958;
    }
  }
}


/* Signup index */
[onsw-hook][hookname="welcomeGuest"] {
  .ons-heading > .box {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    color: #12c1de;
    text-transform: uppercase;
  }

  &.ons-layout.col50-lg > .box {
    background-color: #a60a6e;
    background-image: url(../img/signup_background.jpg) !important;

    @media (max-width: 619px) {
      background-color: #a60a6e;
      background-image: none;
      padding: 10px;
    }
  }

  > .box > div > onsw-template > .box {
    bottom: 40px;
  }
}


/* Close */
.notify.closeable > .box::before {
  fill: white;
  color: white;
  opacity: .5;
}

.notify.closeable > .box:hover::before {
  fill: white;
  color: white;
  opacity: 1;
}

.closeable > .box::before,
.ons-item.close > .box::after,
:not(.notify) > :not(.ons-item).close::after {
  fill: white;
  color: white;
}

/* Overlay */
.ons-layout.overlay-toolbar > .box {
  background: var(--overlay-bg);
}

.ons-layout.overlay-content {
  background: var(--overlay-bg);
}

.overlay-toolbar > .box > .ons-item {
  &.ons-heading > .box {
    color: white;
  }

  > .box {
    > .icon * {
      fill: #1d2129;
    }
  }

  &[click-action] {
    > .box:hover {
      color: #a60a6e;

      > .icon * {
        fill: white;
      }
    }
  }

  &.active > .box {
    color: #a60a6e;
    background: transparent !important;

    > .icon * {
      fill: #a60a6e;
    }
  }
}


/* Stock */
[onsw-stock-indicator].picture.invalid > .box > .label {
  background-color: #c40000;
  border-bottom: 1px solid #d10000;
}

[onsw-stock-indicator].valid > .box > .icon * {
  fill: var(--green);
}

.stock-search-hook {
  display: none !important;
}


/* hotornot */
[onsw-hotornot].ons-layout.group.fixed-main > .box {
  background-image: url(../img/hotornot/logo.png), url(../img/hotornot/bg.jpg);
  background-size: 300px, cover;
  background-repeat: no-repeat;
  background-position: 20px 20px, right;
}

body:lang(en) [onsw-hotornot].ons-layout.group.fixed-main > .box {
  background-image: url(../img/hotornot/logo_en.png), url(../img/hotornot/bg.jpg);
}

.hotornot-pending {
  background: linear-gradient(to bottom, rgb(166, 10, 110) 40% ,rgb(113, 5, 74) 100%);

  &:not(.pos1) > .box > [onsw-item].hotornot {
    background: rgba(166, 10, 110, .7);
    box-shadow: 0 0 5px #940c40;
    font-family: var(--default-font);
    font-size: 28px;
    font-weight: 300;
    color: #fdcaff;
  }
}

.hotbar > .box {
  background: linear-gradient(to bottom, rgb(166, 10, 110) 40%, rgb(113, 5, 74) 100%);

  > onsw-open-dialog-button > .box > .icon * {
    fill: #12c1de;
  }

  > [onsw-hotornot-button][type=hot] > .box {
    background-color: #b0eb2c;
    border: 2px solid #b0eb2c;

    > .icon * {
      stroke: white;
      fill: white;
    }

    &:hover {
      border: 2px solid #b0eb2c;

      > .icon * {
        stroke: #b0eb2c;
        fill: #b0eb2c;
      }
    }
  }

  [onsw-hotornot-button][type=not] > .box > .icon * {
    fill: #cc0000;
  }

  > .ons-item > .box {
    box-shadow: 0 0 8px #940c40;

    > .icon.amateur * {
      fill: #a60a6e;
    }
  }
}

.hotornot-positives {
  background: rgba(166, 10, 110, .4);
  border-top: 1px solid #fdcaff;

  > .box > [onsw-link] > .box {
    background: #de287c;
  }
}

.hotornot-positive {
  box-shadow: 3px 3px 5px -2px #2c0b1c;
}

.hotornot.icons > .icon:last-child * {
  fill: #b0eb2c;
  stroke: #b0eb2c;
}

[onsw-hotornot] .timer {
  background: linear-gradient(0deg, rgba(253, 202, 255, 0.3), transparent);

  > .box {
    background: linear-gradient(0deg, rgba(166, 10, 110, .5), transparent);
  }
}

[onsw-item].hotornot {
  font-weight: 600;
  color: #fdcaff;
  font-size: 26px;
  white-space: nowrap;
}

.name[onsw-item].hotornot {
  color: #fdcaff;
}

.ons-layout.textblock > .box > .ons-heading > .box {
  color: #fdcaff;
  font-weight: 600;
}


/* OTM */
.aotm-widget.name {
  font-weight: 600;
}


/* VIP */
.table1 {
  th {
    text-align: left;
    padding: 0 0 8px;
  }
}

.table1.table-lines td {
  border-top: 1px solid #c663a2;
  padding: 2px 0;
}

onsw-latest-achievements.table1 .date {
  width: 90px;
}

onsw-latest-achievements.table1 .value {
  color: var(--text-default);
  width: 90px;
  padding-right: 10px;
}

/* Pagination */
[onsw-pagination] > .box {
  color: var(--text-default);
  margin-top: 25px;

  > .ons-item {
    &.current > .box {
      background: #12c1de;
      color: var(--text-default);
    }

    &.arrow > .box {
      background: #f084f4;

      > .icon * {
        fill: white;
      }

      &:hover > .icon * {
        fill: #a60a6e !important;
      }
    }
  }
}

body.desktop [onsw-pagination] > .box > .ons-item > .box:hover {
  background: #b0eb2c;
  color: #a60a6e;
}


/* Footer */
onsw-template[template="core.footer"] > .box {
  color: white;
}

.ons-item.link.footermenu-widget > .box,
.footermenu-hook ~ p .ons-item.link > .box {
  color: var(--footer-links);

  > .label {
    text-decoration: underline;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
}

[label="core.moreInformation"].footermenu-widget {
  display: none !important;
}


/* Topic Badge */
[onsw-amateur-topic-badge] > .box {
  .label {
    filter: drop-shadow(1px 1px 1px #587900);
    font-weight: 600;
    font-size: 13px;
  }

  .icon {
    filter: drop-shadow(0px 1px 0px #587900);
  }
}


/* Notify & Error */
.box > .notify {
  border: none !important;
  background: var(--success);
  color: white;
  font-weight: 600;
  text-shadow: none;
}

.error > .box > .notify {
  background: var(--danger);
  color: white;
  font-weight: 600;
  text-shadow: none;
}

/*Actionslider*/
ons-actionslider li.onsas-online,
ons-actionslider li.onsas-online .onsas-inner {
  background: #7f0353;
}

ons-actionslider .onsas-actions {
  background: linear-gradient(to bottom, rgba(0,0,0,0) 10%, #940c40 80%);
}

ons-actionslider .onsas-button {
  background: linear-gradient(to bottom, #a60a6e 40%, #580d45 100%);
}
