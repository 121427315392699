onsw-overlay-reason.overlay-toolbar {
  position: absolute;
  z-index: 1;
  top: -10px;
  left: -10px;
  right: -10px;
  &.ng-leave {
    transition: .5s ease opacity;
    opacity: 1;
  }
  &.ng-leave-active {
    opacity: 0;
  }
  > .box {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px;
    background: var(--overlay-reason-bg);
    color: var(--overlay-reason-fg);
    font-weight: var(--overlay-reason-font-weight);
    font-size: larger;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    text-shadow: var(--overlay--reason-text-shadow);
    > .text {
      padding: 15px;
    }
    > .ons-item[click-action] > .box {
      > .icon * {
        fill: var(--overlay-reason-fg) !important;
      }
      &:hover > .icon * {
        fill: var(--overlay-reason-fg-hover) !important;
      }
    }
  }
}

:root {
  --overlay-reason-font-weight: bold;
}
