@import '../spc/global.css';

/* AFO */
:root {
  /** ### achievement */
  .achievement-package {
    --achievement-package-title-font: 600 15px var(--headline);
  }

  /** ### amateurPool */
  .amateurPool-feed-item {
    --feed-item-icon-color: #b0eb2c;
  }

  /** ### layout */
  .layout-panel {
    --panel-background: #a60a6e;
    --panel-color: var(--text-default);
    --panel-heading-font-color: white;
    --panel-heading-font: 600 14px var(--headline);
    --panel-heading-font-large: 600 21px var(--headline);
    --panel-heading-text-transform: unset;
    --panel-subheading-font: 600 15px var(--headline);
  }

  .layout-link {
    --link-font: 600 15px var(--headline);
  }

  .layout-item {
    --item-border: var(--personal-info-badge-border);
    --item-background: var(--panel-background);
    --item-background-hover: var(--sedcard-button-bg-hover);
    --item-color: #fff;
    --item-highlight-color: #fff;
    --item-heading-font: 600 15px var(--headline);
  }

  .layout-note {
    --layout-note-info-background: var(--success);
    --layout-note-info-color: white;
    --layout-note-warn-background: #f084f4;
    --layout-note-warn-color: white;
    --layout-note-error-background: var(--danger);
    --layout-note-error-color: white;
  }

  .layout-toolbar {
    --toolbar-icon-color: #fd8faf;
  }

  .layout-input-toggle {
    --toggle-bg-off: #7f0353;
    --toggle-bg-on: var(--green);
  }

  .layout-header {
    --header-bg-color: linear-gradient(187deg, #9e0078 0%, #ba1a5b 100%);
    --header-border-bottom: 1px solid transparent;
    --header-menu-border-color: rgba(255, 255, 255, 0.2);
    --header-menu-button-bg-color: transparent;
    --header-menu-button-hover-icon-color: #f084f4;

    --logo-xs-url: url(./img/logo-xs.png);
    --logo-url: url(./img/logo.png);
    --logo-max-width: 300px;

    --header-label-color: white;
    --header-label-font-size: 12px;
    --header-label-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

    &.user-header {
      --coins-button-value-bg: var(--coins);
      --coins-button-value-color: white;
      --messenger-button-value-bg: #e900e0;
      --messenger-button-value-color: white;
      --vip-button-value-bg: var(--vip);
      --vip-button-value-color: white;
      --friends-button-value-bg: var(--friend);
      --friends-button-value-color: white;
    }
  }

  /** - toolbar-list */
  --toolbar-list-font: 600 14px var(--headline);
  --toolbar-list-color-hover: #12c1de;
  --toolbar-list-background: rgba(186, 30, 96, 0.7);
  --toolbar-list-background-without-hover: rgba(186, 30, 96, 0.7);

  /** ### userpool */
  .userpool-mailing-config {
    --mailing-config-primary-color: var(--green);
    --mailing-config-primary-font: 600 16px var(--headline);
    --mailing-config-secondary-color: white;
    --mailing-config-secondary-font: 600 14px var(--headline);
    --mailing-config-warning-font: 600 14px var(--default-font);
    --mailing-config-warning-color: white;
  }
}
