[onsw-pagination] > .box {
  line-height: 40px;
  font-family: var(--headline);
  font-size: 14px;
  color: #a2a1a1;
  white-space: nowrap;
}

[onsw-pagination] > .box > .ons-item > .box {
  margin: 0 2px;
  height: 40px;
  width: unset;
  border-radius: 20px;
  border: none;
  padding: 8px 6px;
  transition: background .3s ease;
  line-height: 24px;
  vertical-align: top;
}

@media (min-width: 1024px) {
  body:not(.show-dialog):not(.regard-shop-open) [onsw-pagination] > .box > .ons-item > .box {
    min-width: 40px;
    padding: 8px;
  }
}

@media (min-width: 1280px) {
  [onsw-pagination] > .box > .ons-item > .box {
    min-width: 40px;
    padding: 8px;
  }
}

body.desktop [onsw-pagination] > .box > .ons-item > .box:hover {
  background: #444;
  color: #fff;
}

[onsw-pagination] > .box > .ons-item.current > .box {
  min-width: 40px;
  background: #f90;
  font-size: 16px;
  color: #fff;
}

[onsw-pagination] > .box > .ons-item.arrow.inactive {
  opacity: .4;
  pointer-events: none;
}

[onsw-pagination] > .box > .ons-item.arrow > .box > .label {
  display: none;
}

[onsw-pagination] > .box > .ons-item.arrow > .box {
  background: #444;
  transform: scale(.8);
}

[onsw-pagination] > .box > .ons-item.arrow > .box > .icon {
  width: 24px;
  height: 24px;
}

[onsw-pagination] > .box > .ons-item.arrow > .box > .icon * {
  fill: #a2a1a1;
}

body.desktop [onsw-pagination] > .box > .ons-item.arrow > .box:hover > .icon * {
  fill: #f90;
}

