.ons-layout.tabs > .box {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  white-space: nowrap;
  overflow: auto hidden; /* animation ? */
}

.ons-layout.tabs > .box > .ons-item.empty {
  display: none;
}

.ons-layout.tabs > .box > .ons-item > .box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.ons-layout.tabs > .box > .ons-item > .box > .label {
  text-decoration: none;
}

.ons-layout.tabs > .box > .ons-item > .box > .icon {
  width: 24px;
  height: 24px;
}

/* Sedcard */
@media (max-width: 767px) {
  .ons-item.sedcard-nav-widget > .box > .value {
    display: none;
  }
}
