/**
 * ## Layout: Panel
 *
 * The `.layout-panel` represents a generic section of the document, 
 * whose child elements should be presented visually grouped in a grid by:
 * - Spacing
 * - Borders
 * - Colors
 * - Fonts
 * It provides special treatment for some direct child elements:
 * - `.heading`
 * - `.subheading`
 * - `.layout-toolbar`
 */
/* TODO remove form.panel selector after getting rid of global .ons-form styles */
.ons-form > form.layout-panel,
.layout-panel {
  box-sizing: border-box;
  display: grid;
  align-content: start;
  grid-template-columns: minmax(0, 1fr);
  padding: var(--panel-padding);
  gap: var(--panel-gap);
  color: var(--panel-color);
  background: var(--panel-background);
  border: var(--panel-border);
  border-radius: var(--default-border-radius);

  > h1,
  > p {
    margin: unset; /* Unset user agent stylesheet. */

    /**
     * Hide empty headings and paragraphs.
     *
     * Motivated by moviePool/movie.html and picturePool/pictureSet.html,
     * because sometimes those have not any applicable text content.
     * It seems like a fine general rule though.
     */
    &:empty {
      display: none;
    }
  }

  > .heading,
  > h1 {
    font: var(--panel-heading-font);
    color: var(--panel-heading-font-color);
    text-transform: var(--panel-heading-text-transform);
    line-height: 1;
  }

  > .subheading {
    font: var(--panel-subheading-font);
    color: var(--panel-subheading-font-color, var(--panel-heading-font-color));
  }

  /**
   * Revert harmful ons-form styles.
   *
   * TODO Get rid of .ons-form and then get rid of this block.
   */
  > label {
    margin: unset;
  }

  > :last-child {
    &.layout-toolbar {
      justify-content: flex-end;
    }

    &.layout-link {
      place-self: flex-end;
    }
  }
}
