[onsw-link] > a {
  text-decoration: none;
}

[onsw-link].list-footer {
  text-align: right;
  margin-bottom: 10px;
  margin-top: 10px;
}

[onsw-link].list-footer.globalFeed {
  padding: 0 5px !important;
}
