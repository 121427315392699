/**
 * BONUS
 */
.marquee.bonus {
  line-height: 32px;
  font-size: 22px;
}

@media (min-width: 1024px) {
  .marquee.bonus {
    line-height: 50px;
    font-size: 35px;
  }
}

.marquee.bonus {
  color: rgba(0, 0, 0, 0.6);
  background: linear-gradient(to bottom, rgb(255, 223, 59) 0%,rgb(212, 127, 0) 100%);
  font-weight: bold;
  overflow: hidden;
}

.marquee.bonus > .box > span::after {
  content: ' - ';
}

.marquee.bonus > .box  {
  display: inline-block;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(-25%); }
  to { transform: translateX(-50%); }
}

