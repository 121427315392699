.ons-form.inlineFormContainer {
  position: relative;
}

@media (min-width: 450px) {
  .ons-form.inlineFormContainer > form.box {
    margin: 0 0 0 -15px;
  }

  .ons-form.inlineFormContainer > form > label {
    float: left;
    max-width: 320px;
    width: 50%;
    padding: 0 0 0 15px;
  }

  .ons-form.inlineFormContainer > form > label > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ons-form.inlineFormContainer > form > :not(label):not(.notify) {
    clear: left;
    padding: 0 0 0 15px;
  }

  .ons-form.inlineFormContainer > form > label:nth-of-type(odd) {
    clear: left;
  }
}

