a,
.ons-item.link > .box {
  text-decoration: none;
  color: white;
}

a:hover, a:active,
.ons-item.link.active > .box,
.ons-item.link > .box.active,
.ons-item.link > .box:hover {
  color: #f90;
}

a,
.ons-item.link > .box > .label {
  text-decoration: underline;
}

.layout-container.messenger .items .ons-item.link > .box,
.layout-container.messenger .items .ons-item.link > .box:hover,
.payment.ons-item.link > .box,
.payment.ons-item.link > .box:hover,
.payment .ons-item.link > .box,
.payment .ons-item.link > .box:hover,
.payment a,
.payment a:hover,
onsw-messenger-message-box .ons-item.link > .box,
onsw-messenger-message-box .ons-item.link > .box:hover {
  color: #a00;
}

