.ons-item.button > .box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  box-sizing: border-box;

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}

.ons-item.button .label .icon {
  margin-right: 5px;
}
