.fade-in {
    opacity: 0;
}
.fade-in.ready {
    opacity: 1;
    animation: ons-fade-in .5s ease;
}

@keyframes ons-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes ons-zoom-in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
