@media (min-width: 1024px) {
  body:not(.show-dialog) [hookname="sidebar"] {
    margin-bottom: -10px;
  }
}

@media (min-width: 1280px) {
  [hookname="sidebar"] {
    margin-bottom: -10px;
  }
}

[hookname="sidebar"] > .box:not(:empty) {
  padding-bottom: 10px;
}
