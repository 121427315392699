.layout-container.messenger [onsc-message-count] > .box > .value {
    background-color: #8dbd0d;
    display: inline-block;
    padding: 4px;
    min-width: 13px;
    font-size: 13px;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 50%;
    animation: blubb .5s 2 alternate ease;
}

@keyframes blubb {
  from { transform: scale(1); background-color: #8dbd0d; }
  to { transform: scale(1.4); background-color: #738e15; }
}

.flying-chat-box {
  font-size: 14px;
  color: #333;
  background: #ddd;
  border: 1px solid #d1d1d1;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
  padding: 2px 5px;
}

