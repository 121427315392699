/**
 * HAS TO BE INSIDE PANEL A.T.M. because some presets use panel-variables.
 */
.layout-field {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--input-gap, 3px);

  > .label {
    font: var(--input-label-font);
    color: var(--input-label-color, var(--panel-color));
  }
}

.layout-input {
    box-sizing: border-box;
    resize: none;
    appearance: none;
    margin: 0;
    outline-offset: var(--input-outline-offset);
    outline: var(--input-outline);
    border: var(--input-border);
    border-radius: var(--input-border-radius, var(--default-border-radius));
    background: var(--input-bg);
    padding: 5px;
    color: var(--input-color);
    font: var(--input-font);

    &:focus {
      border: var(--input-border-focus, var(--input-border));
      background: var(--input-bg-focus, var(--input-bg));
      box-shadow: var(--input-boxshadow-focus);
      color: var(--input-color-focus, var(--input-color));
    }
}
