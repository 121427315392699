@import '../layout/container.css';
@import '../layout/panel.css';
@import '../layout/input-toggle.css';

.userpool-mailing-config > .layout-container {
  &:not(.large) > .image {
    display: none;
  }

  &.large {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    place-items: stretch;

    > .image {
      aspect-ratio: 1;
      background-image: var(--unsubscribe-image-url);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  > .layout-panel.form {
    position: relative;

    > .layout-container {
      gap: var(--mailing-config-space);

      --mailing-config-space: calc(var(--panel-gap, 10px) * 2);

      > .layout-field {
        gap: 0;

        > .toggle {
          font-weight: 600;
        }

        > .desc {
          margin-left: var(--mailing-config-space);

          > .warning {
            display: flex;
            gap: 10px;
            align-items: flex-end;
            font: var(--mailing-config-warning-font);
            text-transform: uppercase;
            color: var(--mailing-config-warning-color);
            margin-bottom: 5px;

            &::before {
              content: url('../../basic/img/warning.png');
              width: 30px;
              height: 30px;
            }
          }
        }

        &.primary {
          --input-toggle-height: 30px;
          --input-toggle-width: 64px;

          > .label {
            font: var(--mailing-config-primary-font);
            color: var(--mailing-config-primary-color);
          }
        }

        &.secondary {
          > .label {
            margin-left: var(--mailing-config-space);
            font: var(--mailing-config-secondary-font);
            color: var(--mailing-config-secondary-color);
          }

          > .toggle {
            font-size: 12px;
          }
        }
      }
    }
  }
}
