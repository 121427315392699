.layout-container.layout-overlay > .core-loader {
  place-self: stretch;
  cursor: wait;
  margin: 0;
  box-shadow: none;
  z-index: -1;

  > .layout-empty-spinner {
    height: 100%;
  }
}

[onsw-loader] .box path,
[onsw-loader] .box circle {
  stroke: #88b40c;
}

[onsw-payment-payback-in-progress] > .box > [onsw-loader],
[onsw-payment-transaction-in-progress] > .box > [onsw-loader] {
  text-align: center;
}

[onsw-payment-payback-in-progress],
[onsw-payment-transaction-in-progress] {
  padding: 15px 0;
}

[onsw-payment-payback-in-progress] > .loading.box,
[onsw-payment-transaction-in-progress] > .loading.box {
  padding: 15px 10px;
}

[onsw-payment-payback-in-progress] > .box > [onsw-loader] .box,
[onsw-payment-transaction-in-progress] > .box > [onsw-loader] .box {
  width: 150px;
  height: 150px;
}

[onsw-loader].livecamLoading > .box {
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  margin: auto;
  width: 20%;
  height: 20%;
}

[onsw-loader].livecamLoading > .box path,
[onsw-loader].livecamLoading > .box circle {
  stroke: #ccc;
}
