/*@media (min-width: 1024px) {
  body:not(.show-dialog) [onsw-tile="TeaserTile"] {
    display: none;
  }
}

@media (min-width: 1280px) {
  [onsw-tile="TeaserTile"] {
    display: none;
  }
}


[onsw-tile="TeaserTile"] {
  position: relative;
  padding-top: 38%;
}

[onsw-tile="TeaserTile"] > .box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}*/
[onsw-tile="TeaserTile"] > .box {
  position: relative;
}
