/**
 * Interactable as a whole.
 */
.layout-item {
  cursor: pointer;
  display: grid;
  align-items: center;

  margin: var(--item-margin);
  outline: var(--item-outline);
  outline-offset: var(--item-outline-offset);
  padding: var(--item-padding);
  gap: var(--item-gap);
  border-radius: var(--item-border-radius);
  background: var(--item-background);
  border: var(--item-border);
  font: var(--item-font);
  color: var(--item-color);

  > .image {
    /** Fit `<img>` into the center of the grid cell. */
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    place-self: center;
  }

  > .title {
    /* The text can be cut, as long as the user will see it uncut somewhere else. */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font: var(--item-heading-font);
    color: var(--item-highlight-color);
  }

  > .text {
    margin: 0;
  }

  > .preset-overlay {
    margin: calc(-1 * var(--item-border-width));
  }

  @media (hover: hover) {
    /**
     * The `.layout-item` uses variables for the "hover" state and 
     * falls back to the normal state values.
     */
    &:hover {
      background: var(--item-background-hover, var(--item-background));
      outline: var(--item-outline-hover, var(--item-outline));
      color: var(--item-color-hover, var(--item-color));

      .title {
        color: var(--item-highlight-color-hover, var(--item-highlight-color));
      }
    }
  }

  /**
   * The "active" style overrides the "hover" style.
   *
   * That seems like a reasonable default, since the interaction promoted by "hover" state
   * normally results in the "active" state.
   *
   * TODO: Therefore, the "active" Variables should fall back first to the "hover" state values.
   */
  &.active {
    background: var(--item-background-active, var(--item-background));
    outline: var(--item-outline-active, var(--item-outline));
    color: var(--item-color-active, var(--item-color));

    .title {
      color: var(--item-highlight-color-active, var(--item-highlight-color));
    }
  }
}
