.ons-layout.dropdown > .box > * > .box {
  color: #b4b4b4;
  background: #333;
  font-family: var(--headline);
  font-size: 14px;
}

.ons-layout.dropdown > .box > * > .box.active,
.ons-layout.dropdown > .box > * > .box:hover {
  background: #f90;
  background: linear-gradient(to bottom, #f80 0%, #f90 50%, #fa0 100%);
  color: white;
}
.ons-layout.dropdown > .box > .spacer {
  border-color: black;
}

