@import url('../../../../node_modules/ons-actionslider/onsas-client.shared.css');

main footer {
  padding-bottom: 80px;
}

ons-actionslider {
  bottom: 0;
  height: 75px;
  right: 0;
  left: 0;
  white-space: nowrap;
  z-index: 2;
  overflow: hidden;
  pointer-events: none;
}

@media (max-width: 1023px) {
  ons-actionslider {
    display: none;
  }
}
@media (max-height: 767px) {
  ons-actionslider {
    display: none;
  }
}

ons-actionslider .onsas-button,
ons-actionslider li {
  pointer-events: all;
}

@media (max-width: 767px) {
  body.show-dialog ons-actionslider,
  body.show-contacts ons-actionslider,
  body.message-notification-open ons-actionslider,
  body.regard-shop-open ons-actionslider { display: none; }
}

ons-actionslider .onsas-actions {
  left: 0;
  right: 24px;
  bottom: 0;
  height: 100%;
  position: absolute;
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 10%,rgba(0,0,0,1) 80%);
}

ons-actionslider .onsas-next {
  right: 0;
}

ons-actionslider ul {
  min-width: 100%;
  right: 0;
  position: absolute;
  margin: 0;
  padding: 0;
}

ons-actionslider li {
  margin-top: 25px;
  margin-right: 6px;
  padding: 0 4px;
  box-shadow: 3px 3px 4px rgba(0,0,0,.5);
}

ons-actionslider li .onsas-outer {
  padding: 0 0 0 42px;
  height: 52px;
  background-size: 75px;
  background-repeat: no-repeat;
  background-position: -17px 4px;
  position: relative;
}

ons-actionslider li .onsas-image {
  position: absolute;
  top: 5px;
  left: 0;
  height: 40px;
  width: 40px;
  background-position: center;
  background-size: cover;
}

ons-actionslider li .onsas-inner {
  height: 52px;
  padding: 10px 20px 10px 5px;
  font-weight: bold;
}

ons-actionslider li .onsas-title, ons-actionslider li .onsas-message {
  display: block;
}

ons-actionslider li .onsas-title {
  font-size: 12px;
}

ons-actionslider li .onsas-title {
  line-height: 14px;
  font-size: 14px;
}

/***********/
/* Buttons */
/***********/

ons-actionslider .onsas-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 24px;
  height: 50px;
  padding: 0;
  margin: 25px 0 0;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: opacity 0.2s;
  background: linear-gradient(to bottom, #9e9e9e 0%, #1e1e1e 100%);
  z-index: 2;
  display: none;
}

ons-actionslider .onsas-first, ons-actionslider .onsas-last { display: none }
ons-actionslider:not(.onsas-first-reached) .onsas-prev { display: block; }
ons-actionslider:not(.onsas-last-reached) .onsas-next { display: block; }

ons-actionslider .icon {
  height: 20px;
  width: 24px;
  margin-top: 15px;
}

ons-actionslider .icon * {
  fill: white;
}

ons-actionslider .onsas-first {
  left: 0;
}

ons-actionslider .onsas-first:after {
  background-position: 0 0;
}

ons-actionslider .onsas-prev {
  left: 0;
}

ons-actionslider .onsas-prev:after {
  background-position: -20px 0;
}

ons-actionslider .onsas-next:after {
  background-position: -40px 0;
}

ons-actionslider .onsas-last {
  right: 0;
}

ons-actionslider .onsas-last:after {
  background-position: -60px 0;
}

/*************/
/* Animation */
/*************/

ons-actionslider ul {
  transition: all 0.2s ease-out;
}

ons-actionslider li.ng-enter {
  animation-duration: .5s;
  animation-timing-function: cubic-bezier(0, .33, .25, 1.33);
  animation-name: onsASWobble;
}

ons-actionslider li.ng-leave {
  display: none;
  width: 0;
}

@keyframes onsASWobble {
  0% {
    opacity: 1;
    margin-left: 0;
    margin-right: -150px;
    transform: translate(500% , 0);
  }
  45% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    margin-left: 5px;
    margin-right: 0;
    transform: translate(0, 0);
  }
}

@media (min-width: 768px) {
  ons-actionslider .onsas-actions {
    right: 300px;
  }
}

@media (min-width: 1024px) {
  body.show-dialog ons-actionslider .onsas-actions,
  body.regard-shop-open ons-actionslider .onsas-actions {
    right: 620px;
  }
}
