[onsw-amateur-nickname-search].ons-form.inline > .box > .buttons > .box > .submit > .box {
  width: 45px;
}

[onsw-amateur-nickname-search].ons-form.inline > .box > .buttons > .box > .cancel > .box {
  position: absolute;
  left: 143px;
  background: none;
  border: none;
  padding: 0;
  font-weight: bold;
  font-size: 27px;
  line-height: 30px;
  color: #888;
  box-shadow: none;
}

[onsw-amateur-nickname-search].ons-form.inline > .box > .buttons > .box > .cancel > .box:hover {
  color: black;
}

[onsw-amateur-nickname-search].ons-form.inline > .box > .buttons > .box > .cancel > .box > .label {
  display: none;
}

[onsw-amateur-nickname-search].ons-form.inline > .box > .buttons > .box > .cancel > .box::before {
  content: '\00D7';
}

[onsw-amateur-nickname-search].ons-form.inline.toggle {
  display: none;
  position: absolute;
  left: 110px;
  transform: translateX(-100%);
  top: 2px;
  z-index: 1;
  animation: show-searchbox .5s ease forwards;
}

[onsw-amateur-nickname-search].ons-form.inline.toggle > .box > .buttons > .box > .cancel > .box {
  animation: show-searchbox-x .5s linear;
}

@media (min-width: 480px) {
  [onsw-amateur-nickname-search].ons-form.inline.toggle {
    left: -10px;
  }
}

[onsw-toggle-button].active + [onsw-amateur-nickname-search].ons-form.inline.toggle {
  display: inline-table;
}

@keyframes show-searchbox-x {
  from {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes show-searchbox {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 210px;
    opacity: 1;
  }
}

[onsw-toggle-button] + [onsw-amateur-nickname-search].ons-form.inline.toggle > .box {
  box-shadow: 2px 2px 10px black;
}
