.ons-scroll > .box::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.mobile .ons-scroll > .scroll-overlay > .bar,
.mobile .ons-scroll > .scroll-overlay > .bar-x,
.ons-scroll.scroll-void > .scroll-overlay > .bar,
.ons-scroll.scroll-void-x > .scroll-overlay > .bar-x {
    display: none;
}

.ons-scroll > .scroll-overlay > .left,
.ons-scroll > .scroll-overlay > .right,
.ons-scroll > .scroll-overlay > .page-down,
.ons-scroll > .scroll-overlay > .scroll-top {
  position: absolute;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
}

.ons-scroll > .scroll-overlay > .left,
.ons-scroll > .scroll-overlay > .right {
  top: 0;
  bottom: 0;
  /*background: red;*/
}
.ons-scroll > .scroll-overlay > .left > .icon,
.ons-scroll > .scroll-overlay > .right > .icon {
  height: 100%;
}
.ons-scroll > .scroll-overlay > .right {
  right: 0;
}
.ons-scroll > .scroll-overlay > .left {
  left: 0;
}

.ons-scroll:not(.scroll-max-x) > .scroll-overlay > .right,
.ons-scroll:not(.scroll-min-x) > .scroll-overlay > .left,
.ons-scroll.btn-page-down:not(.scroll-max) > .scroll-overlay > .page-down,
.ons-scroll.btn-scroll-top:not(.scroll-min) > .scroll-overlay > .scroll-top {
    opacity: 1;
    pointer-events: all;
}

main.ons-scroll > .scroll-overlay > .scroll-top {
    position: absolute;
    bottom: 60px;
    top: auto;
    display: inline;
    left: 10px;
    z-index: 10;
    background: #2b2b2b;
    padding: 10px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.7);
}

main.ons-scroll > .scroll-overlay > .scroll-top > .icon {
  vertical-align: top;
  height: 20px;
  width: 20px;
}

.ons-scroll {
    position: relative;
  /*overflow: hidden; /* this made the browser think it could scroll here around */
}

.ons-scroll > .box {
  overflow: auto;
  scrollbar-width: none;
}

.ons-scroll > .scroll-overlay > .bar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 5px;
}

.ons-scroll > .scroll-overlay > .bar-x {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 5px;
}

.ons-scroll > .scroll-overlay > .bar > .slider {
    position: absolute;
    background-color: black;
    width: 100%;
    top: 0;
    bottom: 0;
}

.ons-scroll > .scroll-overlay > .bar-x > .slider-x {
    position: absolute;
    background-color: black;
    height: 100%;
    left: 0;
    right: 0;
}

@media (min-width: 768px) {
    .ons-scroll[hookname="main"] > .scroll-overlay > .bar {
        right: 1px;
        width: 5px;
    }
}

.amateurPool-sedcard-image,
.myportal2-box {
  .ons-scroll > .scroll-overlay {
    > .bar,
    > .bar-x {
      display: none !important;
    }

    > .scroll-top {
      top: 0;
      left: 0;
      right: 0;
    }

    > .page-down {
      bottom: 0;
      left: 0;
      right: 0;
    }

    > .scroll-top,
    > .page-down,
    > .left,
    > .right {
      width: 36px;
      display: inline-flex;
      align-items: center;

      &.scroll-top,
      &.page-down {
        width: unset;
        height: 36px;
      }

      .icon {
        height: 24px;
        width: 24px;
        stroke-width: 4px;
        fill: var(--portal-scroll-icon-color);
        stroke: var(--portal-scroll-icon-color);
      }

      &:hover .icon {
        fill: var(--portal-scroll-icon-hover-color);
        stroke: var(--portal-scroll-icon-hover-color);
      }
    }

    > .left {
      justify-content: flex-start;
      background: linear-gradient(to right, var(--portal-scroll-bg), transparent);
    }

    > .right {
      justify-content: flex-end;
      background: linear-gradient(to left, var(--portal-scroll-bg), transparent);
    }

    > .scroll-top {
      justify-content: center;
      background: linear-gradient(to bottom, var(--portal-scroll-bg), transparent);
    }

    > .page-down {
      justify-content: center;
      background: linear-gradient(to top, var(--portal-scroll-bg), transparent);
    }
  }

  &.amx-dst {
    .ons-scroll > .scroll-overlay {
      > .right {
        border-radius: 0 var(--default-border-radius) var(--default-border-radius) 0;
      }

      > .left {
        border-radius: var(--default-border-radius) 0 0 var(--default-border-radius);
      }
    }
  }
}
