
.columns.pad6:not(.frame) > .box > .ons-heading {
  margin-bottom: -6px;
}

.columns.pad6.frame > .box > .ons-heading:not(:first-child) {
  margin-top: 6px;
}

.columns.pad10:not(.frame) > .box > .ons-heading {
  margin-bottom: -10px;
}

.columns.pad10.frame > .box > .ons-heading:not(:first-child) {
  margin-top: 10px;
}

.columns.pad30:not(.frame) > .box > .ons-heading {
  margin-bottom: -20px;
}

.columns.pad30.frame > .box > .ons-heading:not(:first-child) {
  margin-top: 30px;
}

.ons-heading.default.small-feed-widget > .box {
  margin: 5px 0 8px;
  text-transform: none;
  font-size: 15px;
}
