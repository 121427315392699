@import '../layout/item.css';
/**
 * [achievement-package.html](../../html/achievement/package.html)
 */
.layout-item.achievement-package {
  grid-template-columns: var(--achievement-package-icon-size, 60px) auto min-content;
  padding: var(--achievement-package-padding, 10px);
  gap: var(--achievement-package-gap, 0 10px);

  > svg {
    grid-area: 1 / 1 / 3 / 2;
    transform: scale(0.8);
    transform-origin: top;

    > g:first-child {
      fill: var(--achievement-package-icon-color, var(--item-color, var(--panel-color)));
    }
  }

  > .title {
    font: var(--achievement-package-title-font, var(--item-heading-font));
  }

  > input {
    grid-area: 1 / 3 / 3 / 4;
  }
}

@property --achievement-package-gap {
  syntax: '<length-percentage> | <length-percentage>+';
  inherits: true;
  initial-value: 0 10px;
}

@property --achievement-package-padding {
  syntax: '<length-percentage> | <length-percentage>+';
  inherits: true;
  initial-value: 10px;
}
