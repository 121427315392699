@import '../user/header.css';
@import '../guest/header.css';

.layout-header {
  --header-icon-size: 30px;

  background: var(--header-bg-color);
  border-bottom: var(--header-border-bottom);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  user-select: none;

  @media (max-width: 1279px) {
    z-index: 2;
  }
  @media (min-width: 1280px) {
    height: 80px;
  }

  @media (max-height: 450px) and (max-width: 1279px) {
    body.show-dialog & {
      z-index: unset;
    }
  }

  @media (min-width: 1280px) {
    body.show-contacts & {
      right: 300px;
    }

    body.regard-shop-open &,
    body.show-dialog & {
      right: 620px;
    }
  }

  > .menu-button {
    display: contents;

    > .icon {
      flex: 0 0 var(--header-icon-size);
      cursor: pointer;
      padding: 5px;
      margin: 0 10px;
      height: var(--header-icon-size);
      width: var(--header-icon-size);
      background: var(--header-menu-button-bg-color);
      fill: var(--header-menu-button-icon-color);

      &:hover {
        fill: var(--header-menu-button-hover-icon-color);
      }
    }
  }

  > .logo {
    flex: 1 1 50px;
    align-self: stretch;
    margin: 5px auto 5px 5px;
    background: left/contain var(--logo-xs-url) no-repeat;
  }

  &.small .button {
    > .icon {
      padding: 0 5px;
    }

    > .value {
      right: 0;
    }
  }

  &:not(.small) {
    > .logo {
      background-image: var(--logo-url);
      max-width: var(--logo-max-width);
    }
  }

  &.large .button {
    padding: 0 20px;
    border-left: 1px solid var(--header-menu-border-color);

    > .label {
      display: inline;
    }

    > .value {
      right: 15px;
    }
  }

  .button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    position: relative;
    text-decoration: none;

    @media (min-width: 1280px) {
      gap: 6px;
    }

    > .icon {
      padding: 0 10px;
      height: var(--header-icon-size);
      width: var(--header-icon-size);
      fill: var(--header-label-color);
    }

    > .label {
      display: none;
      font-size: var(--header-label-font-size);
      font-family: var(--header-label-font-family);
      color: var(--header-label-color);
      text-shadow: var(--header-label-text-shadow);
    }

    > .value {
      position: absolute;
      top: -5px;
      right: 0px;
      font-size: 12px;
      line-height: 1;
      font-weight: 600;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      padding: 2px 4px;
      border-radius: var(--default-border-radius);
      font-family: var(--default-font);

      @media (min-width: 1024px) and (max-width: 1279px) {
        top: 0;
      }
    }

    &.layout-empty > .value {
      display: none;
    }

    &.search {
      border-left: none;
    }

    &.games > .value {
      background: var(--games-button-value-bg);
      color: var(--games-button-value-color);
    }

    &.coins > .value {
      background: var(--coins-button-value-bg);
      color: var(--coins-button-value-color);
    }

    &.messenger {
      transition: transform 0.3s ease-out;

      &.layout-filled > .icon {
        animation: flash 2s ease infinite;
      }

      &.layout-wumper {
        transform: scale(2);
      }

      > .value {
        background: var(--messenger-button-value-bg);
        color: var(--messenger-button-value-color);
      }

      @media (min-width: 768px) {
        display: none;
      }
    }

    &.cams > .value {
      background: var(--cams-button-value-bg);
      color: var(--cams-button-value-color);
    }

    &.vip > .value {
      background: var(--vip-button-value-bg);
      color: var(--vip-button-value-color);
    }

    &.friends > .value {
      background: var(--friends-button-value-bg);
      color: var(--friends-button-value-color);
    }

    &.vip.button,
    &.games.button {
      transition: transform .3s ease-in;

      &.layout-wumper {
        transform: scale(1.2);
        transition-timing-function: ease-out;
      }

      > .gain {
        /* https://www.w3.org/TR/css-flexbox-1/#abspos-items */
        position: absolute;
        pointer-events: none;
        z-index: 100;
        animation: 'header-fly-wobble' 2s ease-in-out;
        display: flex;
        line-height: var(--header-icon-size);
        font-size: 20px;
        font-weight: bold;
        color: white;
        text-shadow: 0 .2px 0 #cccccc, 0 .3px 0 #cccccc, 0 .4px 0 #cccccc, 0 .5px 0 #cccccc, 0 .6px 0 #cccccc, 0 .7px 0 #cccccc, 0 .8px 0 #cccccc, 0 .9px 0 #cccccc, 0 1px 0 #cccccc, 0 .2px .2px rgba(0, 0, 0, 0.9);
        will-change: transform, opacity;

        > .icon {
          width: var(--header-icon-size);
          height: var(--header-icon-size);
        }
      }
    }
  }
}

@keyframes flash {
  0%,
  100%,
  50% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.3;
  }
}

@keyframes header-fly-wobble {
  from {
    opacity: 0;
    transform: translateY(0) scale(1, 1);
  }
  40% {
    opacity: .8;
    transform: translateY(30vh) scale(10, 10);
  }
  45% {
    transform: translateY(30vh) scale(11.25, 13.75);
  }
  50% {
    transform: translateY(30vh) scale(13.75, 11.25);
  }
  55% {
    transform: translateY(30vh) scale(10.75, 14.25);
  }
  60% {
    transform: translateY(30vh) scale(14.75, 10.25);
  }
  65% {
    transform: translateY(30vh) scale(10.25, 14.75);
  }
  70% {
    opacity: 0.8;
    transform: translateY(30vh) scale(10, 10);
  }
  to {
    opacity: 0;
    transform: translateY(0) scale(1, 1);
  }
}
