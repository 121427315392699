.myportal2-hook {
  --default-gap: 10px;
  .myportal2-box {
    &.amx-dst {
      --default-gap: 10px 20px;

      .ons-layout.group > .box {
        padding: 0;
        border: 0;
        background-color: transparent;

        .ons-item.button.list-footer {
          margin-top: 5px;
        }
      }

      > .bottom > .box {
        gap: var(--default-gap);
      }

      &.dst {
        .ons-heading.default > .box {
          display: flex;
          align-items: center;
          font-family: var(--headline);
          font-weight: initial;
        }
      }

      .ons-heading.default > .box {
        margin-bottom: 0 !important;
        font-family: var(--portal-heading-box-font-family);
        font-weight: var(--portal-heading-box-font-weight);
        border-top: 1px solid var(--portal-heading-box-border-color);
        font-size: 18px;
        border-radius: 0;
        gap: 6px;

        @media (min-width: 1700px) {
          font-size: 21px;
        }

        .icon {
          width: 30px;
          height: 30px;
          flex-shrink: 0;

          > * {
            fill: var(--portal-heading-box-icon-color);
          }
        }
      }

      .hotornot-teaser-hook {
        .hotornot-teaser-box {
          background-position: 10% 10%, 0 0;

          .preview > .title {
            @media (min-width: 1600px) {
              font-size: 21px;
            }
          }
        }
      }
    }

    display: grid;
    justify-items: stretch;
    grid-template-columns: auto;
    gap: var(--default-gap);

    @media (min-width: 1280px) {
      grid-template-columns: minmax(350px, 60%) minmax(120px, auto);

      > .cams,
      > .movies {
        grid-column: 1 / 3;
      }

      .dates_mobile,
      .hotornot_mobile,
      .visits_mobile {
        display: none;
      }
    }

    .ons-heading.default > .box {
      background: var(--portal-heading-box-bg);
      color: var(--portal-heading-box-color);
      padding: var(--portal-heading-box-padding);
    }

    onsw-amateur-otm-shuffle {
      height: 250px;

      @media (min-width: 1280px) {
        height: unset;
        grid-row: 2;
        grid-column: 2 / 3;
      }
    }

    > .bottom {
      @media (min-width: 1280px) {
        grid-column: 1 / 3;
      }

      > .box {
        display: grid;
        justify-items: stretch;
        grid-template-columns: auto;
        gap: var(--default-gap);

        @media (min-width: 1280px) {
          grid-template-columns: minmax(50%, auto) minmax(250px, 500px);
        }

        > .right,
        > .dates_desktop {
          display: none;
        }

        > .right > .box {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        @media (min-width: 1280px) {
          > .right {
            display: block;
            grid-row: 1 / 4;
            grid-column: 2 / 3;
          }

          > .dates_desktop {
            display: block;
          }
        }
      }
    }

    .search .img-box {
      display: block;

      .image {
        display: block;
        width: 100%;
        border-radius: var(--default-border-radius);
      }
    }

    .hotornot-teaser-hook {
      .hotornot-teaser-box {
        position: relative;
        background-image: var(--hotornot-teaser-logo), var(--hotornot-teaser-bg-url);
        padding-top: 75%;
        background-repeat: no-repeat;
        background-position: left 0, center 0;
        background-size: var(--hotornot-teaser-logo-size), cover;
        width: auto;
        cursor: pointer;
        border-radius: var(--default-border-radius);

        .preview {
          width: 100%;
          text-align: center;
          position: absolute;
          bottom: 15px;
          left: 0;

          .previewimages {
            margin-top: 10px;
            height: 70px;
            overflow: hidden;

            .imgBox {
              height: 70px;
              width: 70px;
              display: inline-block;
              vertical-align: top;
              margin: 0 6px;
              cursor: pointer;
              background-color: white;
              box-shadow: var(--hotornot-teaser-imgBox-boxshadow);
              border: var(--hotornot-teaser-imgBox-border);

              .ons-image > .box {
                padding-top: 100%;
              }
            }
          }

          > .title {
            font-family: var(--default-font);
            color: #fff;
            font-size: 21px;
            text-shadow: 1px 1px 3px #000;
            display: none;

            @media (min-width: 468px) and (max-width: 767px) {
              font-size: 24px;
              display: block;
            }

            @media (min-width: 768px) and (max-width: 1023px) {
              display: block;
            }

            @media (min-width: 1024px) and (max-width: 1599px) {
              display: none;
            }

            @media (min-width: 1600px) {
              display: block;
              font-size: 24px;
            }
          }
        }

        .ons-layout.buttons {
          text-align: center;
          position: relative;
          top: 8px;

          .ons-item.button > .box {
            background: var(--hotornot-teaser-button-bg);
            text-shadow: var(--hotornot-teaser-button-text-shadow);
            border: var(--hotornot-teaser-button-border);
            box-shadow: var(--hotornot-teaser-button-boxshadow);

            &:hover {
              background: var(--hotornot-teaser-button-hover-bg);
              border: var(--hotornot-teaser-button-hover-border);
            }
          }
        }

        .new {
          background: var(--hotornot-teaser-new-url) no-repeat;
          background-size: contain;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 40%;
        }
      }
    }
  }

  .image.games-teaser-home-additional {
    display: block;
    width: 100%;
  }
}

body:not(.show-dialog) .myportal2-hook {
  .myportal2-box {
    @media (min-width: 1024px) {
      grid-template-columns: minmax(350px, 60%) minmax(120px, auto);

      > .bottom,
      .cams,
      .movies {
        grid-column: 1 / 3;
      }

      > .bottom > .box {
        grid-template-columns: minmax(50%, auto) minmax(300px, 500px);

        > .right {
          display: block;
          grid-row: 1 / 4;
          grid-column: 2 / 3;
        }
      }

      .dates_desktop {
        display: block;
      }

      .dates_mobile,
      .hotornot_mobile,
      .visits_mobile {
        display: none;
      }

      onsw-amateur-otm-shuffle {
        height: unset;
        grid-row: 2;
        grid-column: 2 / 3;
      }
    }

    @media (min-width: 1280px) {
      .hotornot-teaser-box > .preview > .title {
        display: block;
      }
    }
  }
}

body.show-dialog .myportal2-hook {
  .myportal2-box {
    &.amx-dst {
      .ons-heading.default > .box {
        @media (min-width: 1280px) and (max-width: 1399px) {
          font-size: 16px;
        }
        &.dst {
          @media (min-width: 1700px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

:lang(en) {
  .myportal2-hook .myportal2-box .hotornot-teaser-hook {
    .hotornot-teaser-box {
      background-image: var(--hotornot-teaser-logo-en), var(--hotornot-teaser-bg-url);
      .new {
        background: var(--hotornot-teaser-new-url-en) no-repeat;
        background-size: contain;
      }
    }
  }
}

.myportal2-hook {
  --warp-gap: 2px;
  --warp-aspect-ratio-fallback: 100%;

  .cams {
    --warp-height: 100px;
    --warp-width: 100px;
  }

  .movies,
  .feed {
    --warp-height: 80px;
    --warp-width: 260px;
  }

  .youknow {
    --warp-row-limit: 4;
    --warp-min-width: 180px;

    @media (min-width: 1800px) {
      --warp-min-width: 200px;
    }
  }

  .visits_mobile,
  .visits_desktop {
    --warp-row-limit: 3;
    --warp-column-limit: 3;
    --warp-min-width: 120px;
  }

  .dates_mobile,
  .dates_desktop {
    --warp-min-width: 105px;
    --warp-row-limit: 2;
    --warp-column-limit: 4;

    @media (min-width: 1800px) {
      .warp-grid.warp-column-limit {
        --warp-min-width: 200px;
        --warp-column-limit: Infinity;
      }
    }

    article:nth-child(-n + 1) {
      grid-column-end: span 2;
      grid-row-end: span 2;
    }
  }
}
