@media (max-width: 767px){
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-sm {
    display: none !important;
  }
}

  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-min-sm,
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .last-min-sm ~ * {
    display: none !important;
  }

@media (max-width: 767px) {
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-max-sm,
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .last-max-sm ~ * {
    display: none!important;
  }
}

@media (min-width: 767px) {
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-min-md,
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .last-min-md ~ * {
    display: none!important;
  }
}

@media (min-width: 768px) and (max-width: 1023px){
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-md {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-max-md,
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .last-max-md ~ * {
    display: none!important;
  }
}

@media (min-width: 1024px) {
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-min-lg,
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .last-min-lg ~ * {
    display: none!important;
  }
}

@media (min-width: 1024px) and (max-width: 1279px){
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-lg {
    display: none !important;
  }
}

@media (max-width: 1279px) {
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-max-lg,
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .last-max-lg ~ * {
    display: none!important;
  }
}

@media (min-width: 1280px) {
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-xl,
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .hide-min-xl,
  body:not(.show-contacts):not(.show-dialog):not(.regard-shop-open) .ons-layout > .box > .last-min-xl ~ * {
    display: none!important;
  }
}
