.ons-item[onsw-open-overlay] > .box,
.ons-item[\:open-overlay] > .box,
.ons-item[click-action] > .box {
  cursor: pointer;
}
.ons-item.btn > .box {
    cursor: pointer;
}
.ons-item > .box {
  &.layout-wait {
    cursor: wait !important; /* TODO clean up basic item styles */
  }
  &.layout-disabled {
    /*cursor: not-allowed !important; /* TODO clean up basic item styles */
    pointer-events: none;
    filter: blur(2px);
  }
}
.ons-item.value > .box > .label:after {
    content: ': ';
}
.ons-item.btn.full-width > .box {
  display: block;
  text-align: center;
}
.ons-item.value-bracket > .box > .value::before { content: ' ('; }
.ons-item.value-bracket > .box > .value::after { content: ')'; }

.ons-item.link > .box {
  cursor: pointer;
}

.ons-item.link > .box:focus {
  outline: none;
}

/*
.contacts > .items > .box > .ons-item {
  text-align: center;
  padding: 0 10px;
}
*/

.ons-item.backLink {
  margin: 10px 0;
  font-weight: 600;
}
.ons-item.backLink > .box > .label::before {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  margin: -10px 3px 0 -2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 11px solid #a00;
  transform: scale(0.6);
  transform-origin: 50% 130%;
}

.media-hook .icon.gallery *,
.media-hook .icon.play *,
.media-hook .icon.amateur *,
.media-hook .icon.movie * {
  fill: #fff;
}

.pageLogin .link {
  display: inline-block;
  margin-top: 6px;
}
.pageLogin .link:nth-child(odd)::after {
  content: '|';
  margin-left: 5px;
}

.ons-item.hotornotBannerClose {
  position: relative;
  padding: 0 !important;
  display: block !important;
}

.ons-item.hotornotBannerClose > .box {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 10px;
}

.ons-item.hotornotBannerClose > .box > .icon * {
  fill: white;
}

[onsw-livecam-button].normal > .box > .icon *,
.ons-item.small-livecam.normal > .box > .icon *,
.ons-item.small-livecam > .box {
  fill: var(--livecam-icon-normal);
}

[onsw-livecam-button].normal.sedcardImage > .box > .icon * {
  fill: var(--livecam-icon-sedcard-image-normal);
}

[onsw-livecam-button].voyeur > .box > .icon *,
.ons-item.small-livecam.voyeur > .box > .icon * {
  fill: var(--livecam-icon-voyeur);
}

[onsw-livecam-button].voyeur.sedcardImage > .box > .icon * {
  fill: var(--livecam-icon-sedcard-image-voyeur);
}

[onsw-livecam-button].exclusive > .box > .icon *,
.ons-item.small-livecam.exclusive > .box > .icon * {
  fill: var(--livecam-icon-exclusive);
}

[onsw-livecam-button].exclusive.sedcardImage > .box > .icon * {
  fill: var(--livecam-icon-sedcard-image-exclusive);
}

[onsw-livecam-button].normal.has-toy > .box > .icon *,
[onsw-livecam-button].normal.has-toy.sedcardImage > .box > .icon *,
[onsw-livecam-button].exclusive.has-toy > .box > .icon *,
[onsw-livecam-button].exclusive.has-toy.sedcardImage > .box > .icon * {
  fill: #ff379a;
}
