.layout-overlay [onsw-attachments] .attachment {
  background: #333;
}

[onsw-attachments] .attachment {
  background: #444;
}

[onsw-attachments] .attachment:hover {
  background: #555;
}

[onsw-attachments] .attachment.locked {
  color: #c00;
}

[onsw-attachments] .delete > .icon {
  fill: #666;
}

[onsw-attachments] .delete:hover > .icon * {
  fill: black;
}
.ons-layout.columns.pad10 > .box > .profileAttachments > h1 {
  padding: 0 0 10px;
}

