onsw-game-user-stats {
  display: flex;
  justify-content: space-evenly;
  gap: 6px;

  > div {
    flex-basis: calc(16.8% - 6px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: var(--button-border-radius);
    padding: 5px;

    .label {
      font-size: 12px;
    }

    .value {
      font: var(--game-headline);
    }

    &.victory {
      background: var(--game-inviteBox-bg);

      .value {
        font-size: 18px;
        color: var(--games-session-win-color);
      }
    }

    &.match {
      background: var(--game-inviteBox-bg);

      .value {
        font-size: 18px;
        color: var(--game-inviteBox-border-color);
      }
    }
  }
}
