@import '../layout/text.css';
@import '../layout/toolbar.css';
@import '../layout/link.css';

/**
 * The `.preset-submenu` contains a list of rather simple `.layout-link` without icons or border 
 * arranged as "column" inside a `.layout-toolbar`.
 *
 * It is intended to be used within a `.layout-panel` of which some variables are used.
 *
 * If inside a `.layout-panel` the `--panel-padding` is compensated by a negative margin to save space.
 * CARE: This leads to a problem if the `--panel-padding` is greater than the `--panel-gap`.
 */

.preset-submenu {
  --submenu-color: var(--submenu-item-label-color);
  --submenu-font: var(--submenu-item-font);
  --submenu-padding: var(--panel-padding) var(--menu-item-padding);
  --submenu-text-transform: var(--submenu-item-text-transform);
  --submenu-color-hover: var(--submenu-item-label-color-hover);
  --submenu-bg-hover: var(--submenu-item-bg-hover);
  --submenu-bg-active: var(--submenu-item-bg-active);
  --submenu-color-active: var(--submenu-item-label-color-active);

  > .layout-toolbar {
    --toolbar-margin: calc(-1 * var(--panel-padding));
    --toolbar-direction: column;
    --toolbar-gap: 0;

    > .layout-text {
      --text-padding: var(--panel-padding);
    }

    > .layout-link {
      --link-color: var(--submenu-color);
      --link-font: var(--submenu-font);
      --link-padding: var(--submenu-padding);
      border: none;
      outline: none;
      --link-text-transform: var(--submenu-text-transform);
      --link-color-hover: var(--submenu-color-hover);
      --link-background-hover: var(--submenu-bg-hover);
      --link-background-active: var(--submenu-bg-active);
      --link-color-active: var(--submenu-color-active);
    }
  }
}
