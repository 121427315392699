.amateurPool-selector-by-zip {
  background: var(--zip-selector-background, none);
  border: var(--default-border);
  border-radius: var(--default-border-radius);

  a {
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    border: 0;

    &:hover .land {
      fill: var(--zip-selector-fill-hover);
    }
  }

  text {
    pointer-events: none;
    font: var(--zip-selector-font);
    fill: var(--zip-selector-text-color, #fff);
  }

  .stop1 {
    stop-color: var(--zip-selector-gradient-color1, #8a8a8a);
  }

  .stop2 {
    stop-color: var(--zip-selector-gradient-color2, #5a5a5a);
  }

  .stop3 {
    stop-color: var(--zip-selector-gradient-color3, rgb(138, 138, 138));
  }

  .stop4 {
    stop-color: var(--zip-selector-gradient-color4, rgb(70, 70, 70));
  }
}
