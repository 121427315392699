/**
### List

#### TODO
Backport `.layout-list` to **@acng/frontend-discovery**.

#### History
- 2024-01-17
    Currently ACNG uses the `.warp-*` CSS classes from the **@acng/frontend-discovery** package as generic list classes.
*/
.layout-list {
  display: grid;
  grid-auto-rows: minmax(min-content, auto);
  grid-auto-columns: minmax(min-content, auto);
  align-content: start;
  gap: var(--list-gap);
  padding: var(--list-padding);
  margin: var(--list-margin);
  border: var(--list-border);
}
