/**
 * @see [Documentation](../../js/layout/style/teaser.js)
 */

.nova-teaser {
  border-radius: var(--teaser-border-radius, var(--default-border-radius));

  .image {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .nova-countdown {
    /* TODO(1) */
    pointer-events: none;
    position: absolute;
    right: 6%;
    bottom: 1%;
    width: 30%;
    font-weight: 700;
    font-size: 34px;
  }

  .sidebar-box & .nova-countdown {
    bottom: 8.5%;
    left: 10%;
    right: 10%;
    width: 80%;
  }
}
