[onsw-payment-progress] {
    position: relative;
}
[onsw-payment-progress] .bar {
    position:absolute;
    top: 15px;
    height: 10px;
    left: 16.665%;
    right: 16.665%;
}
[onsw-payment-progress] .bar div {
    background: silver;
    height: 100%;
    transition: 0.3s width ease-out;
}
[onsw-payment-progress] ul {
    position: relative;
    padding: 0;
    margin: 0;
}
[onsw-payment-progress] li {
    display: inline-block;
    width: 33.33%;
}
[onsw-payment-progress] li div {
    display: block;
    width: 40px;
    line-height: 38px;
    border: 1px solid silver;
    margin: auto;
    font-weight: bold;
    font-size: large;
    text-align: center;
    background: white;
    border-radius: 20px;
}
[onsw-payment-progress] li.active div {
    border-color: black;
}
[onsw-payment-progress] li p {
    margin: 0;
    text-align: center;
}
@media (max-width: 480px) {
    [onsw-payment-progress] li p {
        display: none;
    }
}

[onsw-payment-progress] li.finalized div::before {
    content: "\2713" !important;
}

[onsw-payment-progress].step {
    margin: 20px auto;
}
@media (min-width: 1024px) {
    [onsw-payment-progress].step {
        width: 65%;
        margin: 50px auto;
    }
}
[onsw-payment-progress].step .bar {
    position:absolute;
    top: 15px;
    height: 10px;
    left: 16.665%;
    right: 16.665%;
    background: #e8e8e8;
    box-shadow: 0 6px 6px -5px #ccc9c9 inset;
}
[onsw-payment-progress].step .bar div {
    background: red;
    box-shadow: 0 6px 6px -5px #5d5959  inset;
    height: 100%;
    transition: 0.3s width ease-out;
}
[onsw-payment-progress].step ul {
    position: relative;
    padding: 0;
    margin: 0;
}
[onsw-payment-progress].step li {
    display: inline-block;
    width: 33.33%;
}
[onsw-payment-progress].step li div {
    background: #e8e8e8;
    box-shadow: 0 6px 6px -5px #ccc9c9 inset;
    border: 3px solid #e4e4e4;
    line-height: 34px;
    color: #868686;
    text-shadow: 0 0 2px #fff;
}
[onsw-payment-progress].step li.active div,
[onsw-payment-progress].step li.finalized div {
    border-color: #e4e4e4;
    background: red;
    box-shadow: 0 6px 6px -5px #5d5959  inset;
    color: #fff;
}
[onsw-payment-progress].step li p {
    margin: 0;
    text-align: center;
}
[onsw-payment-progress].step li.finalized div::before {
    color: #fff;
}
[onsw-payment-progress].step li.active p {
    color:red;
}

