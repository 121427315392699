[onsw-hook].welcomeGuest {
  position: relative;
  width: 50%;
  float: left;
  overflow: hidden;
  padding: 0 5px 10px;
  padding-top: calc((50% - 10px) * 2 * 0.56);
}
[onsw-hook].welcomeGuest form > label:nth-of-type(3) {
  margin-top: -22px;
}
[onsw-hook].welcomeGuest > div {
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  padding: 10px;
  margin: 0 5px;
  background: #535353;
}
[onsw-hook].welcomeGuest label > span {
  display: none!important;
}

[onsw-hook].welcomeGuest [ons-open-overlay] {
  cursor: pointer;
  color: #ffbd2f;
}
[onsw-hook].welcomeGuest form > button {
  margin: 10px 0 0 !important;
  width: 100%;
}
[onsw-hook].welcomeGuest p {
  color: #aaa;
  font-size: 12px;
  margin-top: 15px;
}


@media (max-width: 400px) {
  [onsw-hook].welcomeGuest {
    width: 100%;
    padding-top: 85%;
    margin-bottom: 10px;
  }
}
@media (min-width: 401px) and (max-width: 559px) {
  [onsw-hook].welcomeGuest {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 949px) {
  [onsw-hook].welcomeGuest {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1599px) {
  [onsw-hook].welcomeGuest {
    display: none;
  }
}
@media (min-width: 1600px) {
  body:not(.show-dialog) [onsw-hook].welcomeGuest {
    margin-bottom: 0;
    width: 25%;
    padding-top: calc((25% - 10px) * 2 * 0.56);
  }
  body.show-dialog [onsw-hook].welcomeGuest {
    margin-bottom: 10px;
    width: 33.333%;
    padding-top: calc((33.333% - 10px) * 2 * 0.56);
  }
}

[onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
  padding: 15px;
}

@media (min-width: 1280px) {
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg {
    padding-top: 28%;
    position: relative;
  }

  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 10px;
    right: 10px;
  }
}

@media (max-width: 480px) {
  .ons-text.welcomeGuest {
    display: none !important;
  }

  onsw-template.welcomeGuest,
  .ons-form.welcomeGuest {
    width: 100% !important;
  }

}

[hookname="welcomeGuest"] > .box {
  text-shadow: 1px 1px 2px black;
}

.ons-form.welcomeGuest > .box > label > span {
  display: none;
}

.ons-form.welcomeGuest > .box > .buttons {
  padding-top: 25px;
}

onsw-template.welcomeGuest {
  font-size: 11px;
  position: relative;
  top: 95px;
  height: 0;
}

[hookname="welcomeGuest"] > .box::after {
  display : block;
  content : "";
  clear   : both;
}

@media (max-width: 1023px) {

  .ons-heading.welcomeGuest > .box {
    margin-bottom: 5px;
    font-size: 16px;
  }

}

@media (min-width: 1280px) and (max-width: 1599px) {

  .ons-heading.welcomeGuest > .box {
    font-size: 22px;
  }

}

