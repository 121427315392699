.table table,
.table1 table,
.table2 table {
    width: 100%;
    border-spacing: 0;
}
.table td,
.table1 td,
.table2 td {
    vertical-align: top;
}
.table1 td:first-child {
  padding-right: 5px;
}

.table1.colon td:first-child > span::after {
    content: ':';
}
.table1 ul {
    list-style-type: none;
    padding: 0; margin: 0;
}


.table table,
.table2 table {
    border: 0;
    border-collapse: separate;
    border-spacing: 0;
}
.table {
    border: 1px solid #d1d1d1;
    padding: 6px 10px;
    margin: 15px 0;
}
.table2 table {
    margin-bottom: 15px;
}
.table td,
.table2 td {
    line-height: 28px;
}
.table th {
    text-align: left;
    padding-bottom: 8px;
    vertical-align: bottom;
}
.table.table-lines tr td {
    border-top: 1px solid #d1d1d1;
}
.table.table-lines tr.total td {
    font-weight: bold;
    border-top: 2px solid #d1d1d1;
}
.table table .count {
    text-align: right;
}
.table2 td:last-child {
    font-weight: bold;
    width: 60%;
}
.table2.table-lines tr:not(:last-child) td {
    border-bottom: 1px solid #d1d1d1;
    padding: 0;
}

@media (max-width: 540px) {
    .table {
        font-size: 12px;
    }
    .table th:last-of-type {
        width: 35%;
        padding-left: 10px;
    }
    .table td {
        line-height: 24px;
    }
}