body.regard-shop-open .ons-layout.columns.shrink > .box > [class*="col"],
body.show-dialog .ons-layout.columns.shrink > .box > [class*="col"] { width: 100%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col10-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col10-xs { width: 10%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col16-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col16-xs { width: 16.666%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col20-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col20-xs { width: 20%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col25-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col25-xs { width: 25%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col30-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col30-xs { width: 30%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col33-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col33-xs { width: 33.333%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col40-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col40-xs { width: 40%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col50-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col50-xs { width: 50%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col60-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col60-xs { width: 60%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col66-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col66-xs { width: 66.666%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col70-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col70-xs { width: 70%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col75-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col75-xs { width: 75%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col80-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col80-xs { width: 80%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .col100-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .col100-xs { width: 100%; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .right-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .right-xs { float: right }

body.regard-shop-open .ons-layout.columns.shrink > .box > .left-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .left-xs { float: left }

body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.right-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .clear.right-xs { clear: right; }

body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.left-xs,
body.show-dialog .ons-layout.columns.shrink > .box > .clear.left-xs { clear: left; }

@media (min-width: 768px) {

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col10-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col10-sm { width: 10%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col16-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col16-sm { width: 16.666%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col20-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col20-sm { width: 20%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col25-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col25-sm { width: 25%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col30-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col30-sm { width: 30%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col33-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col33-sm { width: 33.333%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col40-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col40-sm { width: 40%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col50-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col50-sm { width: 50%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col60-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col60-sm { width: 60%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col66-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col66-sm { width: 66.666%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col70-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col70-sm { width: 70%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col75-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col75-sm { width: 75%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col80-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col80-sm { width: 80%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col100-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .col100-sm { width: 100%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .right-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .right-sm { float: right }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .left-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .left-sm { float: left }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .no-float-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .no-float-sm { float: none }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.right-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .clear.right-sm { clear: right; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.left-sm,
  body.show-dialog .ons-layout.columns.shrink > .box > .clear.left-sm { clear: left; }
}

@media (min-width: 1280px) {
  body.regard-shop-open .ons-layout.columns.shrink > .box > .col10-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col10-md { width: 10%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col16-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col16-md { width: 16.666%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col20-md,
  body.regard-shop-open .ons-layout.columns.shrink > .box > .col20,
  body.show-dialog .ons-layout.columns.shrink > .box > .col20-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col20 { width: 20%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col25-md,
  body.regard-shop-open .ons-layout.columns.shrink > .box > .col25,
  body.show-dialog .ons-layout.columns.shrink > .box > .col25-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col25 { width: 25%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col30-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col30-md { width: 30%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col33-md,
  body.regard-shop-open .ons-layout.columns.shrink > .box > .col33,
  body.show-dialog .ons-layout.columns.shrink > .box > .col33-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col33 { width: 33.333%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col40-md,
  body.regard-shop-open .ons-layout.columns.shrink > .box > .col40,
  body.show-dialog .ons-layout.columns.shrink > .box > .col40-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col40 { width: 40%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col50-md,
  body.regard-shop-open .ons-layout.columns.shrink > .box > .colhalf,
  body.show-dialog .ons-layout.columns.shrink > .box > .col50-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .colHalf { width: 50%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col60-md,
  body.regard-shop-open .ons-layout.columns.shrink > .box > .col60,
  body.show-dialog .ons-layout.columns.shrink > .box > .col60-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col60 { width: 60%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col66-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col66-md { width: 66.666%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col70-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col70-md { width: 70%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col75-md,
  body.regard-shop-open .ons-layout.columns.shrink > .box > .col75,
  body.show-dialog .ons-layout.columns.shrink > .box > .col75-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col75 { width: 75%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col80-md,
  body.regard-shop-open .ons-layout.columns.shrink > .box > .col80,
  body.show-dialog .ons-layout.columns.shrink > .box > .col80-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col80 { width: 80%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col100-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .col100-md { width: 100%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .right-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .right-md { float: right }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .left-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .left-md { float: left }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.right-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .clear.right-md { clear: right; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.left-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .clear.left-md { clear: left; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .right-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .right-md { float: right }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .left-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .left-md { float: left }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .no-float-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .no-float-md { float: none }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.right-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .clear.right-md { clear: right; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.left-md,
  body.show-dialog .ons-layout.columns.shrink > .box > .clear.left-md { clear: left; }
}

@media (min-width: 1600px) {
  body.regard-shop-open .ons-layout.columns.shrink > .box > .col10-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col10-lg { width: 10%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col16-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col16-lg { width: 16.666%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col20-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col20-lg { width: 20%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col25-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col25-lg { width: 25%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col30-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col30-lg { width: 30%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col33-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col33-lg { width: 33.333%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col40-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col40-lg { width: 40%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col50-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col50-lg { width: 50%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col60-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col60-lg { width: 60%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col66-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col66-lg { width: 66.666%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col70-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col70-lg { width: 70%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col75-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col75-lg { width: 75%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col80-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col80-lg { width: 80%; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .col100-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .col100-lg { width: 100%; }


  body.regard-shop-open .ons-layout.columns.shrink > .box > .right-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .right-lg { float: right }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .left-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .left-lg { float: left }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .no-float-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .no-float-lg { float: none }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.right-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .clear.right-lg { clear: right; }

  body.regard-shop-open .ons-layout.columns.shrink > .box > .clear.left-lg,
  body.show-dialog .ons-layout.columns.shrink > .box > .clear.left-lg { clear: left; }

}

