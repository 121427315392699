@import '../spc/preset-messenger.css';

/* afo */
.layout-container.regard-shop > .layout-overlay, /* override overlay preset */
.layout-container.messenger,
.layout-container.regard-shop {
  --messenger-background: var(--background-messenger);
  --messenger-background-hover: #f084f4;
  --messenger-background-active: #f084f4;
  --messenger-border-color: #dddfe2;
  --messenger-highlight-color: #de2872;
  --messenger-highlight-color-hover: #fff;
  --messenger-highlight-color-active: #fff;

  /** ### layout */
  .layout-toolbar {
    --toolbar-icon-color: var(--messenger-highlight-color);
  }

  .layout-item {
    --item-border: none;
  }

  /** ### regard */
  .layout-link.regard-category {
    --link-color: var(--messenger-highlight-color);
    --link-color-hover: var(--messenger-highlight-color-hover);
    --link-color-active: var(--messenger-highlight-color-active);
    --regard-link-color-active: var(--messenger-highlight-color-active);
  }
}
