@media (min-width: 1280px) {
  [onsw-toggle-button].sidebar > .box {
    pointer-events: none;
  }

  [onsw-toggle-button].sidebar > .box::after {
    display: none;
  }
}

