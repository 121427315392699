[onsw-teaser].halloween2021.small > .box > .toggle::before {
  display: none;
}

[onsw-teaser].halloween2021 [onsw-countdown] > .box > .label {
  display: none;
}

[onsw-teaser].halloween2021 [onsw-countdown] > .box {
  bottom: 0px;
  right: 0px;
  padding: 5px 30px;
  background: #00000078;
  box-shadow: none;
}
 [onsw-countdown].halloween2021 > .box > .value {
  text-shadow: 0px 3px 6px #080607;
  color: #f90;
  font-size:70px;
}

[onsw-teaser].halloween2021.large > .box {
  padding-top: 56.25%;
}

:lang(de) [onsw-teaser].halloween2021.preview.large > .box {
  background-image: url("/specials/halloween2021/banner_m_de.jpg");
}
:lang(de) [onsw-teaser].halloween2021.active.large > .box {
  background-image: url("/specials/halloween2021/banner_m_de.jpg");
}
:lang(en) [onsw-teaser].halloween2021.preview.large > .box {
  background-image: url("/specials/halloween2021/banner_m_en.jpg");
}
:lang(en) [onsw-teaser].halloween2021.active.large > .box {
  background-image: url("/specials/halloween2021/banner_m_en.jpg");
}
:lang(de) [onsw-teaser].halloween2021.active.finished.large > .box {
  background-image: url("/specials/halloween2021/banner_m_de_redeemed.jpg");
}
:lang(en) [onsw-teaser].halloween2021.active.finished.large > .box {
  background-image: url("/specials/halloween2021/banner_m_en_redeemed.jpg");
}

[onsw-teaser].halloween2021.small > .box {
  padding-top: 65px;
  background-position: center;
  background-size: unset;
  background-repeat: no-repeat;
}

:lang(de) [onsw-teaser].halloween2021.preview.small > .box {
  background-image: url("/specials/halloween2021/banner_mini_de.jpg");
}
:lang(de) [onsw-teaser].halloween2021.active.small > .box {
  background-image: url("/specials/halloween2021/banner_mini_de.jpg");
}
:lang(en) [onsw-teaser].halloween2021.preview.small > .box {
  background-image: url("/specials/halloween2021/banner_mini_en.jpg");
}
:lang(en) [onsw-teaser].halloween2021.active.small > .box {
  background-image: url("/specials/halloween2021/banner_mini_en.jpg");
}

[onsw-teaser].halloween2021 > .box::after {
  display: none;
  content: ' ';
}

@media (min-width: 319px) {

  [onsw-teaser].halloween2021 [onsw-countdown] > .box {
    bottom: 0px;
    right: 0px;
    padding: 0px 10px;
  }

  [onsw-countdown].halloween2021 > .box > .value {
    line-height: 35px;
    font-size: 30px;
  }

}

@media (min-width: 768px) {

  [onsw-teaser].halloween2021 [onsw-countdown] > .box {
    bottom: 0px;
    right: 0px;
    padding: 0px 10px;
  }

  [onsw-countdown].halloween2021 > .box > .value {
    line-height: 35px;
    font-size: 30px;
  }

}


@media (min-width: 1024px) {

  [onsw-teaser].halloween2021 [onsw-countdown] > .box {
    bottom: 0px;
    right: 0px;
    padding: 0px 10px;
  }
  [onsw-countdown].halloween2021 > .box > .value {
    line-height:50px;
    font-size:40px;
  }

  [onsw-teaser].halloween2021.large > .box {
    padding-top: 33.333%;
  }

  :lang(de) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_l_de.jpg");
  }
  :lang(de) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_l_de.jpg");
  }
  :lang(de) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_l_de_redeemed.jpg");
  }
  :lang(en) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_l_en.jpg");
  }
  :lang(en) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_l_en.jpg");
  }
  :lang(en) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_l_en_redeemed.jpg");
  }

  body.show-dialog [onsw-teaser].halloween2021.large > .box {
    padding-top: 56.25%;
  }

  body.show-dialog:lang(de) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_m_de.jpg");
  }
  body.show-dialog:lang(de) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_m_de.jpg");
  }
  body.show-dialog:lang(de) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_m_de_redeemed.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_m_en.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_m_en.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_m_en_redeemed.jpg");
  }

}

@media (min-width: 1280px) {

  [onsw-countdown].halloween2021 > .box > .value {
    line-height: 70px;
    font-size: 70px;
  }

  [onsw-teaser].halloween2021.large > .box {
    padding-top: 26.5%;
  }
  :lang(de) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_de.jpg");
  }
  :lang(de) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_de.jpg");
  }
  :lang(de) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_de_redeemed.jpg");
  }
  :lang(en) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_en.jpg");
  }
  :lang(en) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_en.jpg");
  }
  :lang(en) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_en_redeemed.jpg");
  }

  body.show-dialog [onsw-teaser].halloween2021.large > .box {
    padding-top: 33.333%;
  }
  body.show-dialog:lang(de) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_l_de.jpg");
  }
  body.show-dialog:lang(de) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_l_de.jpg");
  }
  body.show-dialog:lang(de) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_l_de_redeemed.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_l_en.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_l_en.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_l_en_redeemed.jpg");
  }
}

@media (min-width: 1600px) {

  body.show-dialog [onsw-teaser].halloween2021.large > .box {
    padding-top: 26.5%;
  }
  body.show-dialog:lang(de) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_de.jpg");
  }
  body.show-dialog:lang(de) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_de.jpg");
  }
  body.show-dialog:lang(de) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_de_redeemed.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].halloween2021.preview.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_en.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].halloween2021.active.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_en.jpg");
  }
  body.show-dialog:lang(en) [onsw-teaser].halloween2021.active.finished.large > .box {
    background-image: url("/specials/halloween2021/banner_xl_en_redeemed.jpg");
  }
}

@media (max-width: 500px) {
  [onsw-teaser].halloween2021.small > .box {
    background-size: auto 80%;
  }
}
