@import url('../../../../node_modules/cropperjs/dist/cropper.css');

[onsw-profile-image-cropper] img {
    width: 100%;
    height: auto;
}

[onsw-profile-image-cropper] .crop-preview {
    margin-right: 10px;
    overflow: hidden;
    height: 80%;
}

[onsw-profile-image-cropper] .crop-pw {
    position: absolute;
    border: 0 groove #39f;
}

[onsw-profile-image-cropper] .crop-pw.normal {
    border-width: 1px 0;
    height: 75%;
    width: 100%;
    top: 50%;
    left:0;
    transform: translateY(-50%);
}

[onsw-profile-image-cropper] .crop-pw.wide {
    border-width: 0 1px;
    height: 100%;
    width: 75%;
    top:0;
    left: 50%;
    transform: translateX(-50%);
}

[onsw-profile-image-cropper] .cropper-modal {
    opacity: 0.7;
}
