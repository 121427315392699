onsw-comment-list {
  max-height: 300px;
  /*--warp-min-height: 30px;*/
  --warp-gap: var(--comment-gap);

  + .scroll-overlay > .bar {
    transform: translateX(7px);
  }

  > .empty {
    /* text-content from translation contains line-break */
    white-space: pre-line;
  }

  > article {
    min-width: 0;
    padding: var(--comment-padding);
    border: var(--comment-border);
    border-width: var(--comment-border-width);
    border-radius: var(--default-border-radius);

    /*> div,
    &:empty {
      height: 50px;
    }*/

    > header {
      color: var(--comment-header-color);

      > .name {
        font-weight: bold;
      }

      ::after {
        content: ':';
      }
    }

    > p {
      margin: 0;
      white-space: pre-line;
    }
  }

  > article:nth-child(odd) {
    background: var(--comment-background-odd);
  }

  > article:nth-child(even) {
    background: var(--comment-background-even);
  }
}
