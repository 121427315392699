[onsw-livecam-show] {
  height: 100%;

  > .box {
    height: 100%;

    #livecamPlayer {
      height: 100%;

      .livecam-001-row.is-portrait.alternate-design {
        .livecam-001-chat-box {
          width: calc(100% - 20px);
        }

        &.livecam-001-toycontrol-open .livecam-001-chat-box {
          @media (max-width: 723px) {
            bottom: 235px !important;
          }

          @media (min-width: 768px) and (max-width: 1183px) {
            bottom: 235px !important;
          }

          @media (min-width: 1280px) and (max-width: 1384px) {
            bottom: 235px !important;
          }
        }
      }

      .livecam-001-row.alternate-design,
      .livecam-001-row.is-portrait,
      .livecam-001-row.is-landscape {
        font-family: var(--default-font) !important;

        .livecam-001-message,
        .livecam-001-message.livecam-001-default {
          background: var(--livecam-show-message-bg) !important;
          border-radius: var(--livecam-show-message-border-radius) !important;
        }

        .livecam-001-message.livecam-001-default.livecam-toycontrol-message {
          background: #3c80dc !important;
        }

        .modal-content {
          background-color: var(--livecam-show-button-bar-bg);
          border-radius: var(--livecam-show-button-border-radius);
        }

        .modal-header {
          border-bottom: 1px solid rgba(229,229,229,.4);
        }

        .modal-footer {
          border-top: 1px solid rgba(229,229,229,.4);
        }

        .livecam-001-normal-ok-button,
        .livecam-001-cancel-button-normal,
        .livecam-001-cancel-button-exclusive,
        .livecam-001-exclusive-ok-button {
          border-radius: var(--livecam-show-button-border-radius);
        }

        .livecam-001-smiley-select {
          background: var(--livecam-show-smiley-select-bg) !important;
          border-radius: var(--livecam-show-smiley-select-border-radius) !important;
        }

        .livecam-001-button-bar {
          background: var(--livecam-show-button-bar-bg);

          .livecam-001-chatInput {
            background: var(--input-bg) !important;
            border: var(--livecam-show-input-border) !important;
            border-radius: var(--input-border-radius) !important;
            color: var(--input-color) !important;
          }

          .livecam-btn,
          .livecam-btn-default,
          .livecam-btn-info {
            background: var(--livecam-show-button-bg);
            color: var(--livecam-show-button-color);
            border: var(--livecam-show-button-border) !important;
            border-radius: var(--livecam-show-button-border-radius) !important;
            opacity: 1 !important;

            &:hover,
            &:focus,
            &:active,
            &.active {
              background: var(--livecam-show-button-hover-bg);
              color: var(--livecam-show-button-hover-color);
              border: var(--livecam-show-button-hover-border) !important;
              opacity: 1;
            }
          }

          .livecam-001-ask-exclusive-button,
          .livecam-001-exclusive-info {
            background: var(--livecam-button-exclusive-bg) !important;
            color: var(--livecam-button-exclusive-color) !important;
            border: var(--livecam-button-exclusive-border) !important;

            &:hover {
              background: var(--livecam-button-exclusive-hover-bg) !important;
              color: var(--livecam-button-exclusive-hover-color) !important;
              border: var(--livecam-button-exclusive-hover-border) !important;
            }
          }

          .livecam-001-exclusive-info {
            border-radius: var(--livecam-show-button-border-radius) !important;
          }

          .livecam-001-ask-normal-button {
            background: var(--livecam-button-normal-bg) !important;
            color: var(--livecam-button-normal-color) !important;
            border: var(--livecam-button-normal-border) !important;

            &:hover {
              background: var(--livecam-button-normal-hover-bg) !important;
              color: var(--livecam-button-normal-hover-color) !important;
              border: var(--livecam-button-normal-hover-border) !important;
            }
          }

          .livecam-001-smiley-button {
            &:hover,
            &:focus,
            &:active,
            &.active {
              color: var(--livecam-show-smiley-button-hover-color);
            }
          }

          .livecam-001-send-button {
            background: var(--livecam-show-send-button-bg);
            color: var(--livecam-show-send-button-color);
            border: var(--livecam-show-send-button-border) !important;
            border-radius: var(--livecam-show-button-border-radius) !important;
            box-shadow: var(--livecam-show-send-button-box-shadow);

            &:hover,
            &:focus,
            &:active,
            &.active {
              background: var(--livecam-show-send-button-hover-bg);
              color: var(--livecam-show-send-button-hover-color) !important;
              border: var(--livecam-show-send-button-hover-border) !important;
            }
          }

          .livecam-001-toycontrol-button {
            border: #ED2E33 !important;
            background: linear-gradient(to bottom,#ED2E33 0,#F70106 100%) !important;
            color: white !important;

            &:hover,
            &:focus,
            &:active,
            &.active {
              border: #cc0000 !important;
              background: #cc0000 !important;
            }
          }
        }

        .livecam-001-toycontrol {
          background: var(--livecam-show-button-bar-bg);
        }

        .livecam-001-toycontrol-vibration-button-text {
          font-family: var(--headline);
          font-weight: var(--livecam-show-vibration-button-font-weight);
        }

        .livecam-001-toycontrol-vibration-button-text1 {
          font-size: var(--livecam-show-vibration-button-font-size);
        }

        .livecam-001-toycontrol-queue .livecam-001-toycontrol-queue-inner .livecam-001-toycontrol-vibration-button span.livecam-001-toycontrol-vibration-button-text1 {
          font-size: var(--livecam-show-queue-vibration-button-font-size);
        }
      }
    }
  }
}

body.regard-shop-open [onsw-livecam-show] > .box #livecamPlayer .livecam-001-row.is-portrait.alternate-design.livecam-001-toycontrol-open .livecam-001-chat-box,
body.show-dialog [onsw-livecam-show] > .box #livecamPlayer .livecam-001-row.is-portrait.alternate-design.livecam-001-toycontrol-open .livecam-001-chat-box {
  @media (min-width: 1184px) and (max-width: 1279px) {
    bottom: 235px !important;
  }

  @media (min-width: 1385px) and (max-width: 1503px) {
    bottom: 235px !important;
  }

  @media (min-width: 1600px) and (max-width: 1704px) {
    bottom: 235px !important;
  }
}
