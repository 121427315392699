onsw-amateur-tag-list > .box > ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 5px;
}

onsw-amateur-tag-list > .box > ul > li {
  display: inline;
}

onsw-amateur-tag-list > .box > ul > li:not(:last-child)::after {
  content: ', ';
}
