/**
 * @example
 * ```html
 * <span class="price">
 *   100
 *   <svg class="icon coins"></svg>
 * </span>
 * ```
 */
.layout-price {
  display: var(--price-display, inline-flex);
  align-items: center;
  gap: var(--price-gap,  0.4ex);
  font: var(--price-font);
  color: var(--price-color);

  > .icon {
    width: var(--price-icon-size, 20px);
    height: var(--price-icon-size, 20px);
  }
}
