@import '../layout/container.css';
@import '../layout/panel.css';

.amateurPool-sedcard.layout-container {
  > .responsive-column > .responsive-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    > .layout-panel {
      overflow: hidden;
      flex: 1 0 230px;
    }

    .about-me > p {
      white-space: pre-line;
      &:empty {
        display: none;
      }
    }
  }

  &.small {
    > .responsive-column {
      display: contents;

      > .responsive-row {
        order: 1;
      }
    }
  }

  &.large {
    grid-template-columns: 3fr 2fr;
    grid-template-rows: minmax(0, min-content) auto;

    > .responsive-column {
      grid-area: 1 / 2 / span 2 / auto;
    }
  }

  .feed-hook {
    /* TODO those should be like .item, badges too */
    background: var(--personal-info-badge-background-color);

    &.weblog.without-image > .box > figure {
      display: none;
    }

    &.without-media {
      figure,
      header {
        &.feed-widget > .box {
          cursor: unset;
        }
      }
    }
  }
}
