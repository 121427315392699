.ons-item.button.livecam {
  > .box {
    padding: 0 25px;
    height: 50px;
    line-height: 1;
    background: var(--livecam-button-normal-bg);
    color: var(--livecam-button-normal-color);
    border: var(--livecam-button-normal-border);
    box-shadow: var(--livecam-button-box-shadow);

    > .toy-active {
      display: none;
    }
  }

  &.normal:not(.has-toy) > .box {
    box-shadow: var(--livecam-button-normal-box-shadow);

    .label {
      color: var(--livecam-button-normal-color);
      text-shadow: var(--livecam-button-normal-label-text-shadow);
    }

    @media (hover: hover) {
      &:hover {
        background: var(--livecam-button-normal-hover-bg);
        color: var(--livecam-button-normal-hover-color);
        border: var(--livecam-button-normal-hover-border);

        .label {
          color: var(--livecam-button-normal-hover-color);
          text-shadow: var(--livecam-button-normal-hover-label-text-shadow);
        }
      }
    }
  }

  &.voyeur > .box,
  &.voyeur.has-toy > .box {
    background: var(--livecam-button-voyeur-bg);
    color: var(--livecam-button-voyeur-color);
    border: var(--livecam-button-voyeur-border);
    box-shadow: var(--livecam-button-voyeur-box-shadow);

    .label {
      color: var(--livecam-button-voyeur-color);
      text-shadow: var(--livecam-button-voyeur-label-text-shadow);
    }

    @media (hover: hover) {
      &:hover {
        background: var(--livecam-button-voyeur-hover-bg);
        color: var(--livecam-button-voyeur-hover-color);
        border: var(--livecam-button-voyeur-hover-border);

        .label {
          color: var(--livecam-button-voyeur-hover-color);
          text-shadow: var(--livecam-button-voyeur-hover-label-text-shadow);
        }
      }
    }
  }

  &.exclusive:not(.has-toy) > .box {
    background: var(--livecam-button-exclusive-bg);
    color: var(--livecam-button-exclusive-color);
    border: var(--livecam-button-exclusive-border);
    box-shadow: var(--livecam-button-exclusive-box-shadow);

    .label {
      color: var(--livecam-button-exclusive-color);
      text-shadow: var(--livecam-button-exclusive-label-text-shadow);
    }

    @media (hover: hover) {
      &:hover {
        background: var(--livecam-button-exclusive-hover-bg);
        color: var(--livecam-button-exclusive-hover-color);
        border: var(--livecam-button-exclusive-hover-border);

        .label {
          color: var(--livecam-button-exclusive-hover-color);
          text-shadow: var(--livecam-button-exclusive-hover-label-text-shadow);
        }
      }
    }
  }

  &.has-toy:not(.voyeur) {
    > .box {
      background: linear-gradient(to bottom, #FD0097 0%, #9B085C 100%);
      color: white;
      border: none;
      flex-direction: row;
      justify-content: space-evenly;

      > .toy-active {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;

        > .icon {
          width: 24px;
          height: 24px;

          * {
            fill: white;
          }
        }
      }

      @media (hover: hover) {
        &:hover {
          background: #FD0097;
          color: white;
          border: none;
        }
      }
    }
  }

  &.aotm-widget > .box {
    display: flex;
    justify-content: space-around;
    padding: 0 15px;

    > .toy-active > .toy-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
    }
  }

  .default-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .label {
    font-size: var(--livecam-button-label-font-size);
  }

  .additional {
    font-weight: normal;
    font-size: var(--livecam-button-additional-font-size);
  }
}
