.table1 {
  th {
    font-family: var(--headline);
    text-align: left;
    padding-bottom: 8px;
  }

  td {
    line-height: 24px;

    &:first-child:not(.date) {
        font-family: var(--headline);
        font-size: 14px;
        width: 45%;
    }
  }

  .table-lines td {
    border-top: 1px solid #444;
    padding: 2px 0;
  }
}

