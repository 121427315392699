/*.ons-layout.feeditem > article > p > .box > .icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  padding-right: 5px;
}

header.feed-widget > .box {
  cursor: pointer;
}

.ons-layout.feeditem > article > p > .box > .icon * {
  fill: #7ca410;
}

.ons-layout.feeditem > article > footer > .box > :not(:last-child)::after {
  content: " | ";
}

@media (max-width: 767px) {
  .ons-layout.feeditem h1 {
    margin-bottom: 3px;
  }

  .ons-layout.feeditem article {
    padding: 20px;
  }
}*/

/**
 * Bei hoher Auflösung wird die breite des Textes beschränkt.
 */
@media (min-width: 1600px) {
  .ons-layout.feeditem > article > figure + p {
    width: 60% !important;
  }
}

/**
  globalFeed
 */
.feed-box .ons-list >.box >*{padding-top: 25% !important; width: 100%;}
.feed-box .ons-list >.box >* >.box{padding: 10px; position: absolute; top: 10px; bottom: 10px; left: 10px; right: 10px; overflow: hidden;}
.feed-box .ons-list >.box >* >.box >figure {height: 100%}
.feed-box .ons-list >.box >* >.box >figure >.box{height: 100%; padding: unset}

@media (max-width: 1600px) {
  .feed-box .ons-list >.box >*{padding-top: 50% !important;}
  .feed-box .ons-list >.box >* >.box >figure {height: 60%}
}

@media (max-width: 1023px) {
  .feed-box .ons-list >.box >*{padding-top: 100% !important;}
  .feed-box .ons-list >.box >* >.box >figure {height: 60%}
}
