.teaser-box,
.bonus-box {
  position: relative;
  height: 100%;

  & video,
  & img {
    display: block;
    height: 100%;
    width: 100%;
    min-height: 50px;
    object-fit: cover;

    @media (min-width: 1024px) {
      min-height: 65px;
    }
  }
}

