.layout-textlink {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: var(--link-color);
  text-decoration: var(--link-text-decoration, underline);

  @media (hover: hover) {
    &:hover {
      color: var(--link-hover-color, var(--link-color));
      text-decoration: var(--link-hover-text-decoration, var(--link-text-decoration, underline));
    }
  }
}
