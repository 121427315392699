@import '../layout/list.css';

.layout-list.regard-list {
  grid-template-columns: repeat(auto-fill, minmax(var(--regard-list-min-width, 158px), 1fr));
  grid-auto-rows: minmax(var(--regard-list-min-height, 200px), min-content);
  gap: var(--regard-list-gap, var(--list-gap));
  padding: var(--regard-list-padding, var(--list-padding));
  background: var(--regard-list-background, var(--container-background));

  > .loading {
    width: 100%;
    border: none;
    text-align: center;
  }
}
