[onsw-teaser].christmas2022.small.main-widget > .box {
  background-image: url("/specials/christmas2022/banner_mini_1_de.jpg");
  padding-top: 60px;
  background-position: 55% center;
  background-size: auto 100%;
}
@media (max-width: 1023px) {
  [onsw-teaser].christmas2022.small.main-widget > .box {
    padding-top: 45px;
  }
  [onsw-teaser].small.main-widget > .box > .close {
    display: none;
  }
}

[onsw-teaser].christmas2022 [onsw-countdown] > .box {
  background: #db0006;
}

:lang(en) [onsw-teaser].christmas2022.active.small.main-widget > .box {
  background-image: url("/specials/christmas2022/banner_mini_1_en.jpg");
}

[onsw-teaser].christmas2022 > .box {
  padding-top: 40%;
  background-image: url("/specials/christmas2022/xmas_overlay_1_de.jpg");
  background-size: cover;
  background-position: center;
}
.myportal-box [onsw-teaser].christmas2022 > .box,
.sidebar-box [onsw-teaser].christmas2022 > .box {
  background-image: url("/specials/christmas2022/xmas_Otm_1_de.jpg");
  padding-top: 250%;
  background-size: contain;
}
.myportal-box [onsw-teaser].christmas2022B > .box,
.sidebar-box [onsw-teaser].christmas2022B > .box {
  background-image: url("/specials/christmas2022/xmas_Otm_2_de.jpg");
}
.myportal-box [onsw-teaser].christmas2022C > .box,
.sidebar-box [onsw-teaser].christmas2022C > .box {
  background-image: url("/specials/christmas2022/xmas_Otm_3_en.jpg");
}
.myportal-box [onsw-teaser].christmas2022D > .box,
.sidebar-box [onsw-teaser].christmas2022D > .box {
  background-image: url("/specials/christmas2022/xmas_Otm_4_de.jpg");
}

:lang(en) .myportal-box [onsw-teaser].christmas2022 > .box,
:lang(en) .sidebar-box [onsw-teaser].christmas2022 > .box {
  background-image: url("/specials/christmas2022/xmas_Otm_1_en.jpg");
}
:lang(en) .myportal-box [onsw-teaser].christmas2022B > .box,
:lang(en) .sidebar-box [onsw-teaser].christmas2022B > .box {
  background-image: url("/specials/christmas2022/xmas_Otm_2_en.jpg");
}
:lang(en) .myportal-box [onsw-teaser].christmas2022C > .box,
:lang(en) .sidebar-box [onsw-teaser].christmas2022C > .box {
  background-image: url("/specials/christmas2022/xmas_Otm_3_de.jpg");
}
:lang(en) .myportal-box [onsw-teaser].christmas2022D > .box,
:lang(en) .sidebar-box [onsw-teaser].christmas2022D > .box {
  background-image: url("/specials/christmas2022/xmas_Otm_4_en.jpg");
}

:lang(en) [onsw-teaser].christmas2022 > .box {
  background-image: url("/specials/christmas2022/xmas_overlay_1_en.jpg");
}

@media (min-width: 1280px) {
  [onsw-teaser].christmas2022 > .box {
    padding-top: 30%;
    background-position: center 80%;
  }
  [onsw-teaser].christmas2022 > .box,
  [onsw-teaser].christmas2022 > .box {
    background-position: center;
  }
}

.myportal2-widget.teaser1 [onsw-teaser].christmas2022 > .box {
  height: 100%;
}

.myportal-box [onsw-teaser] > .box > .close,
.sidebar-box [onsw-teaser] > .box > .close,
.myportal2-widget.teaser1 [onsw-teaser] > .box > .close {
  display: none;
}

/* christmas2022B */

[onsw-teaser].christmas2022B.active.small.main-widget > .box {
  padding-top: 36px;
  background-position: 55%;
  background-image: url("/specials/christmas2022/banner_mini_2_de.jpg");
}
[onsw-teaser].christmas2022C.active.small.main-widget > .box {
  background-image: url("/specials/christmas2022/banner_mini_3_de.jpg");
}
[onsw-teaser].christmas2022D.active.small.main-widget > .box {
  background-image: url("/specials/christmas2022/banner_mini_4_de.jpg");
}
@media (max-width: 1023px) {
  [onsw-teaser].small.main-widget > .box > .close {
    display: none;
  }
}
@media (min-width: 1024px) {
  [onsw-teaser].christmas2022B.active.small.main-widget > .box {
    padding-top: 65px;
  }
}

:lang(en) [onsw-teaser].christmas2022B.active.small.main-widget > .box {
  background-image: url("/specials/christmas2022/banner_mini_2_en.jpg");
}

:lang(en) [onsw-teaser].christmas2022C.active.small.main-widget > .box {
  background-image: url("/specials/christmas2022/banner_mini_3_en.jpg");
}

:lang(en) [onsw-teaser].christmas2022D.active.small.main-widget > .box {
  background-image: url("/specials/christmas2022/banner_mini_4_en.jpg");
}

[onsw-teaser].christmas2022B > .box {
  padding-top: 40%;
  background-image: url("/specials/christmas2022/xmas_overlay_2_de.jpg");
  background-size: cover;
  background-position: center;
}
[onsw-teaser].christmas2022C > .box {
  background-image: url("/specials/christmas2022/xmas_overlay_3_de.jpg");
}
[onsw-teaser].christmas2022D > .box {
  background-image: url("/specials/christmas2022/xmas_overlay_4_de.jpg");
}

:lang(en) [onsw-teaser].christmas2022B > .box {
  background-image: url("/specials/christmas2022/xmas_overlay_2_en.jpg");
}
:lang(en) [onsw-teaser].christmas2022C > .box {
  background-image: url("/specials/christmas2022/xmas_overlay_3_en.jpg");
}
:lang(en) [onsw-teaser].christmas2022D > .box {
  background-image: url("/specials/christmas2022/xmas_overlay_4_en.jpg");
}

@media (min-width: 1280px) {
  [onsw-teaser].christmas2022B > .box {
    padding-top: 30%;
    background-position: center 80%;
  }
  [onsw-teaser].christmas2022B > .box,
  [onsw-teaser].christmas2022B > .box {
    background-position: center;
  }
}

[onsw-teaser].christmas2022B.small.main-widget > .box {
  padding-top: 60px;
  background-position: top;
}
