.ons-layout.livecamShow,
.ons-layout.livecamShow > .box {
  height: 100%;
}

#livecamOverlay {
  position: relative;
}

[onsw-livecam-close] {
  position: relative;

  > .box {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: var(--livecam-show-close-bg);

    > .icon.close {
      width: 26px;
      height: 26px;

      & * {
        fill: var(--livecam-show-close-icon);
      }
    }

    &:hover {
      background: var(--livecam-show-close-bg-hover);

      > .icon.close * {
        fill: var(--livecam-show-close-icon-hover);
      }
    }

    > .label {
      display: none;
    }
  }
}

