/**
 * ### Container
 */
.layout-container {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: var(--container-gap);
  align-content: start;
  align-items: start;
  background: var(--container-background, none);
}
