@import 'note-info.css';
@import 'note-warn.css';
@import 'note-error.css';

.layout-note {
  display: block;
  cursor: pointer; /* The implementation should remove this element when clicked. */
  padding: var(--layout-note-padding, 10px) calc(var(--layout-note-padding, 10px) * 1.8);
  padding-bottom: 0;
  width: 300px;
  margin: auto;

  .popup-modal > & {
    cursor: default;
  }

  > .close {
    float: right;
    margin: var(--layout-note-padding, 10px);
    margin-top: 0;
    margin-right: calc(var(--layout-note-padding, 10px) * -0.8);

    > .icon {
      display: block;
      width: calc(var(--layout-note-padding, 10px) * 1.8);
      height: calc(var(--layout-note-padding, 10px) * 1.8);
    }

    @media (hover: hover) {
      &:hover {
        transform: var(--layout-note-close-transform-hover, scale(1.2));
      }
    }
  }

  > .message {
    line-height: var(--layout-note-line-height, calc(var(--layout-note-padding, 10px) * 1.8));
    margin: 0;
    padding-bottom: var(--layout-note-padding, 10px);
  }
}

@property --layout-note-padding {
  syntax: "<length>";
  inherits: true;
  initial-value: 10px;
}
