@import '../css/layout/container.css';
@import '../css/layout/panel.css';
@import '../css/layout/toolbar.css';
@import '../css/layout/item.css';
@import '../css/layout/link.css';
@import '../css/layout/price.css';
@import '../css/regard/shop.css';

/**
 * ## Preset: SPC Messenger & Regard Shop
 * It is a little more complicated than necessary for spc to provide a decent 
 * preset for other portals.
 * - Border instead of padding/gap on many elements.
 * - Different color scheme.
 */
.layout-container.regard-shop > .layout-overlay, /* override overlay preset */
.layout-container.messenger,
.layout-container.regard-shop {
  --messenger-color: #333;
  --messenger-background: #fff;
  --messenger-background-hover: #ffe0b2;
  --messenger-background-active: #e6e6e6;
  --messenger-border-width: 1px;
  --messenger-border-style: solid;
  --messenger-border-color: #d1d1d1;
  --messenger-highlight-color: #f90;
  --messenger-highlight-color-hover: #f90;
  --messenger-highlight-color-active: #f90;
  --messenger-tab-bg: black;
  --messenger-tab-bg-active: white;
  --messenger-scroll-bg: #f8f8f8;
  --messenger-scroll-bg-hover: #e9e9e9;
  --messenger-scroll-icon-color: #8dbd0d;
  --messenger-scroll-border: 2px solid #c0bfbf;
  --messenger-scroll-wumper-color: #79bd0d;

  /**
   * ### Layout
   */
  /* `.layout-container` is the current selector */
  --container-gap: 0;
  --container-background: var(--messenger-background);

  .layout-panel {
    --panel-color: var(--messenger-color);
    --panel-heading-font-color: var(--messenger-highlight-color);
    --panel-background: var(--messenger-background);
  }

  .layout-toolbar {
    --toolbar-icon-color: var(--messenger-color);
  }

  .layout-field {
    --input-label-font: 15px var(--headline);
    --input-label-color: var(--messenger-color);
    --input-gap: 10px;
  }

  .layout-input {
    --input-bg: var(--messenger-input-bg);
    --input-bg-focus: var(--messenger-input-bg-focus, var(--messenger-input-bg));
    --input-border: var(--messenger-input-border);
    --input-border-focus: var(--messenger-input-border-focus);
    --input-boxshadow-focus: var(--messenger-input-boxshadow-focus);
    --input-border-radius: var(--input-textarea-border-radius);
  }

  .layout-item {
    --item-margin: 0 var(--messenger-border-width) var(--messenger-border-width) 0;
    --item-outline: var(--messenger-border-width) var(--messenger-border-style) var(--messenger-border-color);
    --item-background: var(--messenger-background);
    --item-color: var(--messenger-color);
    --item-background-hover: var(--messenger-background-hover);
    --item-background-active: var(--messenger-background-active);
    --item-outline-active: var(--item-outline);
    --item-highlight-color: var(--messenger-highlight-color);
    --item-highlight-color-hover: var(--messenger-highlight-color-hover);
    --item-highlight-color-active: var(--messenger-highlight-color-active);
  }

  .layout-price {
    --price-color: var(--messenger-color);
  }

  /**
   * ### Regard
   */
  /* `.regard-shop` is the current selector */
  --regard-shop-border: var(--messenger-border-width) var(--messenger-border-style)
      var(--messenger-border-color);

  .layout-link.regard-category {
    --link-font: 15px var(--headline);
    --regard-link-color: #333;
    --regard-link-color-hover: #333;
    --regard-link-color-active: #f90;
    --link-margin: 0 0 var(--messenger-border-width) 0;
    --link-outline: var(--messenger-border-width) var(--messenger-border-style) var(--messenger-border-color);
    --link-background-hover: var(--messenger-background-hover);
    --link-background-active: var(--messenger-background-active);
  }
}
