[onsw-attachments] > .box > .attachment {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

[onsw-attachments] > .box > .attachment > span {
  margin-right: 5px;
}

[onsw-attachments] > .box > .attachment > span.delete {
  margin-right: 0;
  margin-left: auto;
}

[onsw-attachments] > .box > .attachment > span.delete > .icon {
  height: 18px;
  width: 18px;
  padding: 5px;
  vertical-align: top;
}

[onsw-attachments] .locked img {
  opacity: .5;
}

[onsw-attachments] .preview img,
[onsw-attachments] .preview {
  width: 90px;
  vertical-align: middle;
  text-align: center;
}

[onsw-attachments] .icon.movie {
  width: 90px;
  height: 50px;
}

[onsw-attachments] .icon.movie * {
  fill: #fff;
}

[onsw-attachments] .name,
[onsw-attachments] span.locked {
  text-overflow: ellipsis;
  overflow: hidden;
}

[onsw-attachments] span.locked {
    display: none;
}
[onsw-attachments] .locked .name {
    display: none;
}
[onsw-attachments] .locked span.locked {
    display: flex;
}

[onsw-attachments] td.delete {
    text-align: center;
    width: 30px;
}

[onsw-attachments] td.delete::before {
    content: '×';
}

