.ons-text.emptyList > .box {
  margin: 0 0 10px;
  color: #979797;
  padding: 0 10px 20px 10px;
  text-align: center;
  background: #333;
}
.ons-text.emptyList > .box > .text::before {
  display: block;
  content: '';
  background-image: url(../img/noResult.png);
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  height: 150px;
}

.livecamShow .ons-text.livecamTile {
  display: none;
}

.ons-text.regardShop > .box {
  font-family: var(--headline);
  color: #999;
  text-align: center;
  font-size: 15px;
}

header > .box > p.ons-text {
  line-height: 20px;
  color: #a1a1a1;
}

.ons-text.aotm-widget {
  font-family: var(--headline);
}

p.small-feed-widget:first-child > .box {
  color: #a1a1a1;
}

.small-feed-box:hover {
  background: #1a1a1a;
}

