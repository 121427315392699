/**
 * TOOLBAR
 */
.ons-layout.overlay-toolbar > .box {
  background: #444;
}

.overlay-toolbar > .box > .ons-item > .box {
  font-family: var(--headline);
  font-size: 16px;
  color: #fff;
}

.overlay-toolbar > .box > .ons-item.ons-heading > .box,
.overlay-toolbar > .box > .ons-item.active > .box,
.overlay-toolbar > .box > .ons-item[click-action] > .box:hover {
  color: #f90;
}
.overlay-toolbar > .box > .ons-item[click-action] > .box:hover > .icon * {
  fill: #f90;
}

/**
 * CONTENT
 */
.ons-layout.overlay-content {
  background: #444;
}

/**
 * CONTENT PAYMENT & TRANSACTION RESULT
 */
.ons-layout.overlay > .box > .overlay-content.transactionResultOverlay,
.ons-layout.overlay > .box > .overlay-content.paybackResultOverlay,
.ons-layout.overlay > .box > .overlay-content.payment {
  background: white;
  color: #1a1a1a;
}

@media (min-width: 1024px) {
  .ons-layout.overlay > .box.withBonus > .overlay-toolbar.payment > .box > .ons-item > .box > .icon * {
    fill: white;
  }
}

/**
 * TOOLBAR PAYMENT & TRANSACTION RESULT
 */
.overlay-toolbar.transactionResultOverlay > .box,
.overlay-toolbar.paybackResultOverlay > .box,
.overlay-toolbar.payment > .box {
  background: #fff;
}

.overlay-toolbar.transactionResultOverlay > .box > .ons-heading > .box,
.overlay-toolbar.paybackResultOverlay > .box > .ons-heading > .box,
.overlay-toolbar.payment > .box > .ons-heading > .box {
  color: #f90;
}

