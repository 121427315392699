/* Amateur + Livecam of the Moment */
.aotm-box {
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 10px 40px;
  border-radius: var(--default-border-radius);

  > .messenger-send > .layout-panel {
    border: none;
    padding: 0;
    background: unset;

    .icon {
      display: none;
    }
  }
}

.aotm-widget.name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-shadow: 1px 1px 5px #000000;
  font-size: 36px;
  margin: 15px 0;
}

.aotm-widget.name > .box > .value {
  display: block;
  font-size: 28px;
}

.aotm-widget.desc {
  text-shadow: 1px 1px 5px #000000;
  font-size: 21px;
  line-height: 25px;
  margin: 20px 0 15px;
}

:lang(de) .aotm-widget.name > .box > .value::after {
  content: " Jahre";
}
:lang(en) .aotm-widget.name > .box > .value::after {
  content: " Years";
}
