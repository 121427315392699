[onsw-rating-rater] .outer {
  position: relative;
  font-size: 45px;
  color: grey;
  cursor: pointer;
  line-height: 80px;
}
[onsw-rating-rater] .outer > .inner {
  position: absolute;
  left: 0;
  color: gold;
  overflow: hidden;
}
[onsw-rating-rater] .outer::before, [onsw-rating-rater] .inner::before {
  content: '\2605\2605\2605\2605\2605';
}

[onsw-rating-rater] > .box > .stars {
  text-align: center;
}
