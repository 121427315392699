/*[onsw-nickname-filter] {
  width: 50%;
}

@media (min-width: 1024px) {
  [onsw-nickname-filter] {
    width: 250px;
  }
}*/

[onsw-nickname-filter] > .box {
  display: table;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
}

[onsw-nickname-filter] > .box > label {
  display: table-row;
}

[onsw-nickname-filter] > .box > label > * {
  display: table-cell;
  margin: 0 !important;
}

[onsw-nickname-filter] > .box > label > .box {
  cursor: pointer;
  width: 30px;
  height: 100%;
}

[onsw-nickname-filter] > .box > label > .box > .icon.close {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 10px;
}
