@media (min-width: 1024px) {
  body.regard-shop-open .ons-layout > .box > .hide-min-sm,
  body.regard-shop-open .ons-layout > .box > .last-min-sm ~ *,
  body.show-dialog .ons-layout > .box > .hide-min-sm,
  body.show-dialog .ons-layout > .box > .last-min-sm ~ * {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  body.regard-shop-open .ons-layout > .box > .hide-sm,
  body.show-dialog .ons-layout > .box > .hide-sm {
    display: none !important;
  }
}

@media (max-width: 1279px) {
  body.regard-shop-open .ons-layout > .box > .hide-max-sm,
  body.regard-shop-open .ons-layout > .box > .last-max-sm ~ *,
  body.show-dialog .ons-layout > .box > .hide-max-sm,
  body.show-dialog .ons-layout > .box > .last-max-sm ~ * {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  body.regard-shop-open .ons-layout > .box > .hide-min-md,
  body.regard-shop-open .ons-layout > .box > .last-min-md ~ *,
  body.show-dialog .ons-layout > .box > .hide-min-md,
  body.show-dialog .ons-layout > .box > .last-min-md ~ * {
    display: none !important;
  }
}

@media (min-width: 1280px) and (max-width: 1599px) {
  body.regard-shop-open .ons-layout > .box > .hide-md,
  body.show-dialog .ons-layout > .box > .hide-md {
    display: none !important;
  }
}

@media (max-width: 1599px) {
  body.regard-shop-open .ons-layout > .box > .hide-max-md,
  body.regard-shop-open .ons-layout > .box > .last-max-md ~ *,
  body.show-dialog .ons-layout > .box > .hide-max-md,
  body.show-dialog .ons-layout > .box > .last-max-md ~ * {
    display: none !important;
  }
}

@media (min-width: 1600px) {
  body.regard-shop-open .ons-layout > .box > .hide-lg,
  body.regard-shop-open .ons-layout > .box > .hide-min-lg,
  body.regard-shop-open .ons-layout > .box > .hide-xl,
  body.regard-shop-open .ons-layout > .box > .last-min-lg ~ *,
  body.show-dialog .ons-layout > .box > .hide-lg,
  body.show-dialog .ons-layout > .box > .hide-xl,
  body.show-dialog .ons-layout > .box > .hide-min-lg,
  body.show-dialog .ons-layout > .box > .last-min-lg ~ * {
    display: none !important;
  }
}

body.regard-shop-open .ons-layout > .box > .hide-max-lg,
body.regard-shop-open .ons-layout > .box > .last-max-lg ~ *,
body.show-dialog .ons-layout > .box > .hide-max-lg,
body.show-dialog .ons-layout > .box > .last-max-lg ~ * {
  display: none !important;
}

/*@media (min-width: ??) {
  body.show-dialog .ons-layout > .box > .hide-min-xl,
  body.show-dialog .ons-layout > .box > .last-min-xl ~ * {
    display: none!important;
  }
}*/
