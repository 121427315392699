[onsw-amateur-topic-badge] {
  height: 0;

  .tile-amateur-widget & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  > .box {
    background: linear-gradient(to right, transparent 30%, var(--amateur-topic-badge-bg));
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-end;
    transform: translateY(-100%);
    color: var(--amateur-topic-badge-fg);
    font-family: var(--headline);
    font-size: 15px;
    max-width: 500px;
    margin-left: auto;

    .icon {
      transform: scale(1.4);
      transform-origin: top right;
      height: 22px;
      width: 22px;
    }

    .icon * {
      fill: var(--amateur-topic-badge-fg);
    }
  }
}

