.ons-form > form > h1 {
  font-family: var(--headline);
  color: #cc0000;
  background: transparent;
  text-align: left;
  margin: 0 0 15px;
  padding: 0;
}

.ons-form > form > label > span {
  font-family: var(--headline);
  font-size: 13px;
  color: #ccc;
}

.ons-form > form > label > textarea,
.ons-form > form > label > file-upload,
.ons-form > form > label > input[type="text"],
.ons-form > form > label > input[type="number"],
.ons-form > form > label > input[type="email"],
.ons-form > form > label > input[type="password"],
.ons-form > form > label > select {
  font-family: var(--default-font);
  font-size: 13px;
  height: 30px;
  padding: 2px 5px;
  outline: none;
  color: #333;
  background: #ddd;
  border: 1px solid #d1d1d1;
  box-shadow: none;
  margin-top: 3px;
}

.ons-form > form > label > textarea {
  padding: 5px;
}

.ons-form > form > label > .ng-invalid:not(.ng-pristine) {
  border: 1px solid red !important;
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.6) !important;
}

.ons-form > form > label > textarea {
  height: unset;
  resize: none;
}

.ons-form > form > label > textarea:focus,
.ons-form > form > label > input[type="text"]:focus,
.ons-form > form > label > input[type="number"]:focus,
.ons-form > form > label > input[type="email"]:focus,
.ons-form > form > label > input[type="password"]:focus,
.ons-form > form > label > select:focus {
  border: 1px solid #3d3d3d;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

.ons-form.spc-light > form > label > textarea:focus,
.ons-form.spc-light > form > label > input:focus,
.ons-form.spc-light > form > label > select:focus {
  border: 1px solid #d1d1d1;
  box-shadow: 0 0 20px #999999;
}

.ons-form.inline > form > label > input {
  width: unset;
  margin-top: unset;
}

[onsw-facet-filter] .ons-form > .box > label > span {
  color: white;
  font-size: 14px;
}

.ons-form.formContainer > .box {
  padding: 10px 10px 5px;
}
.ons-layout.buttons {
  margin: 10px 0;
}
