@import '../layout/list.css';

/**
 * This is supposed to be inside of a panel.
 */
.achievement-packages {
  display: contents;

  > .layout-list {
    grid-template-columns: repeat(auto-fill, minmax(var(--achievement-packages-width, 230px), 1fr));
    grid-auto-rows: var(--achievement-packages-height, 60px);
    gap: var(--list-gap, 10px);

    > label {
      display: contents;

      &.notAvailable > * {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }

  .button {
    justify-self: end;
  }
}
