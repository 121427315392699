.livecam-list-show,
.livecam-list-sidebar {
  --warp-gap: 2px;
  --warp-aspect-ratio: 1;
  --warp-aspect-ratio-fallback: 100%; /* see mdn aspect-ratio browser support */
  --warp-limit: 6;

  &.not-sedcard {
    --warp-min-width: 120px;
    --warp-limit: 12;

    .livecam-show-box & {
      --warp-min-width: 180px;
      --warp-limit: unset;
    }
  }

  &.warp-grid > * {
    position: relative;

    > * {
      animation: ons-fade-in 1s ease;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      > .box {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% !important;
        cursor: pointer;

        &:hover {
          background-size: 120% !important;
        }
      }
    }
  }
}
