@import '../layout/toolbar-list.css';

onsw-amateur-list {
  > * {
    position: relative;
    overflow: hidden;

    > img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 1s ease;
      animation: ons-fade-in 1s ease;
    }

    &:hover > img {
      transform: scale(1.2);
    }

    > .layout-toolbar.toolbar-list {
      pointer-events: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20px;
      font-size: var(--amateur-small-list-font-size);
      animation: ons-fade-in .5s ease;

      > .icon:not(.online) {
        height: 20px;
        width: 20px;
      }
    }

    &:not(.messenger-online),
    &.livecam-online {
      .icon.online {
        display: none;
      }
    }

    &:not(.livecam-online) .icon.cam {
      display: none;
    }
  }
}

.amx-dst .cams onsw-amateur-list {
  --warp-gap: 20px;
  --warp-height: 140px;
  --warp-width: 120px;

  > * {
    background: transparent;
    border: 0;

    > img {
      background-color: white;
      border-radius: 50%;
      height: 120px;
    }

    &:hover > img {
      transform: none;
    }

    > .layout-toolbar.toolbar-list {
      background: transparent;
      padding: 0;
      align-items: flex-end;
      justify-content: center;

      > .title {
        flex-grow: 0;
      }

      > .icon {
        position: absolute;
        right: 2px;
        bottom: 20px;
        border-radius: 50%;

        &.cam {
          background: var(--livecam-icon-normal);
          border: 2px solid var(--livecam-icon-normal);
          width: 26px;
          height: 26px;

          * {
            fill: white;
          }
        }

        &.lovense {
          background: #ff379a;
          border: 2px solid #ff379a;
          width: 26px;
          height: 26px;

          * {
            fill: white;
          }
        }

        &.online {
          border: 8px solid #e9ebee;
        }
      }
    }
  }
}
