[onsw-payttachment] {
  cursor: pointer;
}

[onsw-payttachment] img {
  width: 100%;
  display: block;
  margin: auto;
}

[onsw-payttachment] img.dummy {
  filter: drop-shadow( 1px 2px 2px rgba(0, 0, 0, .4));
  width: 50%
}

[onsw-payttachment] .coins {
  font-weight: 600;
}

[onsw-payttachment] .coins > * {
  margin-left: 3px;
  width: 16px;
  height: 16px;
  filter: drop-shadow( 0 1px 2px rgba(0, 0, 0, .4));
}
