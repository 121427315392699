/**
 * ### Empty Spinner
 * This loading animation should not be used.
 * A template based loading animation should be implemented.
 */
.layout-empty-spinner:empty {
  display: block;
  min-height: 50px;
  background-image: var(--empty-spinner-url, url('../../basic/img/loading.svg'));
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  animation: layout-empty-spinner-enter 1s ease-in 0.5s forwards,
    layout-empty-spinner-pulsate 5s ease-in 5s,
    layout-empty-spinner-pulsate 2s linear 10s infinite;
}

@keyframes layout-empty-spinner-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes layout-empty-spinner-pulsate {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@property --empty-spinner-url {
  syntax: '<url>';
  inherits: true;
  initial-value: 'url(../../basic/img/loading.svg)';
}
