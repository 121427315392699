/**
 * @see [Documentation](../../js/layout/style/dots.js)
 */

@import './toolbar.css';

.nova-dots.layout-toolbar {
  justify-content: center;

  > .btn-icon {
    > .icon {
      will-change: opacity, scale, fill, transform;
      fill: var(--dots-color, var(--toolbar-icon-color, gray));
      width: var(--dots-size, var(--toolbar-icon-size, 16px));
      height: var(--dots-size, var(--toolbar-icon-size, 16px));
      transition-property: opacity, scale, fill, transform;
      transition-timing-function: ease-in-out, ease-in-out, ease, ease;
      transition-duration: var(--dots-transition-duration, var(--slideshow-transition-duration, 1s)),
        var(--dots-transition-duration, var(--slideshow-transition-duration, 1s)),
        var(--dots-transition-duration, var(--slideshow-transition-duration, 1s)),
        0.25s;
    }

    @media (hover: hover) {
      &:hover > .icon {
        fill: var(--dots-hover-color, var(--toolbar-icon-color-hover, var(--toolbar-icon-color, black)));
      }
    }

    &.layout-active > .icon {
      fill: var(--dots-active-color, var(--toolbar-icon-color-active, var(--toolbar-icon-color, black)));
    }

    &.layout-enter > .icon {
      animation-name: nova-dots-enter;
      animation-duration: calc(
        var(--dots-transition-duration, var(--slideshow-transition-duration, 1s)) * 2 / 3
      );
      animation-timing-function: ease-out;
    }
  }

  :not(.layout-busy) > & {
    > .btn-icon.layout-active > .icon {
      animation-name: nova-dots-active-busy;
      animation-duration: calc(
        var(--dots-transition-duration, var(--slideshow-transition-duration, 1s)) * 1 / 3
      );
      animation-timing-function: ease-in;
    }
  }
}

@keyframes nova-dots-enter {
  50% {
    scale: 10;
    opacity: 0.2;
    animation-timing-function: ease-in;
  }
}

@keyframes nova-dots-active-busy {
  50% {
    opacity: 0.6;
    animation-timing-function: ease-out;
  }
}
