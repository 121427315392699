.ons-heading > .box {
  display: block;
  font-family: var(--headline);
}

.ons-overlay > .content > .top h1 {
  font-family: var(--headline);
  margin: 0 10px;
}

.ons-heading.default > .box {
  font-size: 16px;
  color: #f90;
  text-transform: uppercase;
  line-height: 1;
}

.ons-list.default .ons-heading > .box {
  font-size: 15px;
  line-height: 1.4142;
  padding: 5px;
}

.ons-list.default .ons-heading > .box > .age,
.ons-list.default .ons-heading > .box > .from {
  font-family: var(--default-font);
  font-size: 13px;
  font-weight: normal;
}

.ons-layout.feeditem .ons-heading.default {
  line-height: 20px;
  color: #a1a1a1;
}
.ons-layout.feeditem .ons-heading.default > .box {
  font-size: 15px;
  text-transform: none;
  line-height: unset;
}

