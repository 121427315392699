.ons-layout.tabs > .box > .ons-item > .box {
  background: black;
  font-size: 16px;
  font-family: var(--headline);
  height: 36px;
  padding: 0 10px;
}

.ons-layout.tabs > .box > .ons-item.active > .box,
.ons-layout.tabs > .box > .ons-item > .box.layout-active,
.ons-layout.tabs > .box > .ons-item > .box:active,
.ons-layout.tabs > .box > .ons-item > .box.active {
  background: #333;
}

.ons-layout.tabs > .box > .ons-item > .box > .icon * {
  fill: white;
}

.ons-layout.tabs > .box > .ons-item.active > .box > .icon *,
.ons-layout.tabs > .box > .ons-item > .box:hover > .icon * {
  fill: #f90;
}

/* Main */
.main-box > .ons-layout.tabs.main {
  margin-bottom: -10px;
}

.ons-layout.tabs.main > .box > .ons-item {
  animation: tab-fade-in .5s ease-out;
  position: relative;
  white-space: nowrap;
}

@keyframes tab-fade-in {
  from {
    top: 35px;
  }
  to {
    top: 0;
  }
}

/* Sedcard */
@media (max-width: 1023px) {
  .ons-layout.tabs.main.sedcard-nav-hook {
    margin-bottom: unset;
  }
}

@media (max-width: 1279px) {
  .show-dialog .ons-layout.tabs.main.sedcard-nav-hook {
    margin-bottom: unset;
  }
}

/* Regardshop */
.ons-layout.tabs.regardShop > .box {
  background: #333;
}
.ons-layout.tabs.regardShop > .box > .ons-item > .box {
  background: unset;
}

.ons-layout.tabs.regardShop > .box > :last-child > .box {
  width: 36px;
}
