.userPool-2fa-setup {
  display: contents;

  > .layout-panel {
    display: contents;

    > .qrcode {
      box-sizing: border-box;
      width: 100%;
      padding: 10% 30%;
    }
  }
}
