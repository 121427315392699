.layout-panel.panel-list {

  > .warp-grid:not(.layout-empty) ~ * {
    display: none;
  }

  > .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--empty-list-text-color);

    > .image {
      margin: auto;
      max-width: 100%;
    }
  }
}
