[onsw-rating] .outer {
  position: relative;
  font-size: large;
  color: grey;
}

[onsw-rating][interactive="1"] .outer {
  cursor: not-allowed;
}

[onsw-rating] .outer > .inner {
  transition: all 0.4s ease-out;
}

[onsw-rating] .outer::before, [onsw-rating] .inner::before {
  content: '\2605\2605\2605\2605\2605'
}

[onsw-rating] .outer > .inner, [onsw-rating] .value {
  display: none;
}

[onsw-rating].ready .value {
  display: inline;
}

[onsw-rating].ready .outer > .inner {
  display: inline;
  position: absolute;
  left: 0;
  color: gold;
  overflow: hidden;
}

[onsw-rating].voting .outer > .inner, [onsw-rating].voted .outer > .inner {
  color: #c00;
}

[onsw-rating].right {
  float: right;
}

[onsw-rating][interactive="1"].rateable .outer {
  cursor: pointer;
}
[onsw-rating][interactive="1"].rateable.voted .outer {
  cursor: not-allowed;
}

[onsw-rating].right .label {
  display: block;
  text-align: right;
}

.layout-item.feed-item [onsw-rating] .label,
[onsw-movie-info] [onsw-rating] .label,
[onsw-picture-set-info] [onsw-rating] .label {
  display: none;
}
