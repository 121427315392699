/*.alert-warning { background: #fcf8e3; color: #8a6d3b; }*/
.box > .notify {
  position: absolute;
  top: 10px; left: 10px; right: 10px;
  z-index: 1;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, .5);
  background: #d6e9c6;
  color: #3c763d;
  padding: 10px;
  cursor: pointer;
}

.error > .box > .notify {
  border: 1px solid #700;
  background: #a00;
  color: white;
}

.box > .notify > .box > .message {
  display: block;
  line-height: 1.3;
}

.ons-form.tile > .box > .notify {
  top: 0; left: 0; right: 0;
  padding: 5px;
}

.notify > .box > .icon {
  float: right;
}

/*
.ons-form.notify-cover > .box {
	position: relative;
}
.ons-form.notify-cover > .box > .notify {
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	background: rgba(0,0,0,.8);
	cursor: pointer;
	z-index: 1;
}
.ons-form.notify-cover > .box > .notify > .message {
	position: absolute;
	top: 40%;
	transform: translateY(-50%);
	text-align: center;
	left: 10px;
	right: 10px;
	color: white;
	font-weight: bold;
}
.ons-form.notify-cover > .box > .notify > .close {
	position: absolute;
	text-align: center;
	bottom: 10px;
	left: 10px;
	right: 10px;
	color: white;
	font-weight: bold;
}
.ons-form.notify-cover > .box > .notify > .close:hover {
	color: red;
}
*/
