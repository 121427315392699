@import '../layout/header.css';
@import '../layout/summary.css';

.layout-header.user-header {
  &.large > .user-menu {
    flex-basis: 90px;

    > .layout-summary::after {
      right: 21px;
    }
  }

  &.small {
    .user-menu,
    .vip.button,
    .friends.button {
      display: none;
    }
  }

  &:not(.large) {
    padding-right: 10px;
  }

  > .user-menu {
    display: block;
    flex-basis: 55px;

    > .layout-summary::after {
      position: absolute;
      top: 7px;
      right: 7px;
      transform: scale(1.9, 1.05);
      color: var(--header-label-color);
    }

    > .layout-panel {
      position: absolute;
      box-shadow: 0 0 20px #0000004d;
    }
  }

  .button {
    &.friends {
      &:not(.layout-empty) > .value::before {
        content: '+';
      }

      > .gain {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        animation: 1s 2 header-friends-button alternate;

        > .icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@keyframes header-friends-button {
  from {
    opacity: 0.2;
    transform: scale(1);
    animation-timing-function: ease-out;
  }

  to {
    opacity: 1;
    transform: translate(-200px, 300px) scale(10);
    animation-timing-function: ease-in;
  }
}
