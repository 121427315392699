@import '../layout/input.css';

.core-security-code {
  display: flex;
  flex-direction: row;
  gap: 16px;

  > .layout-input {
    font-size: 1.5em;
    width: 1em;
    text-align: center;
    flex: 1 0 1em;
    padding: 8px;
    border-radius: calc(8px / 2);
    appearance: textfield; /* Firefox hide spin buttons */

    &::-webkit-inner-spin-button {
      display: none; /* Webkit/Blink hide spin buttons */
    }
  }
}
