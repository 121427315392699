/**
 * TEASER
 */
[onsw-teaser] [onsw-countdown] > .box > .value {
  font-family: var(--headline);
}

[onsw-countdown].free-movie-countdown-widget > .box > .value {
  color: #8dbd0d;
  text-shadow: 0 0 2px #738e15;
  margin-top: 0;
}
