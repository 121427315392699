.amateurPool-otm-shuffle {
  cursor: pointer;
  transition: left 1s ease, transform 0.25s ease-out, filter 0.25s ease-out;
  will-change: left, opacity;
  animation: ons-fade-in 1s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  border-radius: var(--default-border-radius);
  background: linear-gradient(
      to bottom,
      var(--otm-color-start) calc(100% - 120px),
      var(--otm-color-end) calc(100% - 40px)
    ),
    no-repeat top/cover var(--otm-image-url);
  border-bottom: 2px solid var(--otm-color-end);
  background-color: var(--otm-color-end);
  color: #fff;
  text-shadow: 1px 1px 5px #000000;
  filter: drop-shadow(0 0 0 transparent);

  > .name {
    font: var(--otm-name-font);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > .age {
    font: var(--otm-age-font);
  }

  @media (hover: hover) {
    &:hover {
      z-index: 2 !important;
      transition-timing-function: ease, ease-in, ease-in;
      transform: scale(1.05);
      filter: drop-shadow(0 0 10px black);
    }
  }
}
