[onsw-pagination].invalid,
[onsw-pagination].empty {
  display: none !important;
}

[onsw-pagination] > .box {
  text-align: center;
  user-select: none;
}

[onsw-pagination] > .box > .ons-item > .box {
  text-align: center;
  width: 30px;
  display: inline-block;
  box-sizing: border-box;
}

[onsw-pagination] > .box > .ons-item:not(.current) > .box {
  cursor: pointer;
}

[onsw-pagination] > .box > .ons-item:nth-child(3) > .box,
[onsw-pagination] > .box > .ons-item:nth-child(9) > .box {
  display: none !important;
}
  
/*@media (max-width: 767px) {
  [onsw-pagination] > .box > .ons-item:nth-child(5) > .box,
  [onsw-pagination] > .box > .ons-item:nth-child(7) > .box {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  [onsw-pagination] > .box > .ons-item:nth-child(4) > .box,
  [onsw-pagination] > .box > .ons-item:nth-child(8) > .box {
    display: none !important;
  }

  [onsw-pagination] > .box > .ons-item:nth-child(4)::after,
  [onsw-pagination] > .box > .ons-item:nth-child(8)::after {
    content: '...';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  body.show-dialog [onsw-pagination] > .box > .ons-item:nth-child(4) > .box,
  body.show-dialog [onsw-pagination] > .box > .ons-item:nth-child(8) > .box {
    display: none !important;
  }

  body:not(.show-dialog) [onsw-pagination] > .box > .ons-item:nth-child(3)::after,
  body:not(.show-dialog) [onsw-pagination] > .box > .ons-item:nth-child(9)::after,
  body.show-dialog [onsw-pagination] > .box > .ons-item:nth-child(4)::after,
  body.show-dialog [onsw-pagination] > .box > .ons-item:nth-child(8)::after {
    content: '...';
  }
}

@media (min-width: 1280px) {*/
  [onsw-pagination] > .box > .ons-item:nth-child(3)::after,
  [onsw-pagination] > .box > .ons-item:nth-child(9)::after {
    content: '...';
  }
/*}*/

