onsw-personal-info {

  > .icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
    
    &.wide {
      grid-template-columns: repeat(3, 1fr);
    }

    &.xxl > * {
      aspect-ratio: unset;
    }

    > div {
      padding: 5px;
      background: var(--personal-info-badge-background-color);
      aspect-ratio: 1;
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: var(--personal-info-badge-border);
      border-radius: var(--personal-info-badge-border-radius);

      > .icon {
        width: 30px;
        height: 30px;

        * {
          fill: var(--personal-info-badge-icon-color);
        }
      }

      > .label {
        color: var(--personal-info-badge-label-color);
        font: var(--personal-info-badge-label-font);
        line-height: 1;
      }

      > .value {
        color: var(--personal-info-badge-value-color);
        font: var(--personal-info-badge-value-font);
      }
    }
  }

  > .rest {
    margin-top: 10px;

    > div {
      display: flex;
      padding: 5px 0;

      & + div {
        border-top: 1px solid var(--personal-info-list-border-color);
      }

      > span {
        flex-basis: 50%;
      }

      > .label {
        color: var(--personal-info-list-label-color);
        font: var(--personal-info-list-label-font);
      }

      > .value {
        color: var(--personal-info-list-value-color);
        font: var(--personal-info-list-value-font);
      }
    }
  }
}
