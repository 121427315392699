[onsw-livecam-rebuy] {
  .loading.box {
    display: flex;
    align-items: center;
    justify-content: center;

    > span:not([onsw-loader]) {
      font-size: 16px;
      padding-left: 10px;
    }
  }

  .seconds {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;

    > span {
      color: var(--livecam-show-rebuy-seconds);
    }
  }

  .text-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .package-box,
  .loading.box,
  .rebuy.button {
    width: 100%;
  }

  > .notify {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
  }

  > .box {
    padding: 10px;

    > .verified {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;

      > .package-box.ons-list > .box {
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      }

      @media (min-width: 1420px) and (max-width: 1700px) {
        > .text-box {
          max-width: 390px;
        }

        > .loading.box,
        > .package-box {
          max-width: calc(100% - 400px);
        }
      }

      @media (min-width: 1701px) {
        .text-box {
          max-width: calc(50% - 10px);
        }

        .loading.box,
        .package-box {
          max-width: 50%;
        }
      }
    }

    .not-verified {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      > .rebuy.button {
        text-align: center;
      }

      @media (min-width: 1024px) {
        > .text-box {
          max-width: calc(80% - 10px);
        }

        > .rebuy.button {
          max-width: 20%;
        }
      }
    }

    &.toy-control-error .msg {
      display: flex;
      align-items: center;
      padding: 5px;
      background: #fd0097;
      color: white;
      min-height: 40px;

      > .icon {
        height: 30px;
        width: 30px;
        margin-right: 5px;
        flex-shrink: 0;

        * {
          fill: white;
        }
      }
    }
  }
}

body.show-dialog [onsw-livecam-rebuy] > .box {
  @media (min-width: 1024px) and (max-width: 1199px) {
    > .not-verified {
      > .text-box {
        max-width: 100%;
      }

      > .rebuy.button {
        max-width: 100%;
      }
    }
  }

  @media (min-width: 1701px) {
    > .verified {
      > .text-box {
        max-width: 390px;
      }

      > .loading.box,
      > .package-box {
        max-width: calc(100% - 400px);
      }
    }
  }
}

