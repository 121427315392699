[onsw-teaser] {
  display: none !important;
  -webkit-touch-callout: none;
  user-select: none;
}

[onsw-teaser] > .box {
  cursor: pointer;
}

[onsw-teaser].preview,
[onsw-teaser].active {
  display: block !important;
}

[onsw-teaser] > .box {
  position: relative;
  background-size: cover;
}

[onsw-teaser].small > .box > .close::after {
  top: 8px;
  right: 8px;
  content: "";
  height: 34px;
  width: 34px;
  background-size: 34px;
  background-image: url("../../basic/img/resize.svg");
  background-position: center;
  background-repeat: no-repeat;
}

/*.teaser-box,
.teaser-widget {
  height: 100%;
}*/

.slideshow.myportal-widget > .box > :not([onsw-heading]) {
  margin-top: 36px;
}
