[onsw-livecam-timer] > .box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

[onsw-livecam-timer] > .box > .button {
  text-align: center;
}

[onsw-livecam-timer] > .box .text-box {
  width: 100%;
  padding-bottom: 10px;
}

[onsw-livecam-timer] > .box .button {
  width: 100%;
}

@media (min-width: 1024px) {
  [onsw-livecam-timer] > .box > .text-box {
    max-width: 50%;
    padding-bottom: 0;
  }
  [onsw-livecam-timer] > .box > .button {
    max-width: 50%;
  }
}
@media (min-width: 1024px) and (max-width: 1199px){
  body.show-dialog [onsw-livecam-timer] > .box > .text-box {
    max-width: 100%;
    padding-bottom: 10px;
  }
  body.show-dialog [onsw-livecam-timer] > .box > .button {
    max-width: 100%;
  }
}

[onsw-livecam-timer] .seconds {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
}
