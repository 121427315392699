.content-removal > .layout-container {
  &.large {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    >.layout-panel {
      height: 100%;
      padding-top: 30px;
      textarea {
        min-height: 120px;
      }
      /* TODO: add global variables */
      .error-field {
        border-color: #f00;
      }
      .error {
        color: #f00;
      }
      >.content-removal-info {
        a {
          cursor: pointer;
        }
      }
    }
  }
}