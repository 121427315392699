[onsw-chosen] > .box {
  position: relative;
}

[onsw-chosen].has-available-options > .box {
  cursor: pointer;
}

[onsw-chosen] > .box > .info {
  padding: 0 5px;
  border: 1px solid #d1d1d1;
  color: #1a1a1a;
}

[onsw-chosen] > .box > .info.please-choose {
  display: none;
}

[onsw-chosen].has-available-options > .box > .info.please-choose {
  display: block;
}

[onsw-chosen].has-selected-options > .box > .info.please-choose {
  display: none;
}

[onsw-chosen] > .box > .info.no-options {
  display: block;
}

[onsw-chosen].has-selected-options > .box > .info.no-options,
[onsw-chosen].has-available-options > .box > .info.no-options {
  display: none;
}

[onsw-chosen] > .box > .selected {
  display: none;
}

[onsw-chosen].has-selected-options > .box > .selected {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

[onsw-chosen] > .box > .selected > .option,
[onsw-chosen] > .box > .selected > .add {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  line-height: 1;
  padding: 0 4px;
  margin: 2px;
  background: black;
  color: white;
}

[onsw-chosen] > .box > .selected > .add > span {
  display: inline-block;
  padding: 0 4px;
  color: #b4b3b3;
  font-weight: 600;
  font-size: 24px;
  position: relative;
}

[onsw-chosen] > .box > .selected > .add:hover > span {
  color: white;
}

[onsw-chosen] > .box > .selected > .option::after {
  display: inline-block;
  content: '\00D7';
  padding-left: 6px;
  color: #b4b3b3;
  font-weight: 600;
  font-size: 24px;
  position: relative;
}

[onsw-chosen] > .box > .selected > .option:hover::after {
  color: white;
}

[onsw-chosen] > .box > .available {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  color: #1a1a1a;
  box-shadow: 5px 5px 20px rgba(0,0,0,0.3);
}

[onsw-chosen] > .box > .available {
  display: none;
}

[onsw-chosen] > .box > .available > .box > input {
  display: block;
  box-sizing: border-box;
  width: 100%;
}

[onsw-chosen].toggle.has-available-options > .box > .available {
  display: block;
}

[onsw-chosen] > .box > .available > .box {
  max-height: 200px;
}

[onsw-chosen] > .box > .available > .box > .option {
  padding: 5px 6px;
  cursor: pointer;
}

[onsw-chosen] > .box > .available > .box > .option:hover {
  color: white;
}
