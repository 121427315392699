@import '../layout/item.css';
@import '../layout/price.css';

/**
 * ### Regard
 * > [regards.html](../../html/regard/regards.html)
 */
.layout-item.regard {
  grid-template-rows: auto min-content min-content 1fr;
  gap: var(--regard-gap, 0);
  padding: var(--regard-padding, 6px 10px);
  font-size: var(--regard-text-size, 11px);

  > .image {
    height: var(--regard-icon-size, 90px);
    width: var(--regard-icon-size, 90px);
  }

  > .layout-price {
    place-self: end;
  }

  > .text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
