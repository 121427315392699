@media (max-width: 767px) {
  .ons-layout.fixed-main {
    transition: transform 0.5s ease-out !important;
  }

  body.show-dialog .ons-layout.fixed-main,
  body.show-contacts .ons-layout.fixed-main {
    transform: translateX(-100%);
  }

  .ons-layout.fixed-main[hookname='livecamLoading'],
  .ons-layout.fixed-main[hookname='livecamShow'] {
    z-index: 3;
    top: 0;
  }

  .ons-layout.fixed-main[hookname='livecamShow'] > .box {
    padding: 0;
  }
}

@media (max-width: 1023px) {
  .ons-layout.fixed-main {
    z-index: 1;
  }
}

.ons-layout.fixed-main {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
}

.ons-layout.fixed-main:not(.overlay) > .box {
  height: 100%;
  padding: 10px 5px;
}

@media (min-width: 768px) {
  .ons-layout.fixed-main {
    left: 60px;
  }

  body.show-dialog .ons-layout.fixed-main,
  body.show-contacts .ons-layout.fixed-main {
    right: 300px;
  }

  .ons-layout.fixed-main:not(.overlay) > .box {
    padding: 10px 15px;
  }
}

@media (min-width: 1024px) {
  body.show-contacts .ons-layout.fixed-main {
    right: 300px;
  }
  body.regard-shop-open .ons-layout.fixed-main,
  body.show-dialog .ons-layout.fixed-main {
    right: 620px;
  }
}

@media (min-width: 1280px) {
  .ons-layout.fixed-main {
    top: 80px;
  }
}

.ons-layout.fixed-main.animate.ng-enter:not(.overlay) {
  animation: ons-zoom-in 1s ease-out;
}

.ons-layout.fixed-main.animate.ng-leave:not(.overlay) {
  animation: ons-fade-in 1s ease-in reverse;
}

onsw-view[name="sticky"] {
  display: contents;
}

.main-box.sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 0 10px;

  [onsw-hook].main.sidebar-hook {
    width: 300px;
    grid-column: 2;
    grid-row: 1 / 5;
  }

  .sidebar-hook ~ onsw-view[name="footer"] {
    grid-column: 1 / 3;
  }
}

.main-box:not(.sidebar) > .sidebar-hook > .sidebar-box > :not(.show-always) {
  display: none;
}
