[onsw-amateur-signup] > .box {
  position: relative;
}

[onsw-amateur-signup] > .box > .notify {
  top: unset;
  bottom: 10px;
}

onsw-template[template="amateurPool.signupSuccess"] > .box {
    background: white;
        color: green;
	    padding: 20px;
}

.overlay-content.amateurAgb  {
  background: white !important;
}
