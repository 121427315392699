.contact-widget.nickname,
.ons-layout.tabs > .box > .ons-item {
  > .box {
    & .icon.game * {
      fill: var(--game-icon-default) !important;
    }
  }

  &.hasTimeout,
  &.isDeclined {
    .icon.game * {
      fill: var(--game-icon-default) !important;
    }
  }

  &.isPending .icon.game * {
    fill: var(--game-icon-isPending) !important;
  }

  &.amateurTurn .icon.game * {
    fill: var(--game-icon-amateurTurn) !important;
  }

  &.userTurn .icon.game * {
    fill: var(--game-icon-userTurn) !important;
    animation-name: games-icon-blink;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
}

@keyframes games-icon-blink {
  25% { opacity: 1; }
  50% { opacity: 0; }
  75% { opacity: 1; }
}

.icon.gameColor {
  .gameColorBg {
    fill: var(--gameColorBg) !important;
  }
  .gameColorShadow {
    fill: var(--gameColorShadow) !important;
  }
  .gameColorPlus {
    fill: var(--gameColorPlus) !important;
  }
  .gameColorElements {
    fill: var(--gameColorElements) !important;
  }
}
