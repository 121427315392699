@media (max-width: 448px) {
  :not(.regardShop).ons-layout.tabs:not(.messenger) > .box > .ons-item > .box {
    padding: 0 6px;
  }
}

.ons-layout.tabs {
  > .box > .ons-item {
    > .box {
      font-size: 14px;
      line-height: 1;
      padding: 0 15px;
      height: 36px;
      text-decoration: none;
      color: white;
      background: var(--tabs-default);

      > .icon {
        width: 24px;
        height: 24px;

        * {
          fill: white;
        }
      }

      &.active {
        background: var(--tabs-active);
        color: white;
        font-weight: 600;
      }
    }

    &.active > .box {
      background: var(--tabs-active);
      color: white;
      font-weight: 600;

      > .icon * {
        fill: white;
      }
    }

    &:not(.active) > .box:hover {
      background: var(--tabs-hover);
    }
  }

  &.messenger-widget,
  &.regardShop {
    background-color: var(--background-messenger);

    > .box {
      background: var(--background-messenger);
      padding: 0 8px;
      height: 46px;

      > .ons-item {
        > .box {
          background: var(--background-messenger);
          border: var(--tabs-messenger-default);
          padding: 0 15px;
          height: 32px;

          > .icon * {
            fill: #de287c;
          }

          > .label {
            color: var(--text-messenger);
          }
        }

        &.link.active > .box {
          background: var(--tabs-messenger-active);
          color: white;
          font-weight: 600;

          > .icon * {
            fill: white;
          }

          > .label {
            color: white;
          }
        }

        &:not(.active) > .box:hover {
          background: var(--tabs-messenger-hover);
        }
      }

      > :last-child > .box {
        background: var(--background-messenger);
        border: var(--tabs-messenger-default);
        width: 36px;
        padding: 0 !important;
      }
    }
  }
}



