body {
  font-family: var(--default-font);
  background: #1a1a1a;
  color: #eee;
  font-size: 13px;
}

.frame {
  background: #333;
  background-clip: content-box;
}

.frame .frame {
  background-color: #444;
}

.frame > .box {
  padding: 10px;
}

.feed-box .ons-list > .box > * > .box {
  background: #444;
}

[onsw-amateur-topic-badge] > .box .label {
  filter:drop-shadow(1px 1px 1px #547300);
  font-weight: 700;
  font-size: 13px;
}
[onsw-amateur-topic-badge] > .box .icon{
  filter:drop-shadow(0px 1px 0px #547300);
}
