[onsw-amateur-common-filter] > .box {
  position: relative;
}

[onsw-amateur-common-filter] > .box > div.suggestions {
  max-width: unset;
  margin-top: -10px;
  border: 1px solid #d1d1d1;
}

[onsw-amateur-common-filter] > .box > div.suggestions > .box {
  padding: 5px;
}

[onsw-amateur-common-filter] > .box > div.suggestions .suggestion.selected,
[onsw-amateur-common-filter] > .box > div.suggestions .suggestion:hover {
  cursor: pointer;
}
