.overlay-toolbar.payment.payback > .box > .ons-item > .box {
  text-transform: uppercase;
}

.payment.payback h2 {
  font-family: var(--headline);
  color: #f90;
  font-size: 15px;
  margin: 0 0 6px;
}

.payment.payback p.intro {
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 10px;
}

.payment.payback p span {
  color: #f90;
}

.payment.payback p.help-block {
  font-size: 11px;
  font-weight: normal;
  margin: 0;
}
