[onsw-payment-payback-result] > form,
[onsw-payment-transaction-result] > form {
  padding: 15px 10px;
  font-size: 14px;
}

[onsw-payment-payback-result].success > form::before,
[onsw-payment-transaction-result].success > form::before {
  content: '';
  height: 200px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}

[onsw-payment-payback-result].success > form::before,
[onsw-payment-transaction-result].success > form::before {
  background-image: url('../../basic/img/green-check.png');
}

/*
[onsw-payment-transaction-result].error > form::before {
  background-image: url(../basic/img/red-false.png);
}
*/

[onsw-payment-payback-result].success > form > p > span,
[onsw-payment-transaction-result].success > form > p > span {
  font-weight: bold;
}

[onsw-payment-payback-result].ons-form > form > div > button,
[onsw-payment-transaction-result].ons-form > form > div > button {
  padding: 6px 20px;
  font-size: 18px;
}

[onsw-payment-transaction-result].ons-form.error > .box > .buttons {
  display: none;
}

[onsw-payment-payback-result].error > .box h1,
[onsw-payment-transaction-result].error > .box h1 {
  color: red;
}

[onsw-payment-transaction-result].error > .box {
  background: #fcf8e3;
  margin-bottom: 10px;
}
