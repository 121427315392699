dl {
    box-sizing: border-box;
    margin: 0;
}
dl:after {
    display: block;
    content: '';
    clear: both;
}
dt {
    float: left;
    clear: both;
}
dd {
    /*width: 55%;*/
    float: right;
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}
