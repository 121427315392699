@import '../list.css';
@import '../regard.css';
@import '../../layout/price.css';
/**
 * THIS is a PRESET
 * @todo change overlay selector after overlay style cleanup
 */
.ons-layout.overlay .layout-list.regard-list {
  --regard-list-min-width: 120px;
  --regard-list-min-height: 130px;
  --regard-list-padding: 10px;
  --regard-list-gap: 5px;
  --regard-icon-size: 80px;
  --price-display: none;
}
