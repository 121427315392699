@import '../layout/panel.css';
@import 'voicemessageRecorder.css';
@import 'send.css';

.layout-panel.messenger-composer {
  &.voice {
    > .layout-toolbar {
      gap: var(--messenger-form-gap);

      > .messenger-voice-recorder {
        display: contents;

        > .layout-toolbar {
          flex-grow: 1;
        }
      }
    }

    ~ .buttons,
    ~ .dialog-buttons-hook {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  > .layout-toolbar > .messenger-send {
    flex-grow: 1;
  }

  > .layout-link.attachment {
    margin-top: calc(var(--panel-gap) / -2);
    align-items: baseline;
    width: 100%;

    > .caption {
      font-weight: var(--text-strong);

      &::after {
        content: ':';
      }
    }

    > .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .remove {
      font-size: smaller;
      cursor: pointer;

      &::before {
        content: '(';
      }
      &::after {
        content: ')';
      }
    }
  }
}
