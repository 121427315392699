.livecam-motto {
  padding: 6px;
  font: var(--livecam-motto);
  color: white;
  text-shadow: 0 2px 4px rgba(0,0,0,.5);
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.tile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 54px;
    background: url("../../basic/img/cam-motto.png") repeat-x;
  }

  &.inactive {
    display: none;
  }
}
