@import '../layout/container.css';
@import '../layout/overlay.css';
@import '../layout/panel.css';
/**
 * TODO consider container-overlay
 */
.layout-container.regard-shop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto;
  transition: transform 0.5s ease-out;
  transform: translate(100%);
  grid-template:
    'A' min-content
    'B' minmax(0, 1fr);
  align-items: stretch;
  justify-items: stretch;

  > .tabs {
    grid-area: A;

    > .box > :last-child {
      margin-left: auto;

      > .box {
        padding: 0;
      }
    }
  }

  > onsw-regard-categories {
    grid-area: B;

    > .box {
      height: 100%;
      background: var(--regard-categories-background, var(--container-background));
    }
  }

  > onsw-regards {
    grid-area: B;

    &.ng-enter {
      animation: regards-enter 0.5s ease-out;
    }

    &.ng-leave {
      transition: transform 0.5s ease-out;
      transform: translateX(0);
    }

    &.ng-leave-active {
      transform: translateX(100%);
    }

    > .box {
      height: 100%;
    }
  }

  > .layout-panel.no-selection {
    place-content: center;
    background: var(--regard-no-selection-background, var(--container-background));

    > .heading {
      place-self: center;
      color: var(--panel-color);
      text-transform: unset;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (min-width: 768px) {
    left: auto;
    width: 620px;
    grid-template:
      'A A' minmax(min-content, min-content)
      'B C' minmax(0, 1fr) / 300px 320px;

    > onsw-regard-categories {
      border-right: var(--regard-shop-border);
    }

    > onsw-regards {
      grid-area: C;
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    top: 60px;
  }

  body.show-dialog & {
    transition: opacity 0.5s ease-out;
    opacity: 0;
  }

  body.regard-shop-open & {
    transform: translate(0);
    opacity: 1;
  }

  @media (max-width: 1024px) {
    body.regard-shop-open & {
      z-index: 2;
      box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.3);
    }
  }

  > .layout-container.layout-overlay {
    align-content: end;

    > .layout-note {
      width: 300px;
      z-index: 1; /* need to overcome the stacking inside onsw-regard-send */
    }

    > onsw-regard-send {
      width: 100%;
      animation: regard-send 0.5s ease-out;
      box-shadow: var(--panel-regard-send-shadow, 10px 0 15px rgba(0, 0, 0, 0.5));

      &.ng-leave {
        transform: translateY(0);
        transition: transform 0.5s ease-out;
      }
      &.ng-leave-active {
        transform: translateY(100%);
      }
    }
  }
}

@keyframes regard-send {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes regards-enter {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

/* TODO */
@media (min-width: 768px) {
  [onsw-toggle-button].regardNav {
    display: none !important;
  }
}
