[onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
  background-color: #18120f;
  background-image: url(../img/signup_background2.jpg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}
[hookname="welcomeGuest"].ons-layout.columns > .box > .col50-xs {
  padding: 0;
}

[hookname="welcomeGuest"] > .box {
  text-shadow: none;
  color: white;
}

[hookname="welcomeGuest"] .ons-heading > .box {
  font-family: var(--headline);
  font-size: 24px;
  color: #cc0000;
  margin: 0;
}

[hookname="welcomeGuest"] > .box > div {
  position: relative;
}

[hookname="welcomeGuest"] [onsw-text] > .box {
  margin: 10px 0;
}

[hookname="welcomeGuest"] > .box > div > onsw-template > .box {
  bottom: 50px;
  position: absolute;
  font-size: 11px;
  line-height: 1;
  color: white;
}

[hookname="welcomeGuest"] .ons-layout.buttons {
  text-align: left;
  padding-top: 30px;
}

[hookname="welcomeGuest"] form > label > span {
  display: none;
}

[hookname="welcomeGuest"] [onsw-signup] .visibility {
  position: absolute;
  top: 4px;
}

@media (min-width: 1920px) {
  [hookname="welcomeGuest"] > .box > div {
    margin: 0 40px;
  }
  [hookname="welcomeGuest"].ons-layout.columns > .box > .col50-xs {
    width: 360px !important;
  }
  [hookname="welcomeGuest"] [onsw-text] > .box {
    margin: 15px 0;
  }
}

@media (min-width: 1468px) {
  [hookname="welcomeGuest"] > .box > div > .ons-heading > .box {
    font-size: 30px;
  }
  [hookname="welcomeGuest"] > .box > div {
    margin: 0 20px;
    bottom: calc(50% - 177px);
    top: calc(50% - 177px);
  }
}

@media (min-width: 1367px) and (max-width: 1467px) {
  [hookname="welcomeGuest"] > .box > div {
    margin: 0 5px;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  [hookname="welcomeGuest"] .ons-heading > .box {
    line-height: 1;
  }
  [hookname="welcomeGuest"] [onsw-text] > .box {
    font-size: 12px;
  }
  [hookname="welcomeGuest"] > .box > div {
    margin: 0 5px;
    bottom: calc(50% - 140px);
    top: calc(50% - 140px);
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 10px;
    right: 10px;
  }
  [hookname="welcomeGuest"] [onsw-text] {
    display: none;
  }
  [hookname="welcomeGuest"] > .box > div > .ons-heading > .box {
    font-size: 21px;
  }
  [hookname="welcomeGuest"] > .box > div {
    bottom: calc(50% - 113px);
    top: calc(50% - 113px);
  }
}

@media (min-width: 1024px) {
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg {
    padding-top: 28%;
    position: relative;
  }
}

@media (max-width: 1023px) {
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg {
    padding: 5px 10px;
  }
}

@media (max-width: 619px) {
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
    background-image: none;
    padding: 10px;
  }
  [hookname="welcomeGuest"].ons-layout.columns > .box > .col50-xs {
    width: 100%;
  }
}
