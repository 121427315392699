@import '../layout/panel.css';
@import '../layout/container.css';
@import 'timer.css';
@import 'session-info.css';

.layout-container.game-tab {
  &.large {
    grid-template-columns: 3fr 2fr;
    grid-template-rows: min-content 1fr;
  }

  > .layout-container {
    grid-row-end: span 2;

    > .layout-panel.games-panel {
      grid-template-columns: 4fr 1fr 1fr;
      column-gap: 6px;

      > h1 {
        grid-column-end: span 3;
      }

      > onsw-game-user-stats {
        display: contents;

        > div {
          padding: 10px;
        }
      }

      &.small {
        grid-template-columns: 1fr 1fr;

        > h1,
        > onsw-game-invite {
          grid-column-end: span 2;
        }
      }
    }

    > .layout-panel.messenger-panel > .games {
      display: none;
    }
  }

  onsw-game.isActive + .layout-container > .game-tab-panel {
    display: none;
  }

  onsw-games.warp-column-flex {
    --warp-min-height: 80px;
    --warp-gap: var(--game-default-gap);

    > * > div {
      grid-template-columns: 60px auto;

      &.hasSession {
        cursor: pointer;
      }

      > img {
        grid-row: 1 / span 3;
        width: 60px;
        height: 60px;
      }

      > onsw-game {
        grid-column-end: span 2;
        margin-top: var(--game-default-gap);
      }
    }
  }
}
