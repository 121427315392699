@import '../layout/item.css';

.layout-item.amateurPool-feed-item {
  gap: 6px;
  padding: 10px;
  background: var(--personal-info-badge-background-color);

  [onsw-rating] {
    .label,
    .value {
      display: none;
    }
  }

  &:hover {
    background: var(--personal-info-badge-background-color);

    > .title {
      color: var(--item-highlight-color);
    }
  }

  > .image {
    width: 100%; /* required for deprecated onsw-image */
  }

  > .text {
    > .icon {
      display: none;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      padding-right: 5px;
      fill: var(--feed-item-icon-color);
    }
  }

  > footer {
    align-self: end;
    padding-bottom: unset;

    > :not(:last-child)::after {
      content: ' | ';
    }
  }

  &.large {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(3, min-content) 1fr;

    > .image {
      grid-area: 1 / 2 / span 4 / auto;
      place-self: start;
    }
  }

  .weblog > & > .text > .icon {
    display: inline-block;
  }
}
